'use strict';

var carousel = require('./carousel');

/**
 * Initialize carousel for multiple content
 */
function initHeroCarousel() {
    $('.hero-image-wrapper').each(function () {
        if ($(this).find('.hero-images img').length > 1) {
            var $heroImages = $(this).find('.hero-images');
            var opts = {
                centerMode: true,
                centerPadding: '10px',
                adaptiveHeight: true,
                dots: true,
                autoplay: false,
                customPaging: function (slider, i) {
                    var ariaLabel = 'slide ' + (i + 1) + ' of ' + (slider.getDotCount() + 1);
                    return (
                        '<div role="tab" role="button" class="product-carousel-dots" id=' + i + ' aria-label="' + ariaLabel + '" aria-pressed="true" tabindex="0"></div>'
                    );
                },
                responsive: [
                    {
                        breakpoint: 1023.98,
                        settings: {
                            centerMode: false
                        }
                    }
                ]
            };
            carousel.initCarousel(opts, $heroImages);
        }
    });
}

/**
 *Align slick arrows vertically based on image/video height
 */
function alignSlickButtons() {
    $(window).on('load', function () {
        var imgHeight = $('.media-container-div').outerHeight() / 2;
        if (window.matchMedia('(max-width: 700px)').matches) {
            $('.hero-image-wrapper .hero-images .slick-arrow').css('top', `${imgHeight}px`);
        } else {
            $('.hero-image-wrapper .hero-images .slick-arrow').css('top', `${imgHeight - 7}px`);
        }
    });
}

module.exports = {
    initHeroCarousel: initHeroCarousel,
    alignSlickButtons: alignSlickButtons
};
