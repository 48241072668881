require('../../icons/spinner_34px.svg');

/**
 * Populating product bubble with data from ajax response and displaying it
 * @param {Object} tileObject - Response from Tile-Show Ajax call
 * @param {Object} currentComponent - Current shop the look component
 */
function loadBubble(tileObject, currentComponent) {
    var $component = $(currentComponent).find('.bubble-container');
    $component.find('.product-image').attr('src', tileObject.find('.tile-image')[0].dataset.src);
    $component.find('.product-image').attr('alt', tileObject.find('.tile-image')[0].alt);
    $component.find('.product-name')[0].innerHTML = tileObject.find('.pdp-link .link')[0].innerHTML;
    $component.find('.product-name').attr('aria-label', tileObject.find('.pdp-link .link')[0].innerHTML);
    $component.find('.product-price').empty().append(tileObject.find('.price')[0]);

    var quickViewLink = $(currentComponent).find('.bubble-wrapper').find('.quickview');
    $(quickViewLink).attr('href', tileObject.find('.quickview')[0].href);
    $(quickViewLink).attr('data-toggle', 'modal');
    $(quickViewLink).attr('data-target', '#quickViewModal');
    $(quickViewLink).attr('aria-label', tileObject.find('.quickview').attr('aria-label'));
    setTimeout(function () {
        $('.bubble-wrapper').find('.loader').hide();
    }, 100);
}

/**
 * Load product data on click of hotspot and perform other processing.
 */
function loadProduct() {
    $(document).on('click keyup', '.prod-hotspots', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();

        var $hotspotEle = $(this);
        var $component = $hotspotEle.closest('.shop-look-component');

        if ($(this).hasClass('active') && e.which !== 16 && e.which !== 13 && e.which !== 9) {
            $(this).removeClass('active');
            $component.find('.bubble-wrapper').removeClass('displayed');
            return;
        }

        $hotspotEle.addClass('active');
        $('.prod-hotspots').not($(this))
        .removeClass('active');
        $('.bubble-wrapper').not($component.find('.bubble-wrapper'))
        .removeClass('displayed');

        var url = $hotspotEle.data('productbubbleurl');
        if (url !== undefined && url !== null) {
            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    var tileObject = $('<div/>').html(response).contents();
                    loadBubble(tileObject, $component);
                    $hotspotEle.attr('aria-label', tileObject.find('.pdp-link .link')[0].innerHTML);
                }
            });

            // Positioning product bubble near hotspot
            var $bubbleContainer = $component.find('.bubble-container');
            var top = parseInt(this.style.top, 10);
            var left = parseInt(this.style.left, 10);
            var offsetTopVal = parseInt(this.offsetTop, 10);
            var hotspotHeight = parseInt(this.offsetHeight, 10);
            var bubbleHeight = parseInt($bubbleContainer[0].offsetHeight, 10);

            $bubbleContainer.css('left', 'unset').css('top', 'unset');
            if (top < 50) {
                $bubbleContainer.css('top', offsetTopVal + hotspotHeight);
                $bubbleContainer.css('left', left + '%');
                $bubbleContainer.css('transform', 'translateX(-' + (left + 1) + '%)');
                if (left >= 50) {
                    $bubbleContainer.addClass('right-aligned').removeClass('left-aligned');
                } else {
                    $bubbleContainer.addClass('left-aligned').removeClass('right-aligned');
                }
            } else {
                $bubbleContainer.css('top', offsetTopVal - bubbleHeight);
                $bubbleContainer.css('left', left + '%');
                $bubbleContainer.css('transform', 'translateX(-' + (left + 1) + '%)');
                if (left >= 50) {
                    $bubbleContainer.addClass('right-aligned').removeClass('left-aligned');
                } else {
                    $bubbleContainer.addClass('left-aligned').removeClass('right-aligned');
                }
            }

            $component.find('.bubble-wrapper').find('.loader').show();
            $component.find('.bubble-wrapper').addClass('displayed');

            if (e.which === 9 || e.which === 16 || e.which === 13) {
                $bubbleContainer.addClass('tabbed');
            } else {
                $bubbleContainer.removeClass('tabbed');
            }

            if (e.which === 13) {
                $component.find('.bubble-wrapper').find('.quickview')
                .click();
            }
        }
    });
}

// Initialize shop the look component on page load
$(document).ready(function () {
    if ($('.shop-look-component').length) {
        $('.shop-look-component').each(function () {
            var $currentComponent = $(this);
            if (!($currentComponent.hasClass('masonry-element') && $currentComponent.hasClass('col-lg-12'))) {
                if ($(this).find('.prod-hotspots').length) {
                    var hotspots = [];
                    $(this).find('.prod-hotspots').each(function () {
                        hotspots.push($(this).data('prodid'));
                    });

                    var url = $(this).data('productavailability');
                    if (url !== undefined && url !== null) {
                        $.ajax({
                            url: url,
                            method: 'POST',
                            data: {
                                productIDs: hotspots.toString()
                            },
                            success: function (response) {
                                var counter = 0;
                                var products = response.productAvailabilityArray;
                                Object.keys(products).forEach(function (id) {
                                    var $hotspotEle = $currentComponent.find('.prod-hotspots[data-prodid=' + id + ']');
                                    if (products[id] === false) {
                                        $hotspotEle.addClass('d-none');
                                        counter++;
                                    } else {
                                        var topVal = $hotspotEle.data('top').toString().indexOf('%') === -1 ? $hotspotEle.data('top').toString() + '%' : $hotspotEle.data('top');
                                        var leftVal = $hotspotEle.data('left').toString().indexOf('%') === -1 ? $hotspotEle.data('left').toString() + '%' : $hotspotEle.data('left');
                                        $hotspotEle.css('top', topVal);
                                        $hotspotEle.css('left', leftVal);
                                        $hotspotEle.css('transform', 'translateX(-' + leftVal + ')');
                                        $hotspotEle.addClass('display');
                                    }
                                });

                                if (counter === Object.keys(products).length) {
                                    $currentComponent.find('.shop-look-cta').addClass('d-none');
                                }
                            }
                        });
                        var bubble = '<div class="bubble-wrapper">' +
                        '<a role="link" class="quickview" href="" tabindex="0">' +
                        '<div class="bubble-container" role="button">' +
                            '<div class="loader" role="alert" aria-busy="true" title="" data-keyboard="false" data-backdrop="static">' +
                                '<svg><use xlink:href="#spinner_34px"></use></svg>' +
                            '</div>' +
                            '<img class="product-image" src="" alt="" />' +
                            '<div class="product-details">' +
                            '<p class="product-name"></p>' +
                            '<p class="product-price"></p>' +
                            '<svg height="18" width="18"><use xlink:href="#rightArrow" /></svg>' +
                            '</div>' +
                        '</div>' +
                        '</a>' +
                        '</div>';

                        if ($currentComponent.closest('.masonry-component').length) {
                            $currentComponent.find('.masonry-link').append(bubble);
                        } else {
                            $currentComponent.find('.shop-look-wrapper').append(bubble);
                        }
                    }
                }
            } else {
                $currentComponent.find('.shop-look-cta').addClass('d-none');
            }
        });
    }
});

// Display product bubble on click of shop the look CTA
$(document).on('click', '.shop-look-component .shop-look-cta', function () {
    $(this).toggleClass('active');
    if (!$(this).hasClass('active')) {
        $(this).closest('.shop-look-component').find('.bubble-wrapper').removeClass('displayed visible-lg');
        $(this).closest('.shop-look-component').find('.prod-hotspots').removeClass('active');
    }
});

// Prevent masonry-link redirection when shop the look is enabled
$(document).on('click', '.shop-look-component .masonry-link', function (e) {
    if (!$(this).find('.shop-look-cta').hasClass('d-none') && $(this).find('.shopLook-masonry-AB').is(':visible')) { // Code for A/B test. Needs to be updated after AB tests
        e.preventDefault();
    }
});

// Prevent hero-link redirection when shop the look is enabled
$(document).on('click', '.shop-look-component .hero-link', function (e) {
    if (!$(this).find('.shop-look-cta').hasClass('d-none') && $(this).find('.shopLook-hero-AB').is(':visible')) { // Code for A/B test. Needs to be updated after AB tests
        e.preventDefault();
    }
});

// Hide product bubble when clicked away
$(document).on('click keyup', function (e) {
    if (!e.target.classList.contains('.prod-hotspots')) {
        $('.prod-hotspots').removeClass('active');
        $('.shop-look-component .bubble-wrapper').removeClass('displayed');
    }
});

module.exports = {
    loadProduct: loadProduct
};
