/* global _satellite */
'use strict';

require('../../icons/touchIcon.svg');

var carousel = require('./carousel');
const { initSwatch } = require('../components/productTile');

const mediaQuery = window.matchMedia('(min-width: 1024px)');
const MIN_SLIDE_COUNT = 3;
var lastFocus;

/**
 * Gets the position to place the hotspot on the image
 */
function setHotspot() {
    $('.outfit-component').each(function () {
        var parentWrapper = $(this);
        if (parentWrapper.find('.hotspot-outfit-carousel .hotspot-outfit-slide').length) {
            var slides;
            if ($('.outfit-component .outfit-carousel.slick-initialized').length) {
                slides = parentWrapper.find('.outfit-carousel .slick-slide:not(.slick-cloned) .hotspot-outfit-slide').find('.product:not("[data-pid=null]")').closest('.hotspot-outfit-slide');
            } else {
                slides = parentWrapper.find('.outfit-carousel .hotspot-outfit-slide').find('.product:not("[data-pid=null]")').closest('.hotspot-outfit-slide');
            }
            slides.each(function () {
                let hotspotEl = $(
                    '<div class="hotspot" tabindex="0" role="button"><span class="solid"></span></div>'
                );
                var topVal = $(this).data('top').toString().indexOf('%') === -1 ? $(this).data('top').toString() + '%' : $(this).data('top');
                var leftVal = $(this).data('left').toString().indexOf('%') === -1 ? $(this).data('left').toString() + '%' : $(this).data('left');
                hotspotEl.css('top', topVal);
                hotspotEl.css('left', leftVal);
                hotspotEl.attr(
                    'data-pid',
                    $(this).data('prodid')
                );
                hotspotEl.attr(
                    'data-index',
                    $(this).data('index')
                );
                parentWrapper.find('.img-wrapper').append(hotspotEl);
            });
        }
    });
}

/**
 * Removes the outfit modal on window resize
 */
function closeOutfitModal() {
    $('#outfitProductModal').modal('hide');
}

/**
 * Creates an outfit carousel if 3 or more products are present
 * @param {Object} component - outfit component element
 */
function initOutfitCarousel(component) {
    if (
        mediaQuery.matches &&
        $(component).find('.outfit-carousel .hotspot-outfit-slide:not(.d-none)').length <=
            MIN_SLIDE_COUNT
    ) {
        $(component).find('.outfit-carousel-section.show-carousel')
            .removeClass('show-carousel')
            .addClass('no-carousel');
        return;
    }

    var opts = {
        dots: true,
        autoplay: false,
        infinite: false,
        customPaging: function (slider, i) {
            return '<div role="button" class="product-carousel-dots" id=' + i + '></div>';
        },
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        responsive: [
            {
                breakpoint: 1023.98,
                settings: {
                    slidesToShow: 3,
                    centerMode: false
                }
            }
        ]
    };
    // Removing the product slides with null product id's.
    var $productSlides = $('.hotspot-outfit-slide').find('.product');
    for (var i = 0; i < $productSlides.length; i++) {
        if ($($productSlides[i]).data('pid') === null) {
            $($productSlides[i]).parent().remove();
        }
    }
    carousel.initCarousel(opts, $(component).find('.outfit-carousel'));
    carousel.heightResize(
        $(component).find('.outfit-carousel'),
        40,
        'tile-body'
    );
}

/**
 * Populating product bubble with data from ajax response and displaying it
 * @param {Object} tileObject - Response from Tile-Show Ajax call
 * @param {Object} outfitComponent - Current Outfit component element
 */
function loadProductBubble(tileObject, outfitComponent) {
    var $component = $(outfitComponent).find('.bubble-container');
    $component.find('.product-image').attr('src', tileObject.find('.tile-image')[0].dataset.src);
    $component.find('.product-image').attr('alt', tileObject.find('.tile-image')[0].alt);
    $component.find('.product-name')[0].innerHTML = tileObject.find('.pdp-link .link')[0].innerHTML;
    $component.find('.product-name').attr('aria-label', tileObject.find('.pdp-link .link')[0].innerHTML);
    $component.find('.product-price').empty().append(tileObject.find('.price')[0]);

    var quickViewLink = $(outfitComponent).find('.bubble-wrapper').find('.quickview');
    $(quickViewLink).attr('href', tileObject.find('.quickview')[0].href);
    $(quickViewLink).attr('data-toggle', 'modal');
    $(quickViewLink).attr('data-target', '#quickViewModal');
    $(quickViewLink).attr('aria-label', tileObject.find('.quickview').attr('aria-label'));
    setTimeout(function () {
        $('.bubble-wrapper').find('.loader').hide();
    }, 100);
}

/**
 * Highlights the product or opens the modal on the click of corresponding hotspot
 */
function highlightProduct() {
    $(document).on('click keyup', '.outfit-component .hotspot', function (e) {
        var $hotspotEle = $(this);
        var $outfitParent = $hotspotEle.closest('.outfit-component');
        if (!mediaQuery.matches && $(this).hasClass('active')) {
            $outfitParent.find('.bubble-wrapper').removeClass('displayed');
            $(this).removeClass('active');
            return;
        }
        $hotspotEle.addClass('active');
        $outfitParent.find('.hotspot').not($(this))
        .removeClass('active');
        var pid = $hotspotEle.data('pid');
        var slickGoTo = $outfitParent.find(
            '.outfit-carousel >.slick-list >.slick-track >.slick-slide'
        )
            .not('.slick-cloned')
            .has('div.product[data-pid="' + pid + '"]')
            .data('slickIndex');
        if (slickGoTo !== undefined) {
            if (slickGoTo === 3) {
                $outfitParent.find('.outfit-carousel').slick('slickGoTo', 1);
            } else {
                $outfitParent.find('.outfit-carousel').slick('slickGoTo', 0);
            }
        }

        var url = $('.hotspot-outfit-slide[data-prodid="' + pid + '"]').data('productbubbleurl');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (response) {
                var tileObject = $('<div/>').html(response).contents();
                loadProductBubble(tileObject, $outfitParent);
                $hotspotEle.attr('aria-label', tileObject.find('.pdp-link .link')[0].innerHTML);
            }
        });

        // Positioning product bubble near hotspot
        var $bubbleContainer = $outfitParent.find('.bubble-container');
        var top = parseInt(this.style.top, 10);
        var left = parseInt(this.style.left, 10);
        var offsetTopVal = parseInt(this.offsetTop, 10);
        var hotspotHeight = parseInt(this.offsetHeight, 10);
        var bubbleHeight = parseInt($bubbleContainer[0].offsetHeight, 10);

        if (top < 50) {
            $bubbleContainer.css('top', offsetTopVal + hotspotHeight);
            if (left >= 50) {
                $bubbleContainer.css('left', 'unset').css('right', 15);
            } else {
                $bubbleContainer.css('left', 15);
            }
        } else {
            $bubbleContainer.css('top', offsetTopVal - bubbleHeight);
            if (left >= 50) {
                $bubbleContainer.css('left', 'unset').css('right', 15);
            } else {
                $bubbleContainer.css('left', 15);
            }
        }

        $outfitParent.find('.bubble-wrapper').find('.loader').show();
        $outfitParent.find('.bubble-wrapper').addClass('displayed');

        if (mediaQuery.matches) {
            var slides = $outfitParent.find('.outfit-carousel .outfit-slide');
            var productPid = 'div.product[data-pid="' + pid + '"]';
            $(slides)
                .has(productPid)
                .addClass('selected');
            $(slides)
                .not($(slides).has(productPid))
                .removeClass('selected');

            if (e.which !== 13) {
                $outfitParent.find('.bubble-wrapper').removeClass('visible-lg');
            }

            if (e.which === 9 || e.which === 16) {
                $outfitParent.find('.bubble-wrapper').addClass('visible-lg');
            }

            if (e.which === 13) {
                $(this).closest('.image-section').find('.bubble-wrapper').find('.quickview')
                .click();
            }
        }
        var position = $(this).attr('data-index');
        // Analytics - Tracking clicks on hotspot
        _satellite.track('outfit-component:product-dot', {
            component: {
                id: 'product-information-dot',
                text: 'product information dot',
                details: 'enhance-outfit-component',
                position: position
            }
        });
    });
}

$('body').on('show.bs.modal', '#quickViewModal', function () {
    lastFocus = $(':focus');
});

$('body').on('hidden.bs.modal', '#quickViewModal', function () {
    if (lastFocus) {
        lastFocus.focus();
    }
});

// Shift focus back to product hotspot on closing the added to cart modal
$('body').on('hidden.bs.modal', '#addedToCartModal', function () {
    if (lastFocus) {
        lastFocus.focus();
    }
});

// Show/hide hotspot
$('.shop-look').on('click', function () {
    $(this).toggleClass('active');
    if (!$(this).hasClass('active')) {
        if ($(this).closest('.outfit-component').find('.outfit-slide').hasClass('selected')) {
            $(this).closest('.outfit-component').find('.outfit-slide').removeClass('selected');
        }
        $(this).closest('.outfit-component').find('.bubble-wrapper').removeClass('displayed visible-lg');
        $(this).closest('.outfit-component').find('.hotspot').removeClass('active');
    } else { // Analytics - Tracking clicks on Shop this look button when active
        _satellite.track('outfit-component:shop-this-look', {
            component: {
                id: 'shop-this-look',
                text: 'shop this look',
                details: 'enhance-outfit-component'
            }
        });
    }
});

$('.outfit-component').on('keyup', function () {
    if (!document.activeElement.classList.contains('hotspot')) {
        $(this).find('.bubble-wrapper').removeClass('displayed visible-lg');
        $(this).find('.hotspot').removeClass('active');
        $(this).find('.outfit-slide').removeClass('selected');
    }
});

// Hiding product bubble on mouse click if visible on desktop
$(document).on('click', function () {
    $('.bubble-wrapper').removeClass('visible-lg');
});

// Analytics - Tracking clicks on outfit product tiles
$(document).on('click', '.outfit-component .product-tile', function () {
    _satellite.track('outfit-component:product-click', {
        component: {
            position: $(this).closest('.hotspot-outfit-slide').attr('data-index')
        }
    });
});

// Analytics - Tracking clicks on see full details link on Quick Shop modal
$(document).on('click', '#quickViewModal .full-pdp-link', function () {
    if ($(this).attr('data-outfit')) {
        var component = $(this).attr('data-component');
        _satellite.track('outfit-component:see-full-details', {
            component: {
                details: component
            }
        });
    }
});

$(document).ready(function () {
    if ($('.outfit-component').length) {
        setHotspot();
        $('.outfit-component').each(function () {
            var pids = [];
            $(this).find('.hotspot').each(function () {
                pids.push($(this).data('pid'));
            });

            var $currentOutfitComp = $(this);

            if ($currentOutfitComp.find('.hotspot-outfit-slide').find('.product[data-pid=null]').length) {
                $currentOutfitComp.find('.hotspot-outfit-slide').find('.product[data-pid=null]').closest('.hotspot-outfit-slide').addClass('d-none');
            }

            if (pids.length === 0) {
                $currentOutfitComp.find('.outfit-carousel-section').removeClass('show-carousel').addClass('no-prod no-carousel');
                $currentOutfitComp.find('.shop-look').hide();
            }

            if ($(this).data('productavailability') !== undefined && pids.length > 0) {
                $.ajax({
                    url: $(this).data('productavailability'),
                    method: 'POST',
                    data: {
                        productIDs: pids.toString()
                    },
                    success: function (response) {
                        var products = response.productAvailabilityArray;
                        var counter = 0;
                        Object.keys(products).forEach(function (id) {
                            if (products[id] === false) {
                                if ($currentOutfitComp.find('.hotspot-outfit-slide[data-prodid=' + id + ']').closest('.slick-slide').length) {
                                    $currentOutfitComp.find('.hotspot-outfit-slide[data-prodid=' + id + ']').closest('.slick-slide').addClass('d-none');
                                }
                                $currentOutfitComp.find('.hotspot-outfit-slide[data-prodid=' + id + ']').addClass('d-none');
                                $currentOutfitComp.find('.hotspot[data-pid=' + id + ']').addClass('d-none');
                                counter++;
                            }
                        });
                        if (counter === Object.keys(products).length) {
                            $currentOutfitComp.find('.shop-look').hide();
                        }

                        if ($currentOutfitComp.find('.hotspot-outfit-slide:not(.d-none)').length === 2) {
                            $currentOutfitComp.find('.outfit-carousel-section').addClass('two-prods');
                        } else if ($currentOutfitComp.find('.hotspot-outfit-slide:not(.d-none)').length === 1) {
                            $currentOutfitComp.find('.outfit-carousel-section').addClass('one-prod');
                        } else if ($currentOutfitComp.find('.hotspot-outfit-slide:not(.d-none)').length === 0) {
                            $currentOutfitComp.find('.outfit-carousel-section').addClass('no-prod');
                        }

                        initOutfitCarousel($currentOutfitComp);
                    },
                    error: function () {
                    }
                });
            }

            var $swatches = $currentOutfitComp.find('.swatches');
            initSwatch($swatches);
        });
    }
});

/**
 * Initialize listeners for resize
 */
function init() {
    mediaQuery.addListener(initOutfitCarousel);
    mediaQuery.addListener(closeOutfitModal);
    mediaQuery.addListener(highlightProduct);
    highlightProduct();
}

module.exports = {
    init: init
};
