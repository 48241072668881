'use strict';

var Swiper = require('./thirdParty/swiper');

var MIN_SLIDE_COUNT;


/**
 * Init swiper carousel
 *
 * @param {jQuery} el - jQuery element
 * @return {Object} - swiper object
 */
function initSwiper(el) {
    var mySwiper = new Swiper(el, {
        autoHeight: true,
        slidesPerView: 2.2,
        freeMode: true,
        spaceBetween: 10,
        breakpoints: {
            768: {
                slidesPerView: 4.5,
                slidesPerGroup: 2,
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    });

    return mySwiper;
}

/**
 * Init visual links carousel
 */
function initCarousel() {
    $('.visual-links-component').each(function () {
        if (!($(this).parent().hasClass('hide'))) {
            var $this = $(this);
            var swiperEl;
            var $swiperWrapperEl = $(this).find('#swiper-init');
            let slideCountQuery = window.matchMedia('(max-width: 767px)');
            if (slideCountQuery.matches) {
                MIN_SLIDE_COUNT = 3;
            } else {
                MIN_SLIDE_COUNT = 4;
            }
            var slotContentLength = $swiperWrapperEl.data('counter');
            if (slotContentLength <= MIN_SLIDE_COUNT) {
                $swiperWrapperEl.addClass('row');
                $('.swiper-slide-init').addClass('col');
                if (swiperEl) {
                    swiperEl.destroy();
                }
                $swiperWrapperEl.removeAttr('style');
                $('.swiper-slide-init').removeAttr('style');
            } else {
                $swiperWrapperEl.addClass('swiper-wrapper');
                $('.swiper-slide-init').addClass('swiper-slide');
                $swiperWrapperEl.removeClass('row');
                $('.swiper-slide-init').removeClass('col');
                swiperEl = initSwiper($this);
            }
            $('.quick-links-wrapper').removeClass('d-none');

            // Setting top value for carousel arrow to center it vertically based on img height
            var imgHeight = $(this).find('.image-wrapper .img-fluid').height();
            if ($(this).find('.swiper-button-prev').length > 0 && $(this).find('.swiper-button-next').length > 0) { // If the buttons exist
                $(this).find('.swiper-button-prev').css({ top: imgHeight / 2 });
                $(this).find('.swiper-button-next').css({ top: imgHeight / 2 });
            }
        }
    });
}

// Click on anchors with '#' at the beginning should not reload the page, but directly scroll to the referred component
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        var selector;
        var elementHead;
        var headerHeight = $('.header .navigation')[0].offsetHeight + 1;
        // If it is Page designer CTA component
        if ($(this).closest('.storepage').length) {
            elementHead = document.querySelector(this.getAttribute('href'));
        } else { // If it is a BM component
            selector = 'a[name="' + this.getAttribute('href').split('#')[1] + '"]';
            elementHead = document.querySelector(selector).closest('h2') ? document.querySelector(selector).closest('h2') : document.querySelector(selector).closest('.container');
        }

        $('html, body').animate({
            scrollTop: $(elementHead).offset().top - headerHeight
        }, 500);
    });
});

/**
 * Initialize listeners for resize
 */
function init() {
    initCarousel();
}

$(window).on('resize', function () {
    init();
});

module.exports = {
    init: init,
    initCarousel: initCarousel
};
