/* eslint-disable no-undef */
'use strict';

$(document).ready(function () {
    var $productSlides = $('.product-enhanced-grid-carousel').find('.product');
    for (var i = 0; i < $productSlides.length; i++) {
        if ($($productSlides[i]).data('pid') === null) {
            $($productSlides[i]).parent().remove();
        }
    }
});

$('.product-enhanced-grid .product-grid').on('click', function () {
    var componentHeader = $('.product-enhanced-grid .carousel-heading h2')[0].innerText;
    var position = Array.from($('.product-enhanced-grid .product-grid')).indexOf(this) + 1;
    _satellite.track('product-interaction', {
        component: 'product-grid',
        id: componentHeader,
        position: position
    });
});
