'use strict';

/**
 * Scrolls to the top of the page
 */
function scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
}

/**
 * Scrolls to the top of the specified element
 * @param {Object} ele - the element to be scrolled to the top of
 */
function scrollToElement(ele) {
    var $eleOffset = ele.offset();
    $('html, body').animate(
        {
            scrollTop: $eleOffset.top
        },
        500
    );
}

/**
 * set browser info in the final review order page for paywithgoogle implementation
 * @return {Object} - JSON object
 */
function setBrowserInfo() { // eslint-disable-line no-unused-vars
    var jsonBrowserInfo = {};
    try {
        jsonBrowserInfo.javaEnabled = true;
        jsonBrowserInfo.language = window.navigator.language;
        jsonBrowserInfo.screenHeight = window.screen.height;
        jsonBrowserInfo.screenWidth = window.screen.width;
        jsonBrowserInfo.colorDepth = window.screen.colorDepth;
        const currDate = new Date();
        let timeDiff = currDate.getTimezoneOffset();
        jsonBrowserInfo.timeZoneOffset = timeDiff;
    } catch (e) {
        // just ignore the exception
    }
    return jsonBrowserInfo;
}

/** count UTF-8 bytes of a string
 *
 * @param {Object} s - String for which to get bytes
 * @return {number} - total bytes in string
 */
function byteLengthOf(s) {
    // assuming the String is UCS-2(aka UTF-16) encoded
    var n = 0;
    for (var i = 0, l = s.length; i < l; i++) {
        var hi = s.charCodeAt(i);
        if (hi < 0x0080) {
            // [0x0000, 0x007F]
            n += 1;
        } else if (hi < 0x0800) {
            // [0x0080, 0x07FF]
            n += 2;
        } else if (hi < 0xd800) {
            // [0x0800, 0xD7FF]
            n += 3;
        } else if (hi < 0xdc00) {
            // [0xD800, 0xDBFF]
            var lo = s.charCodeAt(++i);
            if (i < l && lo >= 0xdc00 && lo <= 0xdfff) {
                // followed by [0xDC00, 0xDFFF]
                n += 4;
            } else {
                throw new Error('UCS-2 String malformed');
            }
        } else if (hi < 0xe000) {
            // [0xDC00, 0xDFFF]
            throw new Error('UCS-2 String malformed');
        } else {
            // [0xE000, 0xFFFF]
            n += 3;
        }
    }
    return n;
}

var timeout;

/**
 * Shows the spinner on checkout stages
 */
function showSpinner() {
    timeout = setTimeout(function () {
        $('.loader').css('display', 'block');
    }, 1000);
}

/**
 * Hides the spinner on checkout stages
 */
function hideSpinner() {
    clearTimeout(timeout);
    $('.loader').css('display', 'none');
}

/**
 * Sort alphabetically the option elements of a select element by text
 * @param {string} selectElement - selector for the select element
 */
function sortSelectOptions(selectElement) {
    var options = $(selectElement + ' option');

    options.sort(function (option1, option2) {
        if (option1.text.toUpperCase() > option2.text.toUpperCase()) {
            return 1;
        } else if (option1.text.toUpperCase() < option2.text.toUpperCase()) {
            return -1;
        }
        return 0;
    });

    $(selectElement).empty().append(options);
}

module.exports = {
    scrollToTop: scrollToTop,
    scrollToElement: scrollToElement,
    byteLengthOf: byteLengthOf,
    showSpinner: showSpinner,
    hideSpinner: hideSpinner,
    setBrowserInfo: setBrowserInfo,
    sortSelectOptions: sortSelectOptions
};
