/* global _satellite */
'use strict';
var utilities = require('../utilities/util');
var debounce = require('lodash/debounce');

var enableSizeDropDownPDP = $("input[name$='enablesizedropdownUI']").val();
var siteCode = $('#siteCountryCode').val();
var countryCode = $('#countryDropdownCode').val();
if (siteCode === 'DE' || siteCode === 'ES') {
    siteCode = 'EU';
} else if (siteCode === 'EU' && countryCode === 'IT') {
    siteCode = 'IT';
}

/**
 * Function for Satellite Track
 * @return {json} - value to be used when attributes got update for product
 */
function getProdctSatelliteData() {
    var productData = [];
    var productID = $('.product-detail.product-wrapper').attr('data-pid');
    var productName = $('.product-detail.product-wrapper').find('.product-name-new').text();
    var color = $.trim($('.swatch-circle-container.selected').attr('title')).toLowerCase();
    var size;
    if ($('.custom-select-localised-sizes').length) {
        size = $.trim($('.custom-select-localised-sizes:first .size-btns').find('input:checked').attr('data-attr-value')).toLowerCase();
    } else {
        size = enableSizeDropDownPDP === 'true' ? $.trim($('.ll_custom-select-size').find('option[name="select-size"]:selected').attr('data-attr-value')).toLowerCase() : $.trim($('.size-btns').find('input:checked').attr('data-attr-value')).toLowerCase();
    }
    var imageURL = $('.product-detail.product-wrapper').find('.carousel-item.active img').attr('data-src');
    var pdpURL = $('.product-detail.product-wrapper').attr('data-currenturl');
    var displayRegularPrice = '';
    var displayPrice = '';
    var key = color + size;
    var stockStatus = 'stockStatus-unknown';
    var available = '';
    var sku = '';
    var onSale = false;
    if (window.satelliteData && window.satelliteData.linkedProduct[key]) {
        var variant = window.satelliteData.linkedProduct[key];
        sku = variant.sku || '';
        stockStatus = variant.stockStatus;
        available = variant.available;
        if (variant.price) {
            displayRegularPrice = variant.price.displayRegularPrice || '';
            displayPrice = variant.price.displayPrice || '';
            onSale = variant.price.onSale;
        }
    }
    var primaryCategory = window.satelliteData.category.primaryCategory;
    var unifiedID = window.satelliteData.category.unifiedID;

    productData.attributes = {
        stockStatus: stockStatus,
        imageURL: imageURL,
        pdpURL: pdpURL,
        available: available // RETURN BOOLEAN true or false

    };
    productData.category = {
        primaryCategory: primaryCategory,
        unifiedID: unifiedID

    };
    productData.price = {
        displayRegularPrice: displayRegularPrice,
        displayPrice: displayPrice,
        onSale: onSale // RETURN BOOLEAN true or false

    };
    productData.productInfo = {
        productID: productID,
        productName: productName,
        color: color,
        size: size,
        sku: sku

    };

    return productData;
}

/**
 * Function for OOS Sku Update
 */
function productSkuUpdateOos() {
    var baseProductID = $('.product-detail.product-wrapper').attr('data-pid');
    var colorSelected = $.trim($('.swatch-circle-container.selected').attr('title')).toLowerCase();
    var sizeselected;
    if ($('.custom-select-localised-sizes').length) {
        sizeselected = $.trim($('.custom-select-localised-sizes:first .size-btns').find('input:checked').attr('data-attr-value')).toLowerCase();
    } else {
        sizeselected = enableSizeDropDownPDP === 'true' ? $.trim($('.ll_custom-select-size').find('option[name="select-size"]:selected').attr('data-attr-value')).toLowerCase() : $.trim($('.custom-select-btn .size-btns').find('input:checked').attr('data-attr-value')).toLowerCase();
    }
    var key = colorSelected + sizeselected;
    var selectedProductSku = '';
    if (window.satelliteData && window.satelliteData.linkedProduct[key]) {
        selectedProductSku = window.satelliteData.linkedProduct[key].sku;
        if ($('.custom-select-localised-sizes').length && $('.custom-select-localised-sizes .size-btns').find('input:checked').length) {
            $('.skuAlignmentNewPDP').find('.product-id').text(selectedProductSku);
        } else if (($('.custom-select-btn .size-btns').find('input:checked').length && enableSizeDropDownPDP === 'false') || ($('.ll_custom-select-size').find('option[name="select-size"]:selected').length && enableSizeDropDownPDP === 'true')) {
            $('.skuAlignmentNewPDP').find('.product-id').text(selectedProductSku);
        } else {
            $('.skuAlignmentNewPDP').find('.product-id').text(baseProductID);
        }
    }
}

/**
 * Function for Custom select size dropdown on PDP
 */
function pdpCustomSelector() {
    $('.ll_custom-select-size').selectpicker('refresh');
    $('#sizeSoldOutModal .ll_custom-select-size').selectpicker('refresh');
    $('.ll_pdp-size-drodown .ll_size-dropdown-error').hide();
    let sizeSoldoutSText = $('.ll_custom-size-dropdown select').attr('data-sold-out');
    let allSizes = $('.ll_pdp-size-drodown .dropdown-menu.inner.show').find('a.sold-out-size');
    $('.ll_custom-size-dropdown .ll_gradient').remove();
    $('.ll_custom-size-dropdown .dropdown-menu').append('<div class="ll_gradient"></div>');
    allSizes.each(function () {
        $(this).append('<span class="ll_custom-size-soldout"> ' + sizeSoldoutSText + '</span>');
    });
    /* gradient visibility on dropdown scroll for PDP only*/
    let pdpEl = $('.ll_pdp-size-drodown .dropdown-menu > .inner');
    if (pdpEl) {
        pdpEl.on('scroll', function () {
            if (pdpEl[0].clientHeight + pdpEl[0].scrollTop >= pdpEl[0].scrollHeight) {
                $('.ll_gradient').hide();
            } else {
                $('.ll_gradient').show();
            }
        });
    }

    /* gradient visibility on dropdown scroll for BISN modal only*/
    let bisnEl = $('.ll_bisn-custom-size-dropdown .dropdown-menu > .inner');
    if (bisnEl) {
        bisnEl.on('scroll', function () {
            if (bisnEl[0].clientHeight + bisnEl[0].scrollTop >= bisnEl[0].scrollHeight) {
                $('.ll_gradient').hide();
            } else {
                $('.ll_gradient').show();
            }
        });
    }
}

/**
 * Function for update selected size header label if size is selected
 */
function sizeHeaderLabelUpdate() {
    var sizeSelectedText;
    var sizeDataLabel = $('.selected-size-name').attr('data-sizelabel');
    if ($('.custom-select-localised-sizes').length && $('.custom-select-localised-sizes:first .size-btns').find('input:checked').length && !$('.custom-select-btn .size-btns input:checked').closest('.size-btns').hasClass('one-size')) {
        var sizeInputChecked = $('#hybridSizingTabContent .tab-pane.active').find('input:checked');
        var defaultSizeLabelText = $("input[name$='sizeLabelName']").val();
        var localisedActiveSizeTab = $('#hybridSizingTabList .nav-link.active').attr('title');
        $('.select-size-header .selected-size-name').html($('.selected-size-name').attr('data-sizelabel') + localisedActiveSizeTab + ' ' + $(sizeInputChecked).siblings('label').html());
        if ($('#hybridSizingTabList .nav-link.active').attr('title') !== 'US' && ($(sizeInputChecked).attr('data-attr-value') !== $(sizeInputChecked).attr('data-attr-hybridsize'))) {
            defaultSizeLabelText = defaultSizeLabelText + $(sizeInputChecked).attr('data-attr-value') + ' (' + localisedActiveSizeTab + ' ' + $(sizeInputChecked).attr('data-attr-hybridsize') + ')';
        } else if ($('#hybridSizingTabList .nav-link.active').attr('title') !== 'US') {
            defaultSizeLabelText = defaultSizeLabelText + '(' + localisedActiveSizeTab + ') ' + $(sizeInputChecked).attr('data-attr-value');
        } else {
            defaultSizeLabelText += $(sizeInputChecked).attr('data-attr-value');
        }
        $('.select-size-header .selected-size-name').attr('data-newsizing', defaultSizeLabelText);
    } else if (enableSizeDropDownPDP === 'false') {
        if ($('.custom-select-btn .size-btns').find('input:checked').length && !$('.custom-select-btn .size-btns input:checked').closest('.size-btns').hasClass('one-size')) {
            if (typeof $('.custom-select-btn .size-btns').find('input:checked').data('attr-hybridsize') !== 'undefined') {
                var localSizeSameAsUsSize = $("input[name$='usSameAsLocalSize']").val() === 'true' ? $("input[name$='sameSizeLabel']").val() + $('.custom-select-btn .size-btns').find('input:checked').attr('data-attr-value') : sizeDataLabel + '(' + siteCode + ') ' + $('.custom-select-btn .size-btns').find('input:checked').attr('data-attr-value');
                sizeSelectedText = ($('.custom-select-btn .size-btns').find('input:checked').attr('data-attr-value') === $('.custom-select-btn .size-btns').find('input:checked').siblings('label').html()) ? localSizeSameAsUsSize : sizeDataLabel + $('.custom-select-btn .size-btns').find('input:checked').siblings('label').html();
                $('.select-size-header .selected-size-name').html(sizeSelectedText);
            } else {
                $('.select-size-header .selected-size-name').html(sizeDataLabel + $('.custom-select-btn .size-btns').find('input:checked').attr('data-attr-value'));
            }
        }
    } else if ($('.ll_custom-select-size').find('option[name="select-size"]:selected').length) {
        if (typeof $('.ll_custom-select-size').find('option[name="select-size"]:selected').data('attr-hybridsize') !== 'undefined') {
            sizeSelectedText = ($('.ll_custom-select-size').find('option[name="select-size"]:selected').attr('data-attr-value') === $('.ll_custom-select-size').find('option[name="select-size"]:selected').html()) ? '(' + siteCode + ') ' + $('.ll_custom-select-size').find('option[name="select-size"]:selected').attr('data-attr-value') : $('.ll_custom-select-size').find('option[name="select-size"]:selected').html();
            $('.select-size-header .selected-size-name').html(sizeDataLabel + sizeSelectedText);
        } else {
            $('.select-size-header .selected-size-name').html(sizeDataLabel + $('.ll_custom-select-size').find('option[name="select-size"]:selected').attr('data-attr-value'));
        }
    }
    if (($('.custom-select-btn .size-btns').find('input.disabled:checked').length && enableSizeDropDownPDP === 'false') || ($('.ll_custom-select-size').find('option[name="select-size"]:selected').hasClass('sold-out-size') && enableSizeDropDownPDP === 'true')) {
        $('.add-to-cart-section').addClass('show-sold-out-btn');
    } else {
        $('.add-to-cart-section').removeClass('show-sold-out-btn');
    }
}

$(document).on('hide.bs.dropdown', '.ll_custom-size-dropdown .form-control.ll_select-size', function () {
    setTimeout(function () {
        var isDefaultSelected = $('.form-control.ll_select-size').find('option:selected').hasClass('label-option');
        if (isDefaultSelected) {
            $('.ll_custom-size-dropdown .ll_size-dropdown-error').show();
            $('.ll_custom-size-dropdown .ll_custom-select-size').addClass('invalid-state');
        }
    }, 1000);
});

$(document).ready(function () {
    pdpCustomSelector();
    $('.custom-select-btn .size-btns input[data-attr-value="ONE SIZE"]').closest('.size-btns').addClass('one-size');
    if ($('.custom-select-btn .size-btns input[data-attr-value="ONE SIZE"]').closest('.size-btns').length > 0) {
        $('.size-chart').hide();
    }
    $(window).on('load', function () {
        $('.custom-select-btn .size-btns.one-size input').trigger('click');
    });
    $(document).ajaxComplete(function () {
        $('.image-container').hover(function () {
            $(this).find('.hover-image').addClass('lazyload').css('display', 'block');
        });
        if ($('.custom-select-localised-sizes').length && $('.custom-select-localised-sizes .size-btns').find('input.disabled').length) {
            $('.size-sold-out-btn').css('display', 'block');
        } else if (($('.select-size .size-btns').find('input.disabled').length && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown .ll_select-size option[name=select-size]').hasClass('sold-out-size') && enableSizeDropDownPDP === 'true')) {
            $('.size-sold-out-btn').css('display', 'block');
        } else {
            $('.size-sold-out-btn').css('display', 'none');
        }
    });
    if ($('.custom-select-localised-sizes').length && $('.custom-select-localised-sizes .size-btns').find('input.disabled').length) {
        $('.size-sold-out-btn').css('display', 'block');
    } else if (($('.select-size .size-btns').find('input.disabled').length && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown .ll_select-size option[name=select-size]').hasClass('sold-out-size') && enableSizeDropDownPDP === 'true')) {
        $('.size-sold-out-btn').css('display', 'block');
    }
    if ($('.sizechartFooter').length) {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var intCatId = urlParams.get('catid');
        if (intCatId != null) {
            var catId = urlParams.get('catid').toLowerCase();
            catId = '#' + catId + '-tab';
            $(catId).click();
        }
        var intSubCatId = urlParams.get('subcatid');
        if (intSubCatId != null) {
            var subCatId = '#' + (intCatId).toLowerCase() + '-' + urlParams.get('subcatid').toLowerCase();
            $(subCatId).click();
        }
    }
});

/**
 * Function for color Swatch & Size Click
 */
function colorSwatchSizeClick() {
    var backInStockPdp = $('#backInStock').attr('data-featureflag');
    $('.swatch-circle-container').on('click', function () {
        if (backInStockPdp === 'true') {
            productSkuUpdateOos();
        }
        $.ajax({
            complete: function () {
                $('.color-group .pdp-swatches').find('.swatch-circle-container.selected').attr('data-url', '');
                if (backInStockPdp === 'true') {
                    if (($('.custom-select-btn .size-btns').hasClass('active') === true && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown select.ll_custom-select-size').find('option[name="select-size"]:selected').length && enableSizeDropDownPDP === 'true')) {
                        var productJsonData = getProdctSatelliteData();
                        _satellite.track('pdp-size-color-selection', {
                            product: productJsonData
                        });
                    }
                }
            }
        });
        $(document).on('ajaxComplete', function () {
            if (backInStockPdp === 'true') {
                productSkuUpdateOos();
            }
        });
        $('.color-group .pdp-swatches').find('.swatch-circle-container.selected').attr('data-url', '');
    });

    if (backInStockPdp === 'true') {
        if (enableSizeDropDownPDP === 'false') {
            $(document).on('click', '.custom-select-btn .size-btns', function () {
                if ($('.custom-select-localised-sizes').length === 0) {
                    if ($(this).find('input').hasClass('disabled')) {
                        $('.add-to-cart-section').addClass('show-sold-out-btn');
                    } else {
                        $('.add-to-cart-section').removeClass('show-sold-out-btn');
                    }
                }
                productSkuUpdateOos();
                $(document).on('ajaxComplete', function () {
                    productSkuUpdateOos();
                });
                if ($(this).find('input:checked').length) {
                    var productJsonData = getProdctSatelliteData();
                    _satellite.track('pdp-size-selection', {
                        product: productJsonData
                    });
                }
            });
        }
    }
}

/**
 *
 * @param {{}} product - Product object
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updatePostAjax(product, $productContainer) {
    // var showImgZoomModal = $productContainer.find('.modal.imageZoomModal').css('display');

    $productContainer.find('.primary-images').replaceWith(product.imageDOM);

    // Updates the image thumbnails DOM elements post Ajax call
    var thumbnails = product.images.small;
    $productContainer
        .find('.product-thumbnails a')
        .find('img')
        .each(function (index) {
            var $this = $(this);
            if (thumbnails[index]) {
                $this.removeClass('d-none');
            } else {
                $this.addClass('d-none');
            }
        });

    // Updates the hi-res primary images post Ajax call
    var hiresImages = product.images['hi-res'];
    $productContainer
        .find('.primary-images .carousel')
        .find('img')
        .each(function (index) {
            var $this = $(this);
            if (hiresImages[index]) {
                $this.closest('.carousel-item').removeClass('d-none');
                $('.carousel-indicators').addClass('d-none');
            } else {
                $('.carousel-indicators').addClass('d-none');
                $this.closest('.carousel-item').addClass('d-none');
            }
        });

    // Updates model fit info post Ajax call
    var largeImages = product.images.large;
    $productContainer
        .find('.primary-images')
        .find('.carousel-item-message')
        .each(function (index) {
            var $this = $(this);
            if (largeImages[index]) {
                $this.removeClass('d-none');
                $this.html(largeImages[0].title);
                $this.attr('text', largeImages[0].title);
            } else {
                $this.addClass('d-none');
            }
        });

    $('body').trigger('imageCountChanged');

    // remove final sale element if finalSale flag is false for variant
    var $finalSaleEl = $productContainer.find('.final-sale');
    if (!product.finalSale) {
        $finalSaleEl.hide();
    } else {
        $finalSaleEl.show();
    }

    // Updates Non Swatch Values
    if (product.variationAttributes) {
        var attrs = product.variationAttributes;
        var attrsWithSwatches = 'color';

        attrs.forEach(function (attr) {
            if ([attrsWithSwatches].indexOf(attr.id) < 0) {
                var $attr = '[data-attr="' + attr.id + '"]';
                attr.values.forEach(function (attrValue) {
                    if (enableSizeDropDownPDP === 'false') {
                        var $attrValue = $productContainer.find(
                            $attr + ' [data-attr-value="' + attrValue.value + '"]'
                        );
                        $attrValue.html(attrValue.displayValue);
                        if (!attrValue.selectable) {
                            if (attr.id === 'size') {
                                $attrValue.html(
                                    attrValue.displayValue +
                                    ' ' +
                                    $('#soldOut').val()
                                );
                            }
                        }
                    }
                });
            } else {
                var promoBadgeContainer = $productContainer.find('.pdp-promo-badge-container');
                if (promoBadgeContainer.length > 0) {
                    var promoBadgeVal = '';
                    attr.values.forEach(function (attrValue) {
                        if (attrValue.selected && attrValue.promoTag) {
                            promoBadgeVal = attrValue.promoTag;
                        }
                    });
                    if (promoBadgeVal) {
                        if (promoBadgeContainer.find('.promo-badge-value').length > 0) {
                            promoBadgeContainer.find('.promo-badge-value').html(promoBadgeVal);
                        } else {
                            promoBadgeContainer.html('<div class="promo-badge-value">' + promoBadgeVal + '</div>');
                        }
                    } else {
                        promoBadgeContainer.html('');
                    }
                }
            }
        });

        // True Fit Widget Update
        if (($('.tfc-fitrec-product').length > 0) && (typeof tfcapi !== 'undefined')) {
            var prodObj = {};
            if (product.masterProductID) {
                prodObj[product.masterProductID] = {
                    colorId: product.selectedColor,
                    availableSizes: product.availableSizes
                };
            }
            var TFObj = {};
            TFObj.userId = $('#custNo').length > 0 ? $('#custNo').val() : '';
            TFObj.products = prodObj;

            tfcapi('calculate', TFObj); // eslint-disable-line
        }
    }

    // Updates product thumbnails present inside PDP zoom modal
    $('body').trigger('updateZoomModalThumbnails', [
        product.images.small,
        product.showLocalImages,
        $productContainer.attr('data-pid')
    ]);
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Array} variationAttributes - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateSelectedSwatchProductName(
    variationAttributes,
    $productContainer
) {
    if (variationAttributes) {
        var colorVariationObject = variationAttributes.filter(function (
            eachAttribute
        ) {
            return eachAttribute.attributeId === 'color';
        })[0];

        if (colorVariationObject && colorVariationObject.values) {
            var selectedSwatchObject = colorVariationObject.values.filter(
                function (eachValue) {
                    return eachValue.selected;
                }
            )[0];
            var promoBadgeValForIsio = '';
            var promoValueOnline = $('input[name=isioOnlineExclusiveValue]').val();
            if (selectedSwatchObject && selectedSwatchObject.promoTag) {
                promoBadgeValForIsio = selectedSwatchObject.promoTag;
            }
            if (promoBadgeValForIsio === promoValueOnline) {
                $('input[name=isioOnlineExclusive]').attr('value', 'true');
            } else {
                $('input[name=isioOnlineExclusive]').attr('value', 'false');
            }

            $productContainer
                .find('.selected-swatch-name')
                .html(
                    selectedSwatchObject
                        ? selectedSwatchObject.displayValue
                        : ''
                );
        }
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Array} LocalisedSizingTabs - updated the localised sizing after the size
 * @param {Array} LocalisedSizing - updated the localised sizing after the size
 * @param {Array} variationAttributes - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {boolean} usSizeSameAsHybridSize - boolean value for identifing US Size Same as Localised Size for JP
 */
function updateSelectedSizeName(
    LocalisedSizingTabs,
    LocalisedSizing,
    variationAttributes,
    $productContainer,
    usSizeSameAsHybridSize
) {
    if (variationAttributes) {
        var sizeVariationObject = variationAttributes.filter(function (
            eachAttribute
        ) {
            return eachAttribute.attributeId === 'size';
        })[0];

        if (sizeVariationObject && sizeVariationObject.values) {
            var selectedSizeObject = sizeVariationObject.values.filter(
                function (eachValue) {
                    return eachValue.selected;
                }
            )[0];
            var onlineExclusiveIsio = $('input[name=isioOnlineExclusive]').val();
            if ($('.inStoreInventory-checkSize-message').hasClass('d-none')) {
                //  $('.js-check-in-store-inventory').removeClass('d-none');
                $('.in-store-paragraph').addClass('d-none');
            }
            if (onlineExclusiveIsio === 'true') {
                $('.instore-inventory-container .js-online-exclusive').removeClass('d-none');
                $('.inStoreInventory-checkSize-message').addClass('d-none');
                $('.js-check-in-store-inventory').addClass('d-none');
            } else if (selectedSizeObject) {
                $('.instore-inventory-container .js-online-exclusive').addClass('d-none');
                $('.inStoreInventory-checkSize-message').addClass('d-none');
                $('.js-check-in-store-inventory').removeClass('d-none');
            } else {
                $('.js-check-in-store-inventory').addClass('d-none');
                $('.inStoreInventory-checkSize-message').removeClass('d-none');
                $('.instore-inventory-container .js-online-exclusive').addClass('d-none');
            }
            var oldsizelabel = $productContainer.find('.selected-size-name').data('oldsizelabel');
            var sizelabel = oldsizelabel;
            var activeHybridSizingTab = $('#hybridSizingTabList .nav-link.active').attr('title');
            // var activeHybridSizingCountry = $('#hybridSizingTabList .nav-link.active').attr('data-sizetab-country');
            if (sizeVariationObject.values && (sizeVariationObject.values.length === 1) && (sizeVariationObject.values[0].id.toLowerCase() === 'one size')) {
                sizelabel = $productContainer.find('.selected-size-name').data('onesizelabel') + '<span>' + selectedSizeObject.displayValue + '</span>';
            } else {
                if (selectedSizeObject) {
                    var finalSize = selectedSizeObject.displayValue;
                    var getAlphabet = finalSize.replace(/[^A-Za-z]/g, '');
                    var getNumber = finalSize.replace(/[^\d]/g, '');
                    if (getAlphabet && getNumber) {
                        selectedSizeObject.displayValue = getNumber + getAlphabet;
                    } else {
                        selectedSizeObject.displayValue = finalSize;
                    }
                }
                if (LocalisedSizingTabs !== undefined && LocalisedSizingTabs !== null && selectedSizeObject !== undefined && LocalisedSizingTabs[activeHybridSizingTab][selectedSizeObject.displayValue] !== undefined) {
                    sizelabel = $productContainer.find('.selected-size-name').data('sizelabel') + activeHybridSizingTab + ' ' + LocalisedSizingTabs[activeHybridSizingTab][selectedSizeObject.displayValue];
                    var sizeLabelText = $("input[name$='sizeLabelName']").val();
                    if (activeHybridSizingTab !== 'US' && (LocalisedSizingTabs[activeHybridSizingTab][selectedSizeObject.displayValue] !== selectedSizeObject.displayValue)) {
                        sizeLabelText = sizeLabelText + selectedSizeObject.displayValue + ' (' + activeHybridSizingTab + ' ' + LocalisedSizingTabs[activeHybridSizingTab][selectedSizeObject.displayValue] + ')';
                    } else if (activeHybridSizingTab !== 'US') {
                        sizeLabelText = sizeLabelText + '(' + activeHybridSizingTab + ') ' + selectedSizeObject.displayValue;
                    } else {
                        sizeLabelText += selectedSizeObject.displayValue;
                    }
                    $productContainer.find('.selected-size-name').attr('data-newsizing', sizeLabelText);
                } else if (LocalisedSizing !== undefined && LocalisedSizing !== null && selectedSizeObject !== undefined && LocalisedSizing[selectedSizeObject.displayValue] !== undefined) {
                    var localSizeSameAsUs = usSizeSameAsHybridSize ? $("input[name$='sameSizeLabel']").val() + selectedSizeObject.displayValue : $productContainer.find('.selected-size-name').data('sizelabel') + ' (' + siteCode + ') ' + selectedSizeObject.displayValue;
                    var sameSize = (LocalisedSizing[selectedSizeObject.displayValue] === selectedSizeObject.displayValue) ? localSizeSameAsUs : $productContainer.find('.selected-size-name').data('sizelabel') + ' ' + selectedSizeObject.displayValue + ' (' + siteCode + ' ' + LocalisedSizing[selectedSizeObject.displayValue] + ')';
                    sizelabel = (selectedSizeObject ? sameSize : $productContainer.find('.selected-size-name').data('newsizing'));
                } else {
                    oldsizelabel = usSizeSameAsHybridSize ? $("input[name$='sameSizeSelectLabel']").val() : oldsizelabel;
                    sizelabel = (selectedSizeObject ? $productContainer.find('.selected-size-name').data('sizelabel') + ' ' + selectedSizeObject.displayValue : oldsizelabel);
                }
            }

            $productContainer
                .find('.selected-size-name')
                .html(sizelabel);
            $('#sizeSoldOutModal .selected-name-size').html(sizelabel);
            $("input[name$='usSameAsLocalSize']").attr('value', usSizeSameAsHybridSize);
        }
    }
}

/**
 * updates the inseam size values when a product attribute is selected
 * @param {Array} inSeamGroupProducts - updated the inseam size values after the attribute changed
 * @param {Array} inseamAsiaFitGroupProducts - updated the inseam size values after the attribute changed
 * @param {boolean} isAsiaFitProduct - boolean value for identifing whether product is asia fit or global fit
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateInseamAttributes(inSeamGroupProducts, inseamAsiaFitGroupProducts, isAsiaFitProduct, $productContainer) {
    var asiaFitValue = isAsiaFitProduct === true ? inseamAsiaFitGroupProducts : inSeamGroupProducts;
    if (asiaFitValue) {
        var sizeContainer = $productContainer.find('.custom-select-inseam');
        // Size update
        sizeContainer.find('.inseam-size-btns').each(function (index) {
            var $this = $(this);
            $this.find('.inseam-options-select').attr('value', asiaFitValue[index].url);
            $this.find('.inseam-options-select').attr('data-inseam-pid', asiaFitValue[index].pid);
        });
        if ($('.pvd-more-color-btn').length) {
            var moreColorsBtn = $('.pvd-more-color-btn');
            var inseamSelectedSize = $('.inseam-options-select:checked');
            var moreColorsProdIndex = $('.inseam-options-select').index(inseamSelectedSize);
            moreColorsBtn.attr('value', asiaFitValue[moreColorsProdIndex].moreColoursUrl);
            moreColorsBtn.attr('data-inseam-pid', asiaFitValue[moreColorsProdIndex].moreColoursPid);
        }
    }
}

/**
 * updates the session values when a size is selected
 * @param {Array} response - response object
 */
function setInseamDataInSession(response) {
    if (response.data.product.selectedSize !== undefined && response.data.product.selectedSize) {
        sessionStorage.setItem(response.data.product.masterProductID, response.data.product.selectedSize);
        var sizeSelected = $('.inseam-size-btns').find('input:checked').attr('value') + '&sz=' + response.data.product.selectedSize;
        $('#fitTabInseam .nav-link.active').attr('data-inseam-value', sizeSelected);
    } else {
        $('#fitTabInseam .nav-link.active').attr('data-inseam-value', $('.inseam-size-btns').find('input:checked').attr('value'));
    }
}

/**
 * updates the product quantity dropdown on changing quantity based on ATS
 * @param {Array} response - response object
 * @param {Array} quantities - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {jQuery} ats - product ATS value
 * @param {jQuery} selectedQuantity - Seleted quantity from storefront
 */
function updateSelectedQuantity(response, quantities, $productContainer, ats, selectedQuantity) {
    if (quantities && quantities.length > 0) {
        var $quantitySelect = $productContainer.find('.quantity-select');
        $('.low-stock-message').find('.error-message').hide();
        if (ats && ats <= quantities.length) {
            $quantitySelect.html(' ');
            // empty the existing elements here
            var selectedValue = selectedQuantity > ats ? ats : selectedQuantity;
            for (var i = 1; i <= ats; i++) {
                // add new items here
                if (i === selectedValue) {
                    $quantitySelect.append($('<option></option>').attr('value', i).attr('data-url', quantities[i - 1].url).attr('selected', 'selected')
                        .text(i));
                } else {
                    $quantitySelect.append($('<option></option>').attr('value', i).attr('data-url', quantities[i - 1].url).text(i));
                }
            }
            $('.quantity-select').val(selectedValue);

            if (selectedQuantity > ats) {
                $('.stock-avail-msg').hide();
                $('.low-stock-message').find('.error-message').show();
            } else if (response.data.product.isLowThreshold) {
                $('.stock-avail-msg').show();
            } else {
                $('.stock-avail-msg').hide();
            }
        } else if (response.data.product.isLowThreshold) {
            $('.stock-avail-msg').show();
        } else {
            $('.stock-avail-msg').hide();
        }
    }
}

/**
 * updates the sizes refresh when a product attribute is selected
 * @param {Array} LocalisedSizingTabs - updated the localised sizing after the size selection for each size tab
 * @param {Array} variationAttributes - the Url for the selected variation value
 */
function updateHybridSizingTabs(LocalisedSizingTabs, variationAttributes) {
    var backInStock = $('#backInStock').attr('data-featureflag');
    var soldOutMessage = $('#soldOut').val();
    if (variationAttributes) {
        var oosVariationObjectFilter = variationAttributes.filter(function (eachAttribute) {
            return eachAttribute.attributeId === 'size';
        })[0];
        var html;
        var backInStockSizes;
        var sizeAttrUpdate = '';
        var outOfStockSizesUpdate = '';
        $('.custom-select-localised-sizes').each(function () {
            // var currentTab = $(this);
            var countryCodeOfTab = $(this).attr('data-country-tab');
            html = [];
            backInStockSizes = [];
            $.each(oosVariationObjectFilter.values, function (key, eachSize) {
                var activeClass = eachSize.selected ? ' active' : '';
                var activeSize = eachSize.selected ? 'checked' : '';
                var hybridSizing;
                // var onlyHybrid = '';
                if (LocalisedSizingTabs !== undefined && LocalisedSizingTabs !== null && LocalisedSizingTabs[countryCodeOfTab][eachSize.displayValue] !== undefined) {
                    hybridSizing = LocalisedSizingTabs[countryCodeOfTab][eachSize.displayValue];
                    // onlyHybrid = LocalisedSizing[eachSize.displayValue];
                    if (eachSize.selectable) {
                        if (backInStock === 'true') {
                            sizeAttrUpdate = '<span class="size-btns' + activeClass + '" tabindex="0"><input type="radio" class="options-select" value="' + eachSize.url + '" id="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + hybridSizing + '" aria-label="' + hybridSizing + '" name="select-size-' + countryCodeOfTab + '" ' + activeSize + '><label for="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')">' + hybridSizing + '</label></span>';
                        } else {
                            sizeAttrUpdate = '<span class="size-btns" tabindex="0"><input type="radio" class="options-select" value="' + eachSize.url + '" id="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + hybridSizing + '" aria-label="' + hybridSizing + '" name="select-size-' + countryCodeOfTab + '" ' + activeSize + '><label for="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')">' + hybridSizing + '</label></span>';
                        }
                    } else if (backInStock === 'true') {
                        sizeAttrUpdate = '<span class="size-btns' + activeClass + '" tabindex="0"><input type="radio" class="options-select disabled" value="' + eachSize.url + '" id="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + hybridSizing + '" aria-label="' + hybridSizing + ' ' + soldOutMessage + '" name="select-size-' + countryCodeOfTab + '" ' + activeSize + '><label for="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')">' + hybridSizing + '</label></span>';
                        outOfStockSizesUpdate = sizeAttrUpdate.replace('aria-label="' + hybridSizing + ' ' + soldOutMessage + '"', 'aria-label="' + hybridSizing + '"');
                        backInStockSizes.push(outOfStockSizesUpdate);
                    } else {
                        sizeAttrUpdate = '<span class="size-btns" tabindex="0"><input type="radio" class="options-select" value="" id="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + hybridSizing + '" aria-label="' + hybridSizing + ' ' + soldOutMessage + '" name="select-size-' + countryCodeOfTab + '" disabled><label for="' + countryCodeOfTab + hybridSizing + '(' + eachSize.id + ')">' + hybridSizing + '</label></span>';
                    }
                    html.push(sizeAttrUpdate);
                }
            });
            $(this).empty();
            $(this).append(html);
            if ($(this).parents('.tab-pane').hasClass('active')) {
                $('#sizeSoldOutModal .custom-select-btn').empty();
                $('#sizeSoldOutModal .custom-select-btn').append(backInStockSizes);
            }
        });
        // $('.custom-select-btn .size-btns.active input').prop('checked', true);
        if (!$('#hybridSizingTabContent .tab-pane:first').find('input:checked').hasClass('disabled')) {
            $('.add-to-cart-section').removeClass('show-sold-out-btn');
        } else {
            $('.add-to-cart-section').addClass('show-sold-out-btn');
        }
    }
}

/**
 * updates the sizes refresh when a product attribute is selected
 * @param {string} hybridClass - hybrid class name
 * @param {Array} LocalisedSizing - updated the localised sizing after the size
 * @param {Array} variationAttributes - the Url for the selected variation value
 */
function updateSizeAttributes(hybridClass, LocalisedSizing, variationAttributes) {
    var backInStock = $('#backInStock').attr('data-featureflag');
    var soldOutMessage = $('#soldOut').val();
    if (variationAttributes) {
        var oosVariationObjectFilter = variationAttributes.filter(function (eachAttribute) {
            return eachAttribute.attributeId === 'size';
        })[0];
        var html = [];
        var backInStockSizes = [];
        var sizeAttrUpdate = '';
        var outOfStockSizesUpdate = '';
        /* this condition will work when custom select size dropdown is enable on PDP */
        if (enableSizeDropDownPDP === 'true') {
            let defaultSelectSizeText = $('.ll_custom-size-dropdown select').attr('data-default-option');
            sizeAttrUpdate = '<option value="' + oosVariationObjectFilter.resetUrl + '">' + defaultSelectSizeText + '</option>';
            html.push(sizeAttrUpdate);
            backInStockSizes.push(sizeAttrUpdate);
        }
        $.each(oosVariationObjectFilter.values, function (key, eachSize) {
            var activeClass = eachSize.selected ? ' active' : '';
            var activeSize = eachSize.selected ? 'checked' : '';
            var hybridSizing = eachSize.displayValue;
            var onlyHybrid = '';
            if (LocalisedSizing !== undefined && LocalisedSizing !== null && LocalisedSizing[eachSize.displayValue] !== undefined) {
                var sameSize;
                if (enableSizeDropDownPDP === 'true') {
                    sameSize = eachSize.displayValue + ' (' + siteCode + ' ' + LocalisedSizing[eachSize.displayValue] + ')';
                } else {
                    sameSize = (LocalisedSizing[eachSize.displayValue] === eachSize.displayValue) ? eachSize.displayValue : eachSize.displayValue + ' (' + siteCode + ' ' + LocalisedSizing[eachSize.displayValue] + ')';
                }
                hybridSizing = sameSize;
                onlyHybrid = LocalisedSizing[eachSize.displayValue];
            }
            if (enableSizeDropDownPDP === 'false') {
                if (eachSize.selectable) {
                    if (backInStock === 'true') {
                        sizeAttrUpdate = '<span class="size-btns' + activeClass + '" tabindex="0"><input type="radio" class="options-select" value="' + eachSize.url + '" id="' + eachSize.id + '" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + onlyHybrid + '" name="select-size" aria-label="' + hybridSizing + '"><label for="' + eachSize.id + '">' + hybridSizing + '</label></span>';
                    } else {
                        sizeAttrUpdate = '<span class="size-btns" tabindex="0"><input type="radio" class="options-select" value="' + eachSize.url + '" id="' + eachSize.id + '" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + onlyHybrid + '" name="select-size" ' + activeSize + ' aria-label="' + hybridSizing + '"><label for="' + eachSize.id + '">' + hybridSizing + '</label></span>';
                    }
                } else if (backInStock === 'true') {
                    sizeAttrUpdate = '<span class="size-btns' + activeClass + '" tabindex="0"><input type="radio" class="options-select disabled" value="' + eachSize.url + '" id="' + eachSize.id + '" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + onlyHybrid + '" name="select-size" aria-label="' + hybridSizing + ' ' + soldOutMessage + '"><label for="' + eachSize.id + '">' + hybridSizing + '</label></span>';
                    outOfStockSizesUpdate = sizeAttrUpdate.replace('aria-label="' + hybridSizing + ' ' + soldOutMessage + '"', 'aria-label="' + hybridSizing + '"');
                    backInStockSizes.push(outOfStockSizesUpdate);
                } else {
                    sizeAttrUpdate = '<span class="size-btns" tabindex="0"><input type="radio" class="options-select" value="" id="' + eachSize.id + '" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + onlyHybrid + '" name="select-size" aria-label="' + eachSize.id + ' ' + soldOutMessage + '"  disabled><label for="' + eachSize.id + '">' + hybridSizing + '</label></span>';
                }
                html.push(sizeAttrUpdate);
            } else {
                // custom select size dropdown
                var activeSelectedSize = eachSize.selected ? 'selected' : '';
                if (eachSize.selectable) {
                    sizeAttrUpdate = '<option class="options-select size-btns" value="' + eachSize.url + '" id="' + eachSize.id + '" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + onlyHybrid + '" name="select-size" ' + activeSelectedSize + '>' + hybridSizing + '</option>';
                } else {
                    sizeAttrUpdate = '<option class="options-select size-btns sold-out-size" value="' + eachSize.url + '" id="' + eachSize.id + '" data-attr-value="' + eachSize.id + '" data-attr-hybridsize="' + onlyHybrid + '" name="select-size" ' + activeSelectedSize + '>' + hybridSizing + '</option>';
                    backInStockSizes.push(sizeAttrUpdate);
                }
                html.push(sizeAttrUpdate);
            }
        });
        if (enableSizeDropDownPDP === 'false') {
            $('.custom-select-btn').empty();
            $('.attribute > .select-size > .custom-select-btn').append(html);
            $('#sizeSoldOutModal .custom-select-btn').append(backInStockSizes);
            $('.custom-select-btn .size-btns.active input').prop('checked', true);
            if (!$('.custom-select-btn .size-btns.active').find('input').hasClass('disabled')) {
                $('.add-to-cart-section').removeClass('show-sold-out-btn');
            } else {
                $('.add-to-cart-section').addClass('show-sold-out-btn');
            }
            if (hybridClass === 'hybrid-sizing') {
                $('.custom-select-btn').addClass(hybridClass);
            } else {
                $('.custom-select-btn').removeClass('hybrid-sizing');
            }
        } else {
            /* This conditions are for custom size dropdown on PDP */
            $('select[class*="ll_select-size"]').empty();
            $('.attribute > .select-size .ll_pdp-size-drodown select[class*="ll_select-size"]').append(html);
            $('#sizeSoldOutModal select[class*="ll_bisn-size-dropdown"]').append(backInStockSizes);
            $('#sizeSoldOutModal .ll_custom-select-size').selectpicker('refresh');
            pdpCustomSelector();
            if ($('.ll_custom-size-dropdown .form-control.ll_select-size').find('option:selected').hasClass('sold-out-size')) {
                $('.add-to-cart-section').addClass('show-sold-out-btn');
            } else {
                $('.add-to-cart-section').removeClass('show-sold-out-btn');
            }
            var isDefaultSelected = $('.ll_custom-size-dropdown select.ll_select-size').find('option:selected').hasClass('label-option');
            if (isDefaultSelected) {
                $('.ll_custom-size-dropdown .ll_size-dropdown-error').show();
                $('.ll_custom-size-dropdown .ll_custom-select-size').addClass('invalid-state');
            } else {
                $('.ll_custom-size-dropdown .ll_size-dropdown-error').hide();
                $('.ll_custom-size-dropdown .ll_custom-select-size').removeClass('invalid-state');
            }
            if ($('.ll_custom-size-dropdown select.ll_select-size').find('option[name="select-size"]:selected').length) {
                var llDropdownSelectedSize = $('.ll_custom-size-dropdown select.ll_select-size').find('option[name="select-size"]:selected').html();
                _satellite.track('pdp:size-dropdown-selection', {
                    selectedSize: llDropdownSelectedSize
                });
            }
        }
    }
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('input[name=productID]').val();
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" role="dialog">'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">&times;</button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    const enablePriceRoundOff = $('#enablePriceRoundOff').val();
    // show add to cart modal
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if ($('#addedToCartModal').length > 0) {
        $('.veil').remove();

        $('#addedToCartModal .modal-dialog').toggleClass('modal-dialog-centered', !jQuery.browser.mobile);
        $('#addedToCartModal .modal-title .quantity').html(response.productInforDirectCall.quantity);
        if (response.productInforDirectCall.quantity === '1') {
            $('#addedToCartModal .modal-title .quantity-single-text-js').show();
            $('#addedToCartModal .modal-title .quantity-multi-text-js').hide();
        } else {
            $('#addedToCartModal .modal-title .quantity-single-text-js').hide();
            $('#addedToCartModal .modal-title .quantity-multi-text-js').show();
        }
        $('#addedToCartModal .modal-body .quantity').html(response.quantityTotal);
        if (response.quantityTotal === 1) {
            $('#addedToCartModal .modal-body .quantity-single-text-js').show();
            $('#addedToCartModal .modal-body .quantity-multi-text-js').hide();
        } else {
            $('#addedToCartModal .modal-body .quantity-single-text-js').hide();
            $('#addedToCartModal .modal-body .quantity-multi-text-js').show();
        }

        if (response.productInfo) {
            var productInfo = response.productInfo;
            if (productInfo.showLocalImages && (productInfo.images.large[0].alt).includes('Local')) {
                $('#addedToCartModal .modal-body .product-details .product-image img').attr('src', 'https://images.lululemon.com/is/image/lululemon/' + productInfo.images.large[0].alt);
            } else {
                $('#addedToCartModal .modal-body .product-details .product-image img').attr('src', productInfo.images.large[0].url);
            }
            $('#addedToCartModal .modal-body .product-details .product-content-name').html(productInfo.name);
            $('#addedToCartModal .modal-body .product-details .product-content-color').html(productInfo.color);
            var selectedSizeText = $('.selected-size-name').html();
            if ($('.custom-select-localised-sizes').length) {
                selectedSizeText = $('.selected-size-name').attr('data-newsizing');
            }
            $('#addedToCartModal .modal-body .product-details .product-content-size').html(selectedSizeText);
            if (productInfo.price) {
                var price = productInfo.price;
                var formattedSalePrice = price.sales.formatted;
                $('#addedToCartModal .modal-body .product-details .product-content-price .markdown-prices').html(enablePriceRoundOff === 'true' ? formattedSalePrice.replace(/\.00(?=\s*\w*$)/, '') : formattedSalePrice);
                if (productInfo.price.list && productInfo.price.sales.value !== productInfo.price.list.value) {
                    var formattedListPrice = price.list.formatted;
                    $('#addedToCartModal .modal-body .product-details .product-content-price .list-price').html(enablePriceRoundOff === 'true' ? formattedListPrice.replace(/\.00(?=\s*\w*$)/, '') : formattedListPrice);
                }
            }
            $('#addedToCartModal .modal-body .product-details .product-content a').attr('href', productInfo.helpURL);
        }

        $('#addedToCartModal .modal-body .subtotal').html(response.cart.totals.subTotal);
        $('#addedToCartModal').modal();
        setTimeout(function () {
            $('#addedToCartModal .product-carousel').slick('refresh');
        }, 500);
    }
}

$(function () {
    $('.select-size, .quantity-select').change(function () {
        if ($('.custom-select-localised-sizes').length && $('.custom-select-localised-sizes .size-btns').find('input:checked').length === 0) {
            $('.size-error-message').find('.error-message').show();
            $('.custom-select.select-size').addClass('error-highlights');
        } else if (($('.custom-select-btn .size-btns').find('input:checked').length === 0 && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown .ll_select-size option[name=select-size]:selected').length === 0 && enableSizeDropDownPDP === 'true')) {
            $('.size-error-message').find('.error-message').show();
            $('.custom-select.select-size').addClass('error-highlights');
        } else {
            $('.size-error-message').find('.error-message').hide();
            $('.custom-select.select-size').removeClass('error-highlights');
        }
    });
});

/**
 * Function for bisn Modal Click
 */
function bisnModalClick() {
    $(document).on('click', '.size-sold-out-btn #bisn-size-sold-out-link, .sold-out-btn', function () {
        var $this = $(this);
        var urlToSubmit = $this.data('bisnopenmodal');
        var selectedCta = $this.parents('.isio-addToCart').length ? 'isio-NotifyMe' : $this.attr('aria-label');
        var disableSize;
        $.ajax({
            url: urlToSubmit,
            type: 'get',
            data: {},
            success: function (data) {
                var sizeContainer = $('.custom-select-btn');
                $('#sizeSoldOutModal .swatch-circle-container').html('');
                $('#sizeSoldOutModal .email-id').removeClass('is-dirty').removeClass('is-invalid');
                $('#selectSizeBtn').removeClass('sticky-size-add-to-cart');
                $('#stickyAddToCartBlock').removeClass('sticky-size-add-to-cart');
                var bisnActiveSize;
                var bisnActiveHybridSize;
                var currentActiveSize;

                // Size update
                if ($('.custom-select-localised-sizes').length) {
                    sizeContainer = $('#hybridSizingTabContent .tab-pane.active .custom-select-localised-sizes');
                    currentActiveSize = $(sizeContainer).find('.size-btns input:checked').attr('data-attr-value');
                    var activeSizeTabID = $('#hybridSizingTabList .nav-link.active').attr('title');
                    $('#sizeSoldOutModal .custom-select-btn').html('');
                    sizeContainer.find('.size-btns').each(function () {
                        var isSizeDisable = $(this).find('.options-select').hasClass('disabled');
                        var isActive = '';
                        if (isSizeDisable) {
                            var sizeValue = $(this).find('input').attr('data-attr-value');
                            var onlyHybridSize = $(this).find('input').attr('data-attr-hybridsize');
                            var finalValue = sizeValue === $(this).find('input').siblings('label').text() ? sizeValue : $(this).find('input').siblings('label').text();
                            if (currentActiveSize === sizeValue) {
                                isActive = ' active';
                            }
                            disableSize = '<span class="size-btns' + isActive + '" tabindex="0">'
                                + '<input type="radio" class="options-select" value="' + sizeValue + '" id="' + activeSizeTabID + '' + onlyHybridSize + '(' + sizeValue + ')" data-attr-value="' + sizeValue + '" data-attr-hybridsize="' + onlyHybridSize + '" name="select-size-' + activeSizeTabID + '" aria-label="' + finalValue + '"/>'
                                + '<label for="' + activeSizeTabID + onlyHybridSize + '(' + sizeValue + ')">' + finalValue + '</label>'
                                + '</span>';
                            $('#sizeSoldOutModal .custom-select-btn').append(disableSize);
                        }
                    });
                    bisnActiveSize = $('#sizeSoldOutModal .size-btns.active').find('input').attr('data-attr-value');
                    bisnActiveHybridSize = $('#sizeSoldOutModal .size-btns.active').find('input').attr('data-attr-hybridsize');
                    $('#bisn-hybrid-size-tab-country').val(activeSizeTabID);
                } else if (enableSizeDropDownPDP === 'false') {
                    currentActiveSize = $('.custom-select-btn .size-btns').find('input:checked').attr('data-attr-value');
                    $('#sizeSoldOutModal .custom-select-btn').html('');
                    sizeContainer.find('.size-btns').each(function () {
                        var isSizeDisable = $(this).find('.options-select').hasClass('disabled');
                        var isActive = '';
                        if (isSizeDisable) {
                            var sizeValue = $(this).find('input').attr('data-attr-value');
                            var onlyHybridSize = $(this).find('input').attr('data-attr-hybridsize');
                            var finalValue = sizeValue === $(this).find('input').siblings('label').text() ? sizeValue : $(this).find('input').siblings('label').text();
                            if (currentActiveSize === sizeValue) {
                                isActive = ' active';
                            }
                            disableSize = '<span class="size-btns' + isActive + '" tabindex="0">'
                                + '<input type="radio" class="options-select" value="' + sizeValue + '" data-attr-value="' + sizeValue + '" data-attr-hybridsize="' + onlyHybridSize + '" name="select-size" aria-label="' + finalValue + '"/>'
                                + '<label for="' + sizeValue + '">' + finalValue + '</label>'
                                + '</span>';
                            $('#sizeSoldOutModal .custom-select-btn').append(disableSize);
                        }
                    });
                    bisnActiveSize = $('#sizeSoldOutModal .size-btns.active').find('input').attr('data-attr-value');
                    bisnActiveHybridSize = $('#sizeSoldOutModal .size-btns.active').find('input').attr('data-attr-hybridsize');
                } else {
                    $('#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle').removeClass('is-dirty').attr('disabled', false);
                    bisnActiveSize = $('#sizeSoldOutModal .ll_bisn-size-dropdown .options-select.sold-out-size:selected').attr('data-attr-value');
                    bisnActiveHybridSize = $('#sizeSoldOutModal .ll_bisn-size-dropdown .options-select.sold-out-size:selected').attr('data-attr-hybridsize');
                }
                $('#bisn-register-size').val(bisnActiveSize);
                $('#bisn-hybrid-size').val(bisnActiveHybridSize);

                // Color update
                var selectColor = $('.color-group').find('.pdp-swatches').find('.swatch-circle-container.selected').html();
                var selectedColorValue = $('.color-group').find('.pdp-swatches').find('.swatch-circle-container.selected').data('attr-value');
                var selectedColorDisplayValue = $('.color-group').find('.pdp-swatches').find('.swatch-circle-container.selected').attr('title');
                $('#sizeSoldOutModal .swatch-circle-container').append(selectColor);
                $('#sizeSoldOutModal .swatch-circle-container.selected').find('.swatch-circle.selected').removeClass('unselectable');
                $('#sizeSoldOutModal .selected-swatch-name').text($('.color-group').find('.pdp-swatches').find('.swatch-circle-container.selected').attr('title'));
                $('#bisn-register-color').val(selectedColorValue);
                $('#bisn-register-color-value').val(selectedColorDisplayValue);
                $('#sizeSoldOutModal .notified-btn').attr('disabled', false);

                // Token update
                $('#bisn-register-csrf-tokenName').val(data.csrf.token);
                $('#bisn-register-csrf-tokenName').attr('name', data.csrf.tokenName);

                // Show modal
                $('#sizeSoldOutModal').attr('data-previouslabel', selectedCta);
                $('#sizeSoldOutModal').modal('show');
                var bisnSizeLabel = $('.selected-name-size').attr('data-oldsizelabel');
                if ($("input[name$='usSameAsLocalSize']").val() === 'true') {
                    bisnSizeLabel = $("input[name$='sameSizeSelectLabel']").val();
                }
                if (($('.custom-select-btn .size-btns').find('input:checked').hasClass('disabled') && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown .ll_select-size option.sold-out-size:selected').length && enableSizeDropDownPDP === 'true')) {
                    $('#sizeSoldOutModal .selected-name-size').html($('.selected-size-name').html());
                } else {
                    $('#sizeSoldOutModal .selected-name-size').text(bisnSizeLabel);
                }

                // Pre Populate email address field for logged in customers
                if (data.custEmail) {
                    $('#sizeSoldOutModal .email-id').val(data.custEmail);
                    $('#sizeSoldOutModal .email-id').addClass('valid-email');
                    if ($('#sizeSoldOutModal .email-id').valid()) {
                        $('#sizeSoldOutModal .email-id').attr('readonly', true);
                    } else {
                        $('#sizeSoldOutModal .email-id').prop('readonly', false);
                    }
                }
                // reCaptcha token generation
                var responseToken = '';
                var siteKey = $('.bisn-register').data('captcha-sitekey');
                var source = $('.bisn-register').data('captcha-source');

                if (siteKey) {
                    $.getScript(
                        'https://www.google.com/recaptcha/api.js?render=' + siteKey,
                        function () {
                            grecaptcha.ready(function () {
                                grecaptcha
                                    .execute(siteKey, { action: source })
                                    .then(function (token) {
                                        responseToken = token;
                                        $('input.captcha-token-val').val(responseToken);
                                    });
                            });
                        }
                    );
                }
                var productJsonData = getProdctSatelliteData();
                _satellite.track('pdp-notify-me', {
                    previousCTA: selectedCta,
                    product: productJsonData
                });
            },
            error: function () {
                // Error handling
            }
        });
    });
}

/**
 * Function for bisn Modal Open
 */
function bisnModalOpen() {
    $('#sizeSoldOutModal').on('shown.bs.modal', function () {
        if (enableSizeDropDownPDP === 'false') {
            $(document).on('click', '#sizeSoldOutModal .size-btns', function () {
                if (!$(this).hasClass('active')) {
                    $('#sizeSoldOutModal .size-btns.active').find('input').prop('checked', false);
                }
                $('#sizeSoldOutModal .size-btns').removeClass('active');
                $(this).addClass('active');
                $('#bisn-register-size').val($(this).find('input').attr('data-attr-value'));
                $('#bisn-hybrid-size').val($(this).find('input').attr('data-attr-hybridsize'));
                $('#sizeSoldOutModal .size-message-error').find('.message-error').hide();
            });
        } else {
            $('#sizeSoldOutModal .ll_bisn-custom-size-dropdown .ll_size-dropdown-error').hide();
            $('#sizeSoldOutModal .ll_bisn-custom-size-dropdown .ll_custom-select-size').removeClass('invalid-state');
        }
        /* this change event will work for the custom size selector in PDP */
        $(document).on('change', '#sizeSoldOutModal .ll_bisn-size-dropdown', function () {
            $('#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle').removeClass('is-dirty').attr('disabled', false);
            $('#bisn-register-size').val($(this).find('option:selected').attr('data-attr-value'));
            $('#bisn-hybrid-size').val($(this).find('option:selected').attr('data-attr-hybridsize'));
        });
        $(document).on('focusout', '#sizeSoldOutModal .ll_bisn-size-dropdown, #bisn-form-email, #bisn-checkbox', function () {
            $('#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle').removeClass('is-dirty').attr('disabled', false);
        });
        $(document).on('click', '#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle', function () {
            $('#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle').removeClass('is-dirty').attr('disabled', false);
        });

        $('form.bisn-register').submit(function (e) {
            var form = $(this);
            var customerEmail = $('#sizeSoldOutModal .email-id').val();
            e.preventDefault();
            e.stopImmediatePropagation();
            var submitUrl = form.attr('action');
            var validateForm = $('form.bisn-register').validate();
            if (validateForm && validateForm.check('#sizeSoldOutModal .email-id')) {
                if ($('#sizeSoldOutModal .custom-select-btn').find('.active').length === 0 && enableSizeDropDownPDP === 'false') {
                    $('#sizeSoldOutModal .size-message-error').find('.message-error').show().trigger('focus');
                    return false;
                } else if ($('#sizeSoldOutModal .ll_bisn-size-dropdown option.sold-out-size:selected').length === 0 && enableSizeDropDownPDP === 'true') {
                    $('#sizeSoldOutModal .ll_bisn-custom-size-dropdown .ll_size-dropdown-error').show().trigger('focus');
                    $('#sizeSoldOutModal .ll_bisn-custom-size-dropdown .ll_custom-select-size').addClass('invalid-state');
                    return false;
                } else if ($('#bisn-checkbox').prop('checked') === false) {
                    $('.bisn-checkbox-optin .invalid-feedback').show().trigger('focus');
                    return false;
                }
                form.spinner().start();
                $.ajax({
                    url: submitUrl,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        form.spinner().stop();
                        if (!data.success) {
                            if (data.genError) {
                                // Render generic error message
                                $('#sizeSoldOutModal .bisn-generic-message-error').find('.message-error').show();
                            } else {
                                var formValidation = require('base/components/formValidation');
                                formValidation(form, data);
                            }
                        } else {
                            $('.subscription-block').hide();
                            $('#sizeSoldOutModal .success-email-shown').text(customerEmail);
                            $('.success-block').show();
                            var productJsonData = getProdctSatelliteData();
                            var previousCTA = $('#sizeSoldOutModal').attr('data-previouslabel');
                            _satellite.track('modal:notify-me:success', {
                                previousCTA: previousCTA,
                                product: productJsonData
                            });
                        }
                    },
                    error: function () {
                        form.spinner().stop();
                        // Error handling
                    }
                });
            }
            return false;
        });
        $('#sizeSoldOutModal .size-message-error').find('.message-error').hide();
        $('#sizeSoldOutModal .bisn-generic-message-error').find('.message-error').hide();
        $('#sizeSoldOutModal .email-address-block').find('.email-invalid').hide();
        $('#sizeSoldOutModal .email-id').removeClass('valid-email');
        $('#sizeSoldOutModal .email-id').removeClass('is-invalid');
        $('#sizeSoldOutModal .email-id').val('');
        $('.subscription-block').show();
        $('.success-block').hide();
        if ($('#siteCountryCode').val() === 'DE') {
            $('#bisn-checkbox').prop('checked', false);
        } else {
            $('#bisn-checkbox').prop('checked', true);
        }
        $('.size-error-message').find('.error-message').addClass('d-none');
    });

    $('#sizeSoldOutModal').on('hidden.bs.modal', function () {
        $('.size-error-message').find('.error-message').hide();
        $('.size-error-message').find('.error-message').removeClass('d-none');
        $('#sizeSoldOutModal .size-btns').removeClass('active');
        $('.bisn-checkbox-optin .invalid-feedback').hide();
    });
    $(document).on('keyup', '#bisn-form-email', function () {
        $('#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle').attr('disabled', false);
        var validateForm = $('form.bisn-register').validate();
        if (validateForm && validateForm.check('#sizeSoldOutModal .email-id')) {
            $('#sizeSoldOutModal .email-id').addClass('valid-email');
        } else {
            $('#sizeSoldOutModal .email-id').removeClass('valid-email');
        }
        $('.size-error-message').find('.error-message').hide();
    });
    $(document).on('change', '#sizeSoldOutModal .form-check-input', function () {
        $('#sizeSoldOutModal .ll_custom-select-size .dropdown-toggle').attr('disabled', false);
        if ($('#bisn-checkbox').prop('checked') === true) {
            $('.bisn-checkbox-optin .invalid-feedback').hide();
        }
    });
    $(document).on('keyup', '.ll_bisn-custom-size-dropdown .inner.show', function (e) {
        if (document.activeElement === $('.ll_bisn-custom-size-dropdown .inner.show')[0] && e.keyCode === 9) {
            $(this).find('li.active a.active').trigger('focus');
        }
    });

    var scrollTo = $('.product-detail-content').find('.attribute').children('.color-group').last();
    $('.size-select-btn').on('click', function () {
        $('html, body').animate({
            scrollTop: $(scrollTo).offset().top + 25
        }, 500);
    });
}

module.exports = {
    addToCart: function () {
        $(document).on('click', 'button.find-similar-items', function () {
            var similarItemsURL = $('.find-similar-items-url').val();
            window.location.href = similarItemsURL;
        });
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var currentClass = $(this).closest('.add-to-cart-section').parent().attr('class');
            var adding = $('#stickyAddToCartBlock .adding').text();
            var added = $('#stickyAddToCartBlock .added').html();
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var mediaQuery = window.matchMedia('(max-width: 768px)');
            if (($('.custom-select-localised-sizes').length && $('.custom-select-localised-sizes .size-btns').find('input:checked').length === 0) || ($('.select-size .size-btns').find('input:checked').length === 0 && enableSizeDropDownPDP === 'false')) {
                $('.size-error-message').find('.error-message').show();
                $('.custom-select.select-size').addClass('error-highlights');
                if (mediaQuery.matches) {
                    utilities.scrollToElement($('.select-size'));
                    return;
                }
                return;
            } else if ($('.ll_pdp-size-drodown .ll_custom-select-size option[name=select-size]:selected').length === 0 && enableSizeDropDownPDP === 'true') {
                $('.ll_pdp-size-drodown .ll_size-dropdown-error').show();
                $('.ll_pdp-size-drodown .ll_custom-select-size').addClass('invalid-state');
                return;
            }
            $('.size-error-message').find('.error-message').hide();

            var quantityValue = $('.quantity-select').find('option:selected').val();
            var itemStock = parseInt($('#item-stock-indicator').val(), 10);
            if (quantityValue > itemStock) {
                $('.quantity-error-message').find('.error-message').show();
                if (mediaQuery.matches) {
                    utilities.scrollToElement($('.quantity-select'));
                    return;
                }
                return;
            }
            $('.quantity-error-message').find('.error-message').hide();
            $('.custom-select.quantity-select').removeClass('error-highlights');

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }
            if ($('.custom-select-localised-sizes').length) {
                form.selectedSizeTabCountry = $('#hybridSizingTabList .nav-link.active').attr('id');
            }
            $('#stickyAddToCartBlock .add-to-cart').text(adding);
            $('#stickyAddToCartBlock .add-to-cart').addClass('pressed');

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if (currentClass === 'add-to-cart-btn-clone') {
                            setTimeout(function () {
                                handlePostCartAdd(data);
                                $('body').trigger('product:afterAddToCart', data);
                                $('#stickyAddToCartBlock').removeClass('sticky-size-add-to-cart');
                            }, 1000);
                        } else {
                            handlePostCartAdd(data);
                            $('body').trigger('product:afterAddToCart', data);
                            $('#stickyAddToCartBlock').removeClass('sticky-size-add-to-cart');
                        }
                        if (window.cfCacheEnabled) {
                            $.cookie('isDynamicData', true, { expires: 30, path: '/' });
                        }
                        $('#stickyAddToCartBlock .add-to-cart').html(added);
                        $('#stickyAddToCartBlock .add-to-cart').css({ 'background-color': '#921620', border: 'solid 1px #921620' });
                    },
                    error: function () {
                    }
                });
            }
        });
        var imageZoom = require('./imageZoom');
        imageZoom.getZoomImage();
    },

    afterUpdateAvailability: function () {
        $('body').on('product:updateAvailability', function () {
            if ($('.pdp-swatches button').hasClass('selected') === false) {
                $('.add-to-cart').attr('disabled', true);
            } else {
                $('.add-to-cart').prop('disabled', false);
            }
        });
    },

    afterAttributeSelect: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            const enablePriceRoundOff = $('#enablePriceRoundOff').val();
            // Update product ID, this should be updated on variation change. ID used for add product to cart
            $('input[name=productID]').val(response.data.product.id);

            updateSelectedSwatchProductName(
                response.data.product.variationAttributes,
                response.container
            );
            if ($('.inseam-size-btns').length > 0) {
                updateInseamAttributes(
                    response.data.product.inSeamGroupProducts,
                    response.data.product.inseamAsiaFitGroupProducts,
                    response.data.product.isAsiaFitProduct,
                    response.container
                );
                setInseamDataInSession(response);
            }
            var localisiedData;
            var localisiedTabData;
            if (response.data.LocalisedSizingTabs && Object.keys(response.data.LocalisedSizingTabs).length > 0) {
                localisiedTabData = response.data.LocalisedSizingTabs;
            }
            if (response.data.LocalisedSizing) {
                localisiedData = response.data.LocalisedSizing;
            }
            updateSelectedSizeName(
                localisiedTabData,
                localisiedData,
                response.data.product.variationAttributes,
                response.container,
                response.data.localisedSizeSameAsUS
            );
            if (response.data.LocalisedSizingTabs && Object.keys(response.data.LocalisedSizingTabs).length > 0) {
                updateHybridSizingTabs(
                    response.data.LocalisedSizingTabs,
                    response.data.product.variationAttributes
                );
            } else {
                updateSizeAttributes(
                    response.data.HybridClass,
                    localisiedData,
                    response.data.product.variationAttributes
                );
            }
            var backInStock = $('#backInStock').attr('data-featureflag');
            if (backInStock === 'true') {
                $('#sizeSoldOutModal .custom-select-btn .size-btns').find('.options-select').removeClass('disabled');
            }
            updateSelectedQuantity(
                response,
                response.data.product.quantities,
                response.container,
                response.data.product.ats,
                response.data.product.selectedQuantity
            );
            updatePostAjax(response.data.product, response.container);
            if ($('.skuAlignmentNewPDP').length > 0) {
                $('.skuAlignmentNewPDP').find('.product-id').text(response.data.product.id);
            }

            // wishlist changes only for pdp on size/color update
            const $wishlistIcon = $('.wishlist-icon-pdp.product');
            if ($wishlistIcon.length > 0) {
                const wishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
                $wishlistIcon.attr('id', response.data.product.id).attr('data-pid', response.data.product.id);
                const $wishlistTile = $wishlistIcon.find('.wishlistTile');
                $wishlistTile.addClass('remove-from-wishlist');
                const removeURL = $wishlistTile.attr('data-remove-url').split('?pid=')[0] + `?pid=${response.data.product.id}`;
                $wishlistTile.attr('data-remove-url', removeURL);
                $wishlistTile.attr('href', $wishlistTile.data('remove-url'));
                $('.wishlist-icon-pdp.product i').addClass('icon-wishlist-o').removeClass('icon-wishlist-s');
                const addToWishlist = $wishlistTile.attr('data-add-wishlist');
                const removeFromWishlist = $wishlistTile.attr('data-remove-wishlist');
                $wishlistTile.attr('title', removeFromWishlist);
                // if not present in wishlist, update href and title
                if (wishlistItems.indexOf(response.data.product.id) === -1) {
                    $wishlistTile.removeClass('remove-from-wishlist');
                    $wishlistTile.attr('href', $wishlistTile.data('add-url'));
                    $wishlistTile.attr('title', addToWishlist);
                }
                $('body').trigger('wishlist:updateWishlistState');
            }

            var itemStockValue = response.data.product.instockValue;
            $('#item-stock-indicator').attr('value', itemStockValue);
            var quantityValue = $('.quantity-select').find('option:selected').val();
            var itemStock = parseInt($('#item-stock-indicator').val(), 10);
            if ((($('.select-size .size-btns').find('input:checked').length !== 0 && enableSizeDropDownPDP === 'false') || ($('.form-control.ll_select-size').find('option[name="select-size"]:selected').length && enableSizeDropDownPDP === 'true')) && quantityValue > itemStock) {
                $('.quantity-error-message').find('.error-message').show();
            } else {
                $('.quantity-error-message').find('.error-message').hide();
                $('.custom-select.quantity-select').removeClass('error-highlights');
            }

            if (response.data.displayAfterpayMessage) {
                response.container
                    .find('.afterpay-wrapper .afterpay-msg')
                    .text(response.data.product.afterpayInstallmentMsg);
            }
            if (response.data.product.productType === 'variant') {
                var selectedColor = $('.pdp-swatches button.selected').closest('.color-group').find('.color');
                var formattedSalePrice = response.data.product.price.sales.formatted;
                if (selectedColor.find('.markdown-prices').length) {
                    selectedColor.find('.markdown-prices').html(enablePriceRoundOff === 'true' && formattedSalePrice ? formattedSalePrice.replace(/\.00(?=\s*\w*$)/, '') : formattedSalePrice);
                } else {
                    selectedColor.html(enablePriceRoundOff === 'true' ? formattedSalePrice.replace(/\.00(?=\s*\w*$)/, '') : formattedSalePrice);
                }
                if (selectedColor.find('.list-price').length && response.data.product.price.list && response.data.product.price.list.value !== response.data.product.price.sales.value) {
                    var formattedListPrice = response.data.product.price.list.formatted;
                    selectedColor.find('.list-price').html(enablePriceRoundOff === 'true' && formattedListPrice ? formattedListPrice.replace(/\.00(?=\s*\w*$)/, '') : formattedListPrice);
                } else {
                    selectedColor.find('.list-price').html('');
                }
            }

            if (response.data.product.pdpKlarnaOSM) {
                $('.cta-price-value').css('min-height', 'auto');
                var ctaPriceHeight = $('.swatch-circle-container.selected').closest('.color-group').find('.cta-price-value').height();
                $('.swatch-circle-container.selected').closest('.color-group').find('.cta-price-value').css({ display: 'block', 'min-height': ctaPriceHeight });
                $('.product-detail-content .kosm-pdp').remove();
                var currentActiveSwatch = $('.swatch-circle-container.selected').closest('.color-group').find('.display-price');
                $(response.data.product.pdpKlarnaOSM).insertAfter(currentActiveSwatch);
                window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
                window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
            }

            var imageZoom = require('./imageZoom');
            imageZoom.getZoomImage();

            $('body').trigger('variationAttributePayload', response);

            var currentSwatch = $(document.activeElement);
            if (currentSwatch.hasClass('swatch-circle-container')) {
                if (($('.select-size .size-btns').find('input.disabled').length && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown .ll_select-size option[name=select-size]').hasClass('sold-out-size') && enableSizeDropDownPDP === 'true')) {
                    _satellite.track('customImpressionTracking', {
                        impressionID: 'pdp:size-sold-out'
                    });
                }
            }
        });
    },
    bisnModalClick: bisnModalClick,
    colorSwatchSizeClick: colorSwatchSizeClick,
    bisnModalOpen: bisnModalOpen,
    getProdctSatelliteData: getProdctSatelliteData,
    productSkuUpdateOos: productSkuUpdateOos,
    sizeHeaderLabelUpdate: sizeHeaderLabelUpdate
};
/**
 * Function for Sticky Add To Cart
 */
function stickyAddToCart() {
    var scrollHeight = $(window).scrollTop();
    var targetOuterHeight = $('.product-availability').height() - 3;
    var targetHeight = ($('.product-availability').length ? $('.product-availability').offset().top : 0) + targetOuterHeight;
    var addToCartStickyHeight = ($('.pdp-footer').length ? $('.pdp-footer').offset().top : 0) - $(window).height();
    var productName = $('.product-detail-content .product-name').html();
    var deskTopFooterHeight = ($('.footer-social-container').length ? $('.footer-social-container').offset().top : 0) - $(window).height();
    var mobileWindowWidth = $(window).width() < 1024;
    var desktopWindowWidth = $(window).width() > 1023;
    $('#stickyAddToCartBlock .product-size').html('');
    $('.select-size-btn .product-name').html('');
    if ($('.custom-select-inseam').length > 0) {
        $('.sticky-section .product-size-inseam').html($('.product-size-inseam').attr('data-inseam-label') + $('.inseam-size-btns input:checked').siblings('label').html());
    }
    $('#stickyAddToCartBlock .add-to-cart-btn-clone').html('');
    $('.qty-container .add-to-cart-section').clone().appendTo('#stickyAddToCartBlock .add-to-cart-btn-clone');
    if ($('.add-to-cart-btn-clone .add-to-cart-section button').length) {
        $('.add-to-cart-btn-clone .add-to-cart-section button').each(function () {
            $(this).attr('tabindex', '-1');
        });
    }
    if ($('.add-to-cart-btn-clone .add-to-cart-section .add-to-cart .markdown-prices').length) {
        $('.add-to-cart-btn-clone .add-to-cart-section .add-to-cart .markdown-prices').attr('tabindex', '-1');
    }
    var selectedSizeInfo = $('.custom-select-localised-sizes').length === 0 ? $('.selected-size-name').html() : $('.selected-size-name').attr('data-newsizing');
    $('#stickyAddToCartBlock .product-size').append(selectedSizeInfo);
    $('.select-size-btn .product-name').append('<span>' + productName + '</span>');
    var selectedColorValue = $('.color-group').find('.swatch-circle-container.selected').find('.color-value').attr('data-attr-value');
    var stickyAddToCartColorValue = $('#selectSizeBtn').find('.color-value').attr('data-attr-value');
    if (selectedColorValue !== stickyAddToCartColorValue) {
        $('.select-size-btn .product-color').html('');
        var colorName = $('.color-group').find('.swatch-circle-container.selected').attr('title');
        $('.select-size-btn .product-color').append($('.color-group').find('.swatch-circle-container.selected').html() + '<span class="tooltip">' + colorName + '</span>');
    }
    if ((mobileWindowWidth && scrollHeight > targetHeight && addToCartStickyHeight > scrollHeight) || (desktopWindowWidth && scrollHeight > targetHeight && deskTopFooterHeight > scrollHeight)) {
        if (($('.select-size .size-btns').find('input:checked').length === 0 && enableSizeDropDownPDP === 'false') || ($('.ll_custom-size-dropdown .ll_select-size option[name=select-size]:selected').length === 0 && enableSizeDropDownPDP === 'true')) {
            $('#selectSizeBtn').addClass('sticky-size-add-to-cart');
        } else {
            $('#stickyAddToCartBlock').addClass('sticky-size-add-to-cart');
        }
    } else {
        $('#selectSizeBtn').removeClass('sticky-size-add-to-cart');
        $('#stickyAddToCartBlock').removeClass('sticky-size-add-to-cart');
    }
}

var stickyAddToCartDebounce = debounce(stickyAddToCart, 20);

$(window).on('scroll', function () {
    stickyAddToCartDebounce();
});

stickyAddToCart();

$(window).resize(function () {
    stickyAddToCart();
});
