/* global _satellite */
'use strict';
var Swiper = require('./thirdParty/swiper');
var MIN_COLLECTION_FILTER_SLIDE_COUNT;

/**
 * Init collection swiper carousel
 *
 * @param {jQuery} el - jQuery element
 * @return {Object} - swiper object
 */
function initSwiper(el) {
    var mySwiper = new Swiper(el, {
        slidesPerView: 2.1,
        freeMode: true,
        spaceBetween: 8,
        watchSlidesVisibility: true,
        keyboard: true,
        breakpoints: {
            768: {
                slidesPerView: 3.55,
                slidesPerGroup: 1,
                spaceBetween: 24,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            1024: {
                slidesPerView: 4,
                slidesPerGroup: 1,
                spaceBetween: 24,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        },
        on: {
            slideNextTransitionEnd: function () {
                _satellite.track('carousel:right-clicked');
                $('.experience-dynamic-filterTile.swiper-slide-visible a, .experience-dynamic-filterTile.swiper-slide-visible ul.educational-bullets li').attr('tabindex', 0);
                $('.experience-dynamic-filterTile:not(.swiper-slide-visible) a, .experience-dynamic-filterTile:not(.swiper-slide-visible) ul.educational-bullets li').attr('tabindex', -1);
                $('.collection-filter-section .swiper-button.swiper-button-disabled').attr('tabindex', -1);
                $('.collection-filter-section .swiper-button:not(.swiper-button-disabled)').attr('tabindex', 0);
                $('.collection-filter-section .swiper-slide-visible').last().find('.image-wrapper')[0].focus();
            },
            slidePrevTransitionEnd: function () {
                _satellite.track('carousel:left-clicked');
                $('.experience-dynamic-filterTile.swiper-slide-visible a, .experience-dynamic-filterTile.swiper-slide-visible ul.educational-bullets li').attr('tabindex', 0);
                $('.experience-dynamic-filterTile:not(.swiper-slide-visible) a, .experience-dynamic-filterTile:not(.swiper-slide-visible) ul.educational-bullets li').attr('tabindex', -1);
                $('.collection-filter-section .swiper-button.swiper-button-disabled').attr('tabindex', -1);
                $('.collection-filter-section .swiper-button:not(.swiper-button-disabled)').attr('tabindex', 0);
                $('.collection-filter-section .swiper-slide-visible').first().find('.image-wrapper')[0].focus();
            },
            init: function () {
                $('.experience-dynamic-filterTile, .swiper-button').css('opacity', 1);
                $('.experience-dynamic-filterTile:not(.swiper-slide-visible) a, .experience-dynamic-filterTile:not(.swiper-slide-visible) ul.educational-bullets li').attr('tabindex', -1);
                setTimeout(function () {
                    $('.collection-filter-section .swiper-button.swiper-button-disabled').attr('tabindex', -1);
                    $('.collection-filter-section .swiper-button:not(.swiper-button-disabled)').attr('tabindex', 0);
                }, 200);
            }
        }
    });

    return mySwiper;
}

/**
 * Init Collection Filter carousel
 */
function initCarousel() {
    var swiperEl;
    $('.collection-filter-section').each(function () {
        var $this = $(this);
        var swiperElInnerWrapper = $('.collection-filter-swiper-init');
        var swiperNavButton = $('.collection-filter-section .swiper-button');
        if (window.matchMedia('(min-width: 1024px)').matches) {
            MIN_COLLECTION_FILTER_SLIDE_COUNT = 5;
        } else if (window.matchMedia('(min-width: 768px)').matches && window.matchMedia('(max-width: 1023px)').matches) {
            MIN_COLLECTION_FILTER_SLIDE_COUNT = 4;
        } else {
            MIN_COLLECTION_FILTER_SLIDE_COUNT = 3;
        }
        var slotContentLength = $('.collection-filter-swiper-init .experience-dynamic-filterTile').not('.d-none').length;
        if (slotContentLength < MIN_COLLECTION_FILTER_SLIDE_COUNT) {
            swiperElInnerWrapper.addClass('row');
            $('.experience-dynamic-filterTile').addClass('colFilter');
            if (window.swiperElement) {
                window.swiperElement.destroy();
            }
            if (swiperEl) {
                swiperEl.destroy();
            }
            swiperElInnerWrapper.removeAttr('style');
            $('.experience-dynamic-filterTile').removeAttr('style');
            swiperNavButton.addClass('d-none');
        } else {
            swiperElInnerWrapper.addClass('swiper-wrapper');
            $('.experience-dynamic-filterTile').addClass('swiper-slide');
            swiperElInnerWrapper.removeClass('row');
            $('.experience-dynamic-filterTile').removeClass('colFilter');
            if (!$this.hasClass('swiper-container-initialized')) {
                swiperEl = initSwiper($this);
                window.swiperElement = swiperEl;
            }
            swiperNavButton.removeClass('d-none');
        }

        var avialableSlides = $('.collection-filter-swiper-init .experience-dynamic-filterTile:not(.d-none)');

        if (swiperEl) {
            var selectedSlideIndex = avialableSlides.filter('.selected').index();
            if (window.matchMedia('(min-width: 1024px)').matches && avialableSlides.length > 4 && selectedSlideIndex > 3) {
                swiperEl.slideTo(selectedSlideIndex, 1000, function () {});
            } else if ((window.matchMedia('(min-width: 768px)').matches && window.matchMedia('(max-width: 1023px)').matches) && avialableSlides.length > 3 && selectedSlideIndex > 2) {
                swiperEl.slideTo(selectedSlideIndex, 1000, function () {});
            } else if (window.matchMedia('(max-width: 768px)').matches && avialableSlides.length > 2 && selectedSlideIndex > 1) {
                swiperEl.slideTo(selectedSlideIndex, 1000, function () {});
            }
        } else {
            setTimeout(function () {
                var selectedSlide = avialableSlides.index(avialableSlides.filter('.selected'));
                if (window.matchMedia('(max-width: 767px)').matches && avialableSlides.length > 2) {
                    window.swiperElement.slideTo(selectedSlide - 1, 1000, function () {});
                }
            }, 200);
        }
    });
}

/**
 * Initialize listeners for resize
 */
function init() {
    initCarousel();
}

$(window).on('resize', function () {
    init();
});

module.exports = {
    init: init,
    initCarousel: initCarousel
};
