/* global s7viewers */
/* eslint no-new: "off"*/

'use strict';

let videoPlayers = {};
/**
 * Initialize scene7 video component
 * @param {string} id - Id String of video
 * @param {boolean} isAutoPlay - If video is auto play or no
 * @param {string} asset - Video asset
 * @return {video} - Instance of video
 */
function getVideo(id, isAutoPlay, asset) {
    videoPlayers[id] = new s7viewers.VideoViewer({
        containerId: id,
        params: {
            autoplay: isAutoPlay ? '1' : '0',
            loop: isAutoPlay ? '1' : '0',
            asset: asset,
            serverurl: '//images.lululemon.com/is/image/',
            videoserverurl: '//images.lululemon.com/is/content/'
        },
        handlers: {
            initComplete: function () {
                if (isAutoPlay) {
                    // videoPlayers[id]
                    //     .getComponent('mutableVolume')
                    //     .setPosition(0);
                    // videoPlayers[id].getComponent('mutableVolume').deactivate();
                    $('.s7mutebutton').click();
                }
            }
        }
    }).init();
    return videoPlayers[id];
}

/**
 * Initializes scene7 zoom image component
 * @param {string} asset - Name of the image asset in scene7
 * @param {string} containerId - containerId that would be used the modal
 * @returns {image} - Instance of image used for zoom component
 */
function getZoomImage(asset, containerId) {
    var zoomedImage = new s7viewers.BasicZoomViewer({
        containerId: containerId,
        params: {
            asset: 'lululemon/' + asset,
            serverurl: '//images.lululemon.com/is/image/'
        }
    }).init();
    return zoomedImage;
}

/**
 * Deletes scene7 video component
 * @param {string} id - Id String of video
 */
function deleteVideo(id) {
    if (videoPlayers[id]) {
        videoPlayers[id].dispose();
    }
}

module.exports = {
    getVideo: getVideo,
    deleteVideo: deleteVideo,
    getZoomImage: getZoomImage
};
