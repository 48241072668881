/* global _satellite */
'use strict';

require('../../icons/chevron.svg');
require('../../icons/plus.svg');
require('../../icons/minus.svg');
require('../../icons/cross.svg');
require('../../icons/crossWhite.svg');
require('../../icons/logoModal.svg');
require('../../icons/globe.svg');
require('../../icons/lululemonPrimaryLogo.svg');
require('../../icons/brand_lululemonPrimaryLogo.svg');
require('../../icons/brand_lululemonPrimaryLogoWhite.svg');

var $emailSignupPopupModal = $('#emailPopupModal');
var $emailSignupToaster = $('#js-email-signup-toaster');
var optanonConsent = $.cookie('OptanonConsent');
var optanonAlertBoxClosed = $.cookie('OptanonAlertBoxClosed');

if (sessionStorage.getItem('quickStartActiveFlag') === null) {
    sessionStorage.setItem('quickStartActiveFlag', 'false');
}

/**
 * Returns all required selectors at once in a single object
 * @param {Object} emailComponent - Email component's Jquery element
 * @returns {Object} - Returns all the selectors used by the component
 */
function getSelectors(emailComponent) {
    let emailEl = emailComponent.find('.emailsignup-popup');
    let popupForm = emailComponent.find('.email-signup-form-popup');
    let errorElement = emailComponent.find(
        '.email-address-section .invalid-feedback'
    );
    let submitBtn = emailComponent.find('.signup-action-section button.signup-popup-btn');
    let emailSignUpFormContainer = emailComponent.find(
        '.email-popup-form-container'
    );
    let emailSubmittedContainer = emailComponent.find(
        '.email-popup-submitted'
    );
    let serverError = errorElement.data('serverError');
    let loader = $('.email-signup-popup .emailsignupform-progress-loader');

    return {
        emailComponent: emailComponent,
        emailEl: emailEl,
        serverEmailErrorEl: errorElement,
        submitBtn: submitBtn,
        emailSignUpFormContainer: emailSignUpFormContainer,
        emailSubmittedContainer: emailSubmittedContainer,
        serverError: serverError,
        popupForm: popupForm,
        loader: loader
    };
}

/**
 * Submits emails address to the server
 * @param {Object} selectors - jquery selectors to hide/show sections based on server response
 */
function submitForm(selectors) {
    let popupForm = selectors.popupForm;
    let popupFormData = popupForm.serialize();
    let urlToSubmit = popupForm.data('action-url');
    let loader = selectors.loader;
    selectors.emailComponent
        .find('.email-signup-form-popup')
        .spinner()
        .start();
    var emailBodyContentHeight = $('.email-signup-body').outerHeight();
    $('.email-signup-body').outerHeight(emailBodyContentHeight);
    loader.removeClass('d-none');
    if ($emailSignupPopupModal.hasClass('emailSignupToasterTriggered')) {
        _satellite.track('email-toaster-signup-submit');
    } else {
        _satellite.track('email-signup-header-submit');
    }
    $.ajax({
        url: urlToSubmit,
        type: 'post',
        data: popupFormData,
        success: function (data) {
            loader.addClass('d-none');
            /* On successful submission, show thank you message
                On unsuccessful submission, show error state and message on input field */
            if (data.success) {
                if ($emailSignupPopupModal.hasClass('emailSignupToasterTriggered')) {
                    _satellite.track('email-toaster-signup-success');
                } else {
                    _satellite.track('email-signup-header-success');
                }
                $('.email-signup-popup .modal-dialog').addClass('form-submitted');
                setTimeout(function () {
                    $('.email-popup-submitted').removeClass('d-none');
                    $('.email-signup-body').outerHeight($('.email-popup-submitted').outerHeight());
                    $('.email-signup-body-content').addClass('d-none');
                    $('.email-signup-popup .modal-dialog').removeClass('form-submitted');
                }, 500);
                $.cookie('dontShowEmailPopup', 'true', { expires: 1000, path: '/' });
            } else {
                $('.email-signup-body').removeAttr('style');
                selectors.emailEl.addClass('is-invalid');
                selectors.serverEmailErrorEl.html(selectors.serverError);
                $('body').trigger('emailSignupPayloadError', {
                    errorMessage: selectors.serverError
                });
            }
            selectors.emailComponent
                .find('.email-signup-form-popup')
                .spinner()
                .stop();
        },
        error: function () {
            loader.addClass('d-none');
            /* On error, show error state and message on input field */
            selectors.emailEl.addClass('is-invalid');
            selectors.serverEmailErrorEl.html(selectors.serverError);
            $('body').trigger('emailSignupPayloadError', {
                errorMessage: selectors.serverError
            });
            selectors.emailComponent
                .find('.email-signup-form-popup')
                .spinner()
                .stop();
        }
    });
}

/**
 * Initializes email signup component with event listeners
 */
function init() {
    $(document).ready(function () {
        $('.email-popup-form-container').each(function () {
            let selectors = getSelectors($(this));
            selectors.submitBtn.on('click', function (event) {
                event.preventDefault();
                let popupForm = selectors.popupForm;
                // reCaptcha token generation
                var responseToken = '';
                var captchaEnabled = popupForm.data('captcha-enabled');
                var siteKey = popupForm.data('captcha-sitekey');
                var source = popupForm.data('captcha-source');

                if (captchaEnabled && siteKey) {
                    $.getScript(
                        'https://www.google.com/recaptcha/api.js?render=' + siteKey,
                            function () {
                                grecaptcha.ready(function () {
                                    grecaptcha.execute(siteKey, { action: source })
                                     .then(function (token) {
                                         responseToken = token;
                                         $('input.captcha-popup-token-val').val(responseToken);
                                         submitForm(selectors);
                                         $('.grecaptcha-badge').css('visibility', 'hidden');
                                     });
                                });
                            }
                    );
                } else {
                    submitForm(selectors);
                }
            });
        });
    });
}

var dontShowEmailPopup = $.cookie('dontShowEmailPopup');
var dontShowQuickStart = $.cookie('dontShowQuickStart');
var emailPopupTimer = (($('#emailPopUpTimer').length > 0) && ($('#emailPopUpTimer').val() !== 'null')) ? parseInt($('#emailPopUpTimer').val(), 10) * 1000 : 30000;
var doNotShowPopupBefore = (($('#doNotShowPopupBefore').length > 0) && ($('#doNotShowPopupBefore').val() !== 'null')) ? parseInt($('#doNotShowPopupBefore').val(), 10) : 7;
var timeoutLapsed = false;
var doNotShowQuickStartBefore = (($('#showQuickStartPopUpAfter').length > 0) && ($('#showQuickStartPopUpAfter').val() !== 'null')) ? parseInt($('#showQuickStartPopUpAfter').val(), 10) : 6;
var expShowQuickDate = new Date();
expShowQuickDate.setTime(expShowQuickDate.getTime() + (doNotShowQuickStartBefore * 3600 * 1000));

/**
 * quick start display handler
*/
function quickStartHandler() {
    if (($('#countrySelectorModal').hasClass('show') === false) && (optanonAlertBoxClosed || !optanonConsent) && !($emailSignupPopupModal.hasClass('show')) && !dontShowQuickStart && ($.cookie('dontShowEmailPopup') || (sessionStorage.getItem('enableSignUpPopModalFlag') === 'false'))) {
        setTimeout(function () {
            if (sessionStorage.getItem('quickStartActiveFlag') === 'false') {
                _satellite.track('customImpressionTracking', {
                    impressionID: 'pick-up-where-you-left-off-component'
                });
            } else {
                sessionStorage.setItem('quickStartActiveFlag', true);
            }
            $('.quickStartWrapper').addClass('show');
        }, 400);
    } else {
        $('.quickStartWrapper').removeClass('show');
        sessionStorage.setItem('quickStartActiveFlag', false);
    }
}
/**
 * Submits emails address to the server
 * @param {Object} Optanon - jquery selectors to hide/show sections based on server response
 * Show email popup modal
 */
function showModal() {
    $(document).ready(function () {
        var enableSignUpPopModalFlag = sessionStorage.getItem('enableSignUpPopModalFlag');
        if (enableSignUpPopModalFlag === null) {
            sessionStorage.setItem('enableSignUpPopModalFlag', 'false');
            enableSignUpPopModalFlag = 'false';
        } else if (sessionStorage.getItem('enableSignUpPopModalFlag') === 'false') {
            sessionStorage.setItem('enableSignUpPopModalFlag', 'true');
            enableSignUpPopModalFlag = 'true';
        }

        var enableFollowSignUpPopModalFlag = sessionStorage.getItem('enableFollowSignUpPopModalFlag');
        if (enableFollowSignUpPopModalFlag === null) {
            sessionStorage.setItem('enableFollowSignUpPopModalFlag', 'false');
            enableFollowSignUpPopModalFlag = 'false';
        }

        var enableFollowEmailToasterFlag = sessionStorage.getItem('enableFollowEmailToasterFlag');
        if (enableFollowEmailToasterFlag === null) {
            sessionStorage.setItem('enableFollowEmailToasterFlag', 'false');
            enableFollowEmailToasterFlag = 'false';
        }

        if (($('#home-page-indicator, #cdp-page-indicator, #catLanding-indicator').length >= 1) && enableFollowSignUpPopModalFlag === 'true' && !dontShowEmailPopup) {
            setTimeout(function () {
                $emailSignupPopupModal.addClass('emailSignupToasterTriggered');
                $('body').addClass('modal-open-custom');
                if (window.matchMedia('(max-width: 1023px)').matches) {
                    $emailSignupPopupModal.modal({
                        keyboard: false,
                        focus: false,
                        backdrop: 'static'
                    });
                } else {
                    $emailSignupPopupModal.modal({
                        keyboard: false,
                        focus: false,
                        backdrop: false
                    });
                }
            }, 500);
        } else {
            if (enableFollowEmailToasterFlag === 'true') {
                emailPopupTimer = 500;
            }
            setTimeout(function () {
                if (($('#home-page-indicator, #cdp-page-indicator, #catLanding-indicator').length >= 1) && enableSignUpPopModalFlag === 'true') {
                    if (($('#countrySelectorModal').hasClass('show') === false) && (optanonAlertBoxClosed || !optanonConsent) && !($emailSignupPopupModal.hasClass('show'))) {
                        if (!dontShowEmailPopup) {
                            if ($emailSignupToaster.hasClass('email-signup-toaster-enabled')) {
                                $emailSignupToaster.addClass('show');
                                _satellite.track('email-toaster-impression');
                                $emailSignupToaster.find('.toaster-signup-cta').attr('aria-hidden', 'false');
                                $emailSignupToaster.find('.toaster-text').attr('aria-hidden', 'false');
                                $emailSignupToaster.find('.close-cta').attr('aria-hidden', 'false');
                                $('#js-email-signup-toaster .close-cta, #js-email-signup-toaster .toaster-signup-cta').on('click', function () {
                                    $emailSignupToaster.removeClass('show');
                                    $emailSignupToaster.find('.toaster-signup-cta').attr('aria-hidden', 'true');
                                    $emailSignupToaster.find('.toaster-text').attr('aria-hidden', 'true');
                                    $emailSignupToaster.find('.close-cta').attr('aria-hidden', 'true');
                                    $emailSignupPopupModal.addClass('emailSignupToasterTriggered');
                                });
                                $('#js-email-signup-toaster .close-cta').on('click', function () {
                                    $.cookie('dontShowEmailPopup', 'true', { expires: doNotShowPopupBefore, path: '/' });
                                    setTimeout(function () {
                                        quickStartHandler();
                                    }, 500);
                                    _satellite.track('email-toaster-signup-close');
                                });
                                $('.toaster-signup-cta').on('click', function () {
                                    _satellite.track('email-toaster-signup');
                                    if (window.matchMedia('(max-width: 1023px)').matches) {
                                        $emailSignupPopupModal.modal({
                                            keyboard: false,
                                            focus: false,
                                            backdrop: 'static'
                                        });
                                    } else {
                                        $emailSignupPopupModal.modal({
                                            keyboard: false,
                                            focus: false,
                                            backdrop: false
                                        });
                                    }
                                });
                            } else {
                                $emailSignupPopupModal.addClass('emailSignupToasterTriggered');
                                if (window.matchMedia('(max-width: 1023px)').matches) {
                                    $emailSignupPopupModal.modal({
                                        keyboard: false,
                                        focus: false,
                                        backdrop: 'static'
                                    });
                                } else {
                                    $emailSignupPopupModal.modal({
                                        keyboard: false,
                                        focus: false,
                                        backdrop: false
                                    });
                                }
                            }

                            sessionStorage.setItem('enableFollowEmailToasterFlag', 'true');
                            enableFollowEmailToasterFlag = 'true';

                            $('body').addClass('modal-open-custom');
                            $('.modal-header').find('button.close').click(function () {
                                $('body').removeClass('modal-open-custom');
                                quickStartHandler();
                            });
                        }
                    } else {
                        timeoutLapsed = true;
                    }
                }
            }, emailPopupTimer);
        }

        $('a.headerEmailSignupLink').on('click', function () {
            if (!($emailSignupPopupModal.hasClass('show'))) {
                _satellite.track('email-signup-header');
                $('.email-signup-popup .modal-dialog').removeClass('form-submitted');
                $('.email-signup-form-popup').trigger('reset');
                $('.signup-popup-btn').attr('disabled', 'disabled');
                $('.email-popup-submitted').addClass('d-none');
                $('.email-signup-body-content').removeClass('d-none');
                if ($('.main-menu').hasClass('in')) {
                    $('.main-menu .close-menu a').trigger('click');
                }
                $emailSignupPopupModal.removeClass('emailSignupToasterTriggered');
                $emailSignupPopupModal.modal({
                    keyboard: false,
                    focus: false,
                    backdrop: 'static'
                });
                $('body').addClass('modal-open-custom');
                if ($emailSignupToaster.hasClass('show')) {
                    $emailSignupToaster.removeClass('show');
                    $emailSignupToaster.find('.toaster-signup-cta').attr('aria-hidden', 'true');
                    $emailSignupToaster.find('.toaster-text').attr('aria-hidden', 'true');
                    $emailSignupToaster.find('.close-cta').attr('aria-hidden', 'true');
                }
            }
        });

        $(document).on('click', 'button.email-signup-modal-close', function () {
            if ($emailSignupPopupModal.hasClass('emailSignupToasterTriggered')) {
                _satellite.track('email-toaster-signup-modal-close');
            } else {
                _satellite.track('email-signup-header-close');
            }
            if ($('.modal-backdrop').length !== 0) {
                $('.modal-backdrop').removeClass('show-backdrop');
                $('.modal-backdrop').addClass('hide-backdrop');
            }
            $emailSignupPopupModal.removeClass('show');
            setTimeout(function () {
                $emailSignupPopupModal.modal('hide');
                $('body').removeClass('modal-open-custom');
                $emailSignupPopupModal.removeData();
            }, 500);
        });

        $(document).click(function (event) {
            if ($(event.target).hasClass('email-signup-popup')) {
                $emailSignupPopupModal.removeClass('show');
                if ($('.modal-backdrop').length !== 0) {
                    $('.modal-backdrop').removeClass('show-backdrop');
                    $('.modal-backdrop').addClass('hide-backdrop');
                }
                setTimeout(function () {
                    $emailSignupPopupModal.modal('hide');
                    $('body').removeClass('modal-open-custom');
                    $emailSignupPopupModal.removeData();
                }, 500);
            }
        });

        $emailSignupPopupModal.on('hidden.bs.modal', function () {
            $('body').removeClass('modal-with-backdrop');
            $('.email-signup-body').removeAttr('style');
            if ($emailSignupPopupModal.hasClass('emailSignupToasterTriggered') && $('.email-popup-submitted').hasClass('d-none') === true) {
                $.cookie('dontShowEmailPopup', 'true', { expires: doNotShowPopupBefore, path: '/' });
            }

            $emailSignupPopupModal.find('input').removeClass('is-invalid is-dirty');
            quickStartHandler();
        });

        $emailSignupPopupModal.on('shown.bs.modal', function () {
            if ($('.modal-backdrop').length !== 0) {
                $('body').addClass('modal-with-backdrop');
                $('.modal-backdrop').addClass('show-backdrop');
                $('.modal-backdrop').removeClass('hide-backdrop');
            }

            if ($emailSignupPopupModal.hasClass('emailSignupToasterTriggered')) {
                sessionStorage.setItem('enableFollowSignUpPopModalFlag', 'true');
                enableFollowSignUpPopModalFlag = 'true';
            }
        });
    });

    $(window).on('load', function () {
        if (($('#home-page-indicator, #cdp-page-indicator, #catLanding-indicator').length >= 1) && $('.quickStartWrapper').length > 0) {
            quickStartHandler();
            $('.quick-start-close-cta').on('click', function () {
                $('.quickStartWrapper').removeClass('show');
                _satellite.track('pickup-where-you-left-off-component-close');
                sessionStorage.setItem('quickStartActiveFlag', false);
                $.cookie('dontShowQuickStart', 'true', { expires: expShowQuickDate, path: '/' });
            });

            $('.quick-start-popup a').on('click', function () {
                _satellite.track('pick-up-where-you-left-off-recommendations');
            });
        }
    });
}

$('#countrySelectorModal').on('hidden.bs.modal', function () {
    if (timeoutLapsed === true) {
        if ($emailSignupToaster.hasClass('email-signup-toaster-enabled')) {
            $emailSignupToaster.addClass('show');
            $emailSignupToaster.find('.toaster-signup-cta').attr('aria-hidden', 'false');
            $emailSignupToaster.find('.toaster-text').attr('aria-hidden', 'false');
            $emailSignupToaster.find('.close-cta').attr('aria-hidden', 'false');
            _satellite.track('email-toaster-impression');
            $('#js-email-signup-toaster .copy').attr('tabindex', '0');
            $('#js-email-signup-toaster .toaster-signup-cta').attr('tabindex', '0');
            $('#js-email-signup-toaster .toaster-text').attr('tabindex', '0');
            $('#js-email-signup-toaster .close-cta').attr('tabindex', '0');
            $('#js-email-signup-toaster .close-cta, #js-email-signup-toaster .toaster-signup-cta').on('click', function () {
                $emailSignupToaster.removeClass('show');
                $emailSignupToaster.find('.toaster-signup-cta').attr('aria-hidden', 'true');
                $emailSignupToaster.find('.toaster-text').attr('aria-hidden', 'true');
                $emailSignupToaster.find('.close-cta').attr('aria-hidden', 'true');
                $emailSignupPopupModal.addClass('emailSignupToasterTriggered');
            });
            $('.toaster-signup-cta').on('click', function () {
                _satellite.track('email-toaster-sign-up');
                if (window.matchMedia('(max-width: 1023px)').matches) {
                    $emailSignupPopupModal.modal({
                        keyboard: false,
                        focus: false,
                        backdrop: 'static'
                    });
                } else {
                    $emailSignupPopupModal.modal({
                        keyboard: false,
                        focus: false,
                        backdrop: false
                    });
                }
            });
        } else {
            $emailSignupPopupModal.addClass('emailSignupToasterTriggered');
            if (window.matchMedia('(max-width: 1023px)').matches) {
                $emailSignupPopupModal.modal({
                    keyboard: false,
                    focus: false,
                    backdrop: 'static'
                });
            } else {
                $emailSignupPopupModal.modal({
                    keyboard: false,
                    focus: false,
                    backdrop: false
                });
            }
        }

        $('body').addClass('modal-open-custom');
        $('.modal-header').find('button.close').click(function () {
            $('body').removeClass('modal-open-custom');
            quickStartHandler();
        });
    }
});

module.exports = {
    init: init,
    showModal: showModal
};
