'use strict';

/**
 * Forces same height for all tile body
 * @param {Object} $element - jquery instance of the outfit element.
 * @param {number} paddingValue - Amount of padding to be added to the top and bottom of the tile-body.
 * @param {string} slickElement - name of carousel's child element to force same height on.
 */
function heightResize($element, paddingValue, slickElement) {
    var carouselSlides = $element
        .children('.slick-list')
        .children('.slick-track')
        .find('.slick-slide');

    var $slickElement = $(carouselSlides)
        .not('.slick-cloned')
        .find('.' + slickElement);

    var maxTileHeight = -1;
    $slickElement.each(function () {
        maxTileHeight = Math.max(maxTileHeight, $(this).height());
    });

    maxTileHeight += paddingValue;

    $(carouselSlides)
        .find('.' + slickElement)
        .css('height', maxTileHeight + 'px');
}

/**
 * Creates a carousel with the provided options
 * @param {JSON} opts - settings to be set for the carousel
 * @param {Object} element - name of the container to be rendered as a carousel
 */
function initCarousel(opts, element) {
    var options = {
        accessibility: true,
        infinite: true,
        dots: false,
        arrows: true,
        swipe: true,
        swipeToSlide: true,
        mobileFirst: true,
        autoplay: false
    };

    options = $.extend({}, options, opts, {});
    element.not('.slick-initialized').slick(options);

    if ($(element).hasClass('site-stripe-carousel')) {
        var currSlide;
        var totalSlides;
        element.on('afterChange', function (event, slick, currentSlide) {
            currSlide = currentSlide;
            totalSlides = slick.$slides.length - 1;
        });

        $(element).find('button.slick-arrow').on('click', function (e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (currSlide === totalSlides) {
                element.slick('slickGoTo', 0);
            } else if (currSlide === 0) {
                element.slick('slickGoTo', totalSlides);
            }
        });
    }
}

/**
 * Resets carousel
 * @param {Object} element - jquery instance of the element.
 */
function removeCarousel(element) {
    if (element.hasClass('slick-initialized')) {
        element.slick('unslick');
    }
}

module.exports = {
    initCarousel: initCarousel,
    removeCarousel: removeCarousel,
    heightResize: heightResize
};
