/* global _satellite */
'use strict';
require('../../icons/infoHelp.svg');

/**
 * New Size Guide Changes
 */
function sizeGuideModal() {
    var TFInstanceInfo = $('#TFinstance').val();
    $('#mens-socks-size-guide #myselection option[value=AU]').remove();
    $('#mens-socks-size-guide #myselection option[value=NZ]').remove();
    $('#womens-socks-size-guide #myselection option[value=AU]').remove();
    $('#womens-socks-size-guide #myselection option[value=NZ]').remove();
    $('.intl-dropdown').selectpicker('refresh');
    $('.dropdown-menu .selected.active').addClass('hide');
    $('.intl-dropdown .dropdown-menu').append('<div class="gradient-shadow"></div>');
    var dataStyleId = $('.trufit-container').find('.tfc-fitrec-product').data('styleid');
    var dataLocaleId = $('.trufit-container').find('.tfc-fitrec-product').data('locale');
    if ($('#sizeChartModal').hasClass('show')) {
        $('#sizeChartModal').find('.tfc-fitrec-product').attr('data-styleid', dataStyleId);
        $('#sizeChartModal').find('.tfc-fitrec-product').attr('data-locale', dataLocaleId);
    }
    $('[data-toggle="tooltip"]').tooltip();
    var el = $('.intl-dropdown .dropdown-menu').children('.inner.show');
    if (el) {
        el.on('scroll', function () {
            if ($(el)[0].clientHeight + $(el).scrollTop() >= $(el)[0].scrollHeight) {
                $('.gradient-shadow').hide();
            } else {
                $('.gradient-shadow').show();
            }
        });
    }
    if (typeof tfcapi !== 'undefined') {
        /* eslint-disable */
        tfcapi('calculate');
        (function () {
            var a = {};
            function g(l) {
                a[l] = function (r, e, o) {
                    var w = window,
                        d = document,
                        p = [],
                        t,
                        s,
                        x;
                    w.tfcapi = t = w.tfcapi || function () {
                        t.q = t.q || [];
                        t.q.push(arguments);
                    };
                    o && !!o.deviceType && p.push('deviceType=' + o.deviceType);
                    o && o.autoCalculate === false && p.push('autoCalculate=false');
                    x = d.getElementsByTagName('script')[0];
                    s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://' + r + '-cdn' + (e === 'dev' || e === 'staging' ? '.' + e : '') + '.truefitcorp.com/fitrec/' + r + '/js/' + l + '.js?' + p.join('&');
                    x.parentNode.insertBefore(s, x);
                };
            }

            g('fitrec');
            g('tracker');

            return a;

        // Don't change anything above this line
        })().fitrec('lul', TFInstanceInfo, { autoCalculate: true });
        /* eslint-enable */
    }
}

/**
 * Function for Size Guide left right arrow
 */
function carouselArrows() {
    var itemSize = 120;
    var updateArrows;
    // Function to update left right arrow
    if ($('.fit-category-tab.active:not(:hidden)').find('.scroll-tab-container').length) {
        var tabList = $('.fit-category-tab.active:not(:hidden)').find('.scroll-tabs');
        var leftArrow = $('.fit-category-tab.active:not(:hidden)').find('.left-arrow');
        var rightArrow = $('.fit-category-tab.active:not(:hidden)').find('.right-arrow');
        var scrollDistance = itemSize; // Adjust the scroll distance as needed
        leftArrow.on('click', function () {
            tabList.stop(true).animate({
                scrollLeft: '-=' + scrollDistance
            }, 'slow');
        });
        rightArrow.on('click', function () {
            tabList.stop(true).animate({
                scrollLeft: '+=' + scrollDistance
            }, 'slow');
        });

        /**
         * Disable - enable arrows based on scroll position
        */
        updateArrows = function () {
            var scrollLeft = tabList.scrollLeft();
            var tabWidth = tabList.outerWidth();
            var widthRoundOff = Math.ceil(tabWidth);
            var scrollLeftRoundOff = Math.ceil(scrollLeft);
            var scrollWidth = tabList[0].scrollWidth - widthRoundOff;
            leftArrow.toggleClass('disabled', scrollLeft === 0);
            rightArrow.toggleClass('disabled', scrollLeftRoundOff >= scrollWidth);
        };

        tabList.on('scroll', function () {
            updateArrows();
        });

        updateArrows();
    }
}

/**
 * Function to update Size tabs
 */
function sizeTabsControl() {
    var listItems = $('.product-detail-content .size-btns:first-child input');
    var labelValue = $(listItems).attr('data-attr-value');
    var alphabetRegex = new RegExp('[A-Za-z]');
    var numberRegex = new RegExp('[0-9]');
    if (alphabetRegex.test(labelValue) && listItems.length) {
        if (labelValue.indexOf('/') > -1) {
            if ($('.fit-tab-div.active.show .dual-alpha-tab').length && $('.fit-tab-div.active.show .dual-alpha-tab:first').hasClass('active') === false) {
                $('.fit-tab-div.active.show .fit-tab-div:first').removeClass('active show');
                $('.fit-tab-div.active.show .dual-alpha-tab:first').trigger('click');
            }
        } else if ($('.fit-tab-div.active.show .alpha-tab').length && $('.fit-tab-div.active.show .alpha-tab:first').hasClass('active') === false) {
            $('.fit-tab-div.active.show .fit-tab-div:first').removeClass('active show');
            $('.fit-tab-div.active.show .alpha-tab:first').trigger('click');
        }
    } else if (numberRegex.test(labelValue)) {
        if ($('.fit-tab-div.active.show .numeric-tab').length && $('.fit-tab-div.active.show .numeric-tab:first').hasClass('active') === false) {
            $('.fit-tab-div.active.show .fit-tab-div:first').removeClass('active show');
            $('.fit-tab-div.active.show .numeric-tab:first').trigger('click');
        }
    }
}

/**
 * Function to update Size tabs when product is high support
 */
function bandCupSizeTab() {
    var highSupportProduct = $("input[name$='enablesizedropdownUI']").val();
    if ($('.fit-category-tab.active.show .size-tab-fit[data-sizelabel="band/cup sizes"]').length && highSupportProduct === 'true') {
        $('.fit-category-tab.active.show .size-tab-fit').removeClass('active');
        $('.fit-category-tab.active.show .fit-tab-div').removeClass('active show');
        $('.fit-category-tab.active.show .size-tab-fit[data-sizelabel="band/cup sizes"]').trigger('click');
    }
}

$(window).on('resize', function () {
    if ($('.fit-category-tab.active:not(:hidden)').find('.scroll-tab-container').length) {
        carouselArrows();
    }

    $('.bootstrap-select').selectpicker();
    $('.bootstrap-select').attr({ role: 'combobox', 'aria-autocomplete': 'list', 'aria-haspopup': 'listbox', 'aria-controls': 'select-options', 'aria-activedescendant': '' });

    $('.bootstrap-select').on('shown.bs.select', function () {
        $(this).attr('aria-expanded', 'true');
    });

    $('.bootstrap-select').on('hidden.bs.select', function () {
        $(this).attr('aria-expanded', 'false');
    });

    $('.bootstrap-select').on('changed.bs.select', function (e, clickedIndex) {
        var selectedOption = $(this).find('option').eq(clickedIndex).text();
        $(this).attr('aria-activedescendant', selectedOption);
    });
});

$(document).ready(function () {
    if ($('.size-guide-footer').length) {
        sizeGuideModal();
    }
    if ($('.size-guide-footer').length && $('.fit-category-tab.active:not(:hidden)').find('.scroll-tab-container').length) {
        carouselArrows();
    }
    $('button.dropdown-toggle').click(function () {
        $('.dropdown-menu.inner.show li.selected.active').addClass('hide');
        $('.dropdown-menu.show .inner.show').scrollTop(0);
        if ($('.dropdown-toggle').attr('aria-expanded') === 'true') {
            var dropdown = $('.dropdown-menu').children('.inner').children('.dropdown-menu');
            if (dropdown[0].scrollHeight === dropdown[0].clientHeight) {
                $('.gradient-shadow').hide();
            }
        }
    });
    $(document).on('click', '.size-chart .size-chart-link', function (e) {
        e.stopPropagation();
        e.preventDefault();

        var $sizeChart;
        var targetEl;
        var url = $(this).attr('href');
        var checkEl;

        if ($('#sizeChartModal').length > 0) {
            $sizeChart = $('#sizeChartModal');
            targetEl = '.modal-body';
            checkEl = '.sizinginformation';
        } else {
            $sizeChart = $('#genericSizeChartModal');
            targetEl = '.tab-content';
            checkEl = '.sizechart';
        }

        if ($sizeChart.length > 0 && $sizeChart.find(checkEl).length > 0) {
            $sizeChart.modal('show');
        } else {
            $.ajax({
                url: url,
                type: 'get',
                success: function (data) {
                    if (data.content != null) {
                        $sizeChart.find(targetEl).html(data.content);
                        $sizeChart.modal('show');
                    } else {
                        $sizeChart.find(targetEl).html(data);
                        $sizeChart.modal('show');
                        if ($('.size-guide-footer').length === 0) {
                            sizeTabsControl();
                            bandCupSizeTab();
                        }
                        sizeGuideModal();
                        carouselArrows();
                    }
                }
            });
        }
    });

    $('#mensocksasia-fit #myselection option[value=AU]').remove();
    $('#mensocksasia-fit #myselection option[value=NZ]').remove();
    $('#womensocks #myselection option[value=AU]').remove();
    $('#womensocks #myselection option[value=NZ]').remove();

    $('#women-socks').on('click', function () {
        if ($('.AU').length || $('.NZ').length) {
            var womenSocksGlobalFit = $('#womensocksglobal-fit');
            var mySelection = womenSocksGlobalFit.find('#myselection');
            var targetOption = mySelection.find('option[value=UK]');
            targetOption.prop('selected', true);
            mySelection.change();
            womenSocksGlobalFit.find('li:has(a:contains(New Zealand)), li:has(a:contains(Australia))').addClass('hide');
            $('#womensocksglobal-fit .filter-option').on('click', function () {
                womenSocksGlobalFit.find('li:has(a:contains(New Zealand)), li:has(a:contains(Australia))').addClass('hide');
            });
        }
        $('#womensocksglobal-fit').find('li:has(a:contains(New Zealand), a:contains(Australia))').addClass('hide');
        $('#womensocksglobal-fit').find('.filter-option').on('click', function () {
            $('#womensocksglobal-fit').find('li:has(a:contains(New Zealand)), li:has(a:contains(Australia))').addClass('hide');
        });
    });

    $('#men-socks').on('click', function () {
        if ($('.AU').length || $('.NZ').length) {
            var menSocksAsiaFit = $('#mensocksasia-fit');
            var mySelection = menSocksAsiaFit.find('#myselection');
            var targetOption = mySelection.find('option[value=UK]');
            targetOption.prop('selected', true);
            mySelection.change();
            $('#mensocksasia-fit').find('li:has(a:contains(New Zealand), a:contains(Australia))').addClass('hide');
            $('#mensocksasia-fit').find('.filter-option').on('click', function () {
                $('#mensocksasia-fit').find('li:has(a:contains(New Zealand)), li:has(a:contains(Australia))').addClass('hide');
            });
        }
        $('#mensocksasia-fit').find('li:has(a:contains(New Zealand), a:contains(Australia))').addClass('hide');
        $('#mensocksasia-fit').find('.filter-option').on('click', function () {
            $('#mensocksasia-fit').find('li:has(a:contains(New Zealand)), li:has(a:contains(Australia))').addClass('hide');
        });
    });
});

/**
 * Function for Sizeguide Redesign Modal Open
 */
function sizeGuideModalOpen() {
    $(document).on('change', '#myselection', function () {
        var parentId = $(this).closest('.fit-tab-div.active').attr('id');
        var demovalue = $(this).val();
        $('#' + parentId + ' option:not(:selected)').each(function () {
            var optionValue = $(this).val();
            $('#' + parentId + ' .show' + optionValue).addClass('d-none');
        });
        $('#' + parentId + ' .show' + demovalue).removeClass('d-none');
        if ($('#' + parentId).find('.sizeguide-tab').length) {
            var newObjectForSubHeading = {};
            $('#' + parentId + ' .sizeguide-tab').find('.scroll-tab .size-tab-fit').each(function (idx) {
                var sizeTabThis = $(this);
                var sizeTabListItems = $(sizeTabThis).attr('data-tab-names');
                var parsedJson = JSON.parse(sizeTabListItems.replace(/'/g, '"'));
                var sizeTabValue = parsedJson[demovalue];
                var changedText = parsedJson.ussizetext;
                var defaultText = parsedJson.defaultussize;
                if (sizeTabValue !== 'null') {
                    $(sizeTabThis).html(changedText + ' ' + sizeTabValue);
                    newObjectForSubHeading[idx] = changedText + ' ' + sizeTabValue;
                    $(sizeTabThis).attr('data-sizetab-id', parsedJson.sizetabid + ' ' + sizeTabValue.toLowerCase());
                } else {
                    $(sizeTabThis).html(defaultText);
                    newObjectForSubHeading[idx] = '';
                    $(sizeTabThis).attr('data-sizetab-id', $(sizeTabThis).attr('data-sizelabel'));
                }
            });
            $('#' + parentId + ' .fit-tab-div').find('.fit-subheading').each(function (idx) {
                var defaultHeading = $(this).attr('data-sizetab-heading');
                var objectValue = newObjectForSubHeading[idx];
                if (objectValue !== '') {
                    $(this).html(objectValue);
                } else {
                    $(this).html(defaultHeading);
                }
            });
        }
        $('#' + parentId).find('.fit-tab-div').each(function () {
            var sizeTableThis = $(this);
            if ($(sizeTableThis).find(' .show' + demovalue).length) {
                var newObjectForColumnHide = {};
                $(sizeTableThis).find(' .show' + demovalue + ' td').each(function (indx) {
                    var sizeTableColumnThis = $(this);
                    var columnTrimmedValue = $.trim($(sizeTableColumnThis).text());
                    if (columnTrimmedValue === '-') {
                        $(sizeTableColumnThis).addClass('d-none');
                        newObjectForColumnHide[indx] = indx;
                    } else {
                        $(sizeTableColumnThis).removeClass('d-none');
                        newObjectForColumnHide[indx] = '';
                    }
                });
                $(sizeTableThis).find('tr.table-row').each(function () {
                    var tableRowThis = $(this);
                    if (!$(tableRowThis).hasClass(' .show' + demovalue)) {
                        $(tableRowThis).find('td').each(function (index) {
                            if (newObjectForColumnHide[index] === index) {
                                $(this).addClass('d-none');
                            } else if (newObjectForColumnHide[index] === '') {
                                $(this).removeClass('d-none');
                            }
                        });
                    }
                });
            }
        });
        $('.fit-tab-div.active .intl-dropdown').selectpicker('refresh');
    });
    $('.intl-dropdown').on('shown.bs.dropdown', function () {
        $('.dropdown-menu.show .inner.show').scrollTop(0);
    });
    $(document).on('shown.bs.tab', '.sizeguide-tab a[data-toggle="tab"]', function () {
        $('.size-guide-redesign tr').removeClass('odd');
        $('.size-guide-redesign tr').removeClass('even');
        $('.size-guide-redesign tr.table-row:not(:hidden)').filter(':odd').addClass('even');
        $('.size-guide-redesign tr.table-row:not(:hidden)').filter(':even').addClass('odd');
        $('.intl-dropdown').selectpicker('refresh');
    });
    $(document).on('shown.bs.tab', '.categoty-fit-tab', function () {
        if ($('.size-guide-footer').length === 0) {
            sizeTabsControl();
        }
    });
    $(document).on('shown.bs.tab', '.categoty-fit-tab, .gender-tab a[data-toggle="tab"]', function () {
        if ($('.fit-category-tab.active:not(:hidden)').find('.scroll-tab-container').length) {
            carouselArrows();
        }
    });
    $(document).on('change', '.intl-dropdown', function () {
        $('.size-error-message').find('.error-message').hide();
        $('.size-guide-redesign tr').removeClass('odd');
        $('.size-guide-redesign tr').removeClass('even');
        $('.size-guide-redesign tr.table-row:not(:hidden)').filter(':odd').addClass('even');
        $('.size-guide-redesign tr.table-row:not(:hidden)').filter(':even').addClass('odd');
        $('.intl-dropdown').selectpicker('refresh');
    });
    $(document).on('change', '.measurement-switch input', function (e) {
        $('.size-error-message').find('.error-message').hide();
        var parentDiv = $(this).closest('.fit-tab-div.active').attr('id');
        var isOn = e.currentTarget.checked;
        if (isOn) {
            $('#' + parentDiv + '.fit-tab-div.active .centimeter').removeClass('show');
            $('#' + parentDiv + '.fit-tab-div.active .inches').addClass('show');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-centimeters').removeClass('active');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-inches').addClass('active');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-switch').find('.visually-hidden').html('IN');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-div span.switch-measurement').attr('aria-label', 'Inches ON');
        } else {
            $('#' + parentDiv + '.fit-tab-div.active .inches').removeClass('show');
            $('#' + parentDiv + '.fit-tab-div.active .centimeter').addClass('show');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-centimeters').addClass('active');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-inches').removeClass('active');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-switch').find('.visually-hidden').html('CM');
            $('#' + parentDiv + '.fit-tab-div.active .measurement-div span.switch-measurement').attr('aria-label', 'Centimeter ON');
        }
        $('.size-guide-redesign tr').removeClass('odd');
        $('.size-guide-redesign tr').removeClass('even');
        $('.size-guide-redesign tr.table-row:not(:hidden)').filter(':odd').addClass('even');
        $('.size-guide-redesign tr.table-row:not(:hidden)').filter(':even').addClass('odd');
    });

    $(document).on('keyup', '.footer-checkbox-toggle .toggle-checkbox', function (e) {
        var currentElementID = $(this).attr('id');
        if (document.activeElement === $('#' + currentElementID + '.toggle-checkbox')[0] && e.keyCode === 9) {
            $('#' + currentElementID + '.toggle-checkbox').addClass('tabbed');
        }
    });

    $(document).on('focusout', '.footer-checkbox-toggle .toggle-checkbox', function () {
        if (document.activeElement !== this) {
            $('.footer-checkbox-toggle .toggle-checkbox').removeClass('tabbed');
        }
    });
}

module.exports = {
    sizeGuideModalOpen: sizeGuideModalOpen
};
