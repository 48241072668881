'use strict';

require('../../icons/leftArrow.svg');
require('../../icons/rightArrow.svg');
var carousel = require('./carousel');
var productTile = require('./productTile');

var enableNewPdp = $('#pdp-redesign').val();
var slidesToShowCarousel = 1;
var centerModeCarousel = true;
var arrowsCarousel = true;
var scrollSlidesDesktop = 1;
var scrollSlidesMobile = 1;
var swipeToSlideFlag = true;

if (enableNewPdp === 'true') {
    slidesToShowCarousel = 2;
    centerModeCarousel = false;
    arrowsCarousel = false;
    scrollSlidesDesktop = 4;
    scrollSlidesMobile = 2;
    swipeToSlideFlag = false;
}

if ($('#home-page-indicator, #cdp-page-indicator, #catLanding-indicator, #pd-product-carousel').length >= 1) {
    slidesToShowCarousel = 2;
    centerModeCarousel = false;
    arrowsCarousel = false;
    scrollSlidesDesktop = 4;
    scrollSlidesMobile = 2;
    swipeToSlideFlag = false;
}

/**
 * Creates a product recommendations carousel
 */
function initProductCarousel() {
    var $productCarousel = $('.recommendations .product-carousel');
    if ($('.ai-recommendations .product-carousel').length) {
        $('.ai-recommendations .product-carousel').each(function () {
            $productCarousel.push(this);
        });
    }
    var opts = {
        focusOnChange: true,
        accessibility: true,
        slidesToShow: slidesToShowCarousel,
        slidesToScroll: scrollSlidesMobile,
        dots: true,
        customPaging: function (slider, i) {
            var ariaLable = 'slide ' + (i + 1) + ' of ' + (slider.getDotCount() + 1);
            var ariaCurrent = (i === 0) ? 'true' : 'false';
            return '<a href="javascript:void(0)" role="tab" class="product-carousel-dots" id="' + i + '"aria-label="' + ariaLable + '" aria-current="' + ariaCurrent + '" tabindex="0" ></a>';
        },
        centerMode: centerModeCarousel,
        centerPadding: '10%',
        arrows: arrowsCarousel,
        slide: 'div.product-carousel-slide',
        rows: 0,
        autoplay: false,
        swipeToSlide: swipeToSlideFlag,
        responsive: [
            {
                breakpoint: 1023.98,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: scrollSlidesDesktop,
                    arrows: true,
                    centerMode: false
                }
            }
        ]
    };
    $('.recommendations .product-carousel .slick-dots li').attr('role', 'presentation');
    // Removing the product slides with null product id's.
    var $productSlides = $('.product-carousel-slide').find('.product');
    for (var i = 0; i < $productSlides.length; i++) {
        if ($($productSlides[i]).data('pid') === null) {
            $($productSlides[i]).parent().remove();
        }
    }

    if ($($productCarousel.closest('.recommendations-cdp')).length) {
        opts.infinite = false;
    }

    $productCarousel.each(function () {
        let node = $(this);
        setTimeout(function () {
            carousel.initCarousel(opts, node);
            carousel.heightResize(node, 40, 'tile-body');
        }, 10);
    });

    //  add aria label for color swatches next prev arrows
    var colorSwatchPrevButton = document.querySelectorAll('.color-swatches .slick-prev');
    var colorSwatchNextButton = document.querySelectorAll('.color-swatches .slick-next');
    if (colorSwatchPrevButton.length > 0) {
        colorSwatchPrevButton.forEach((element) => element.setAttribute('aria-label', $(element).parent().data('swatch-button-label')));
    }
    if (colorSwatchNextButton.length > 0) {
        colorSwatchNextButton.forEach((element) => element.setAttribute('aria-label', $(element).parent().data('swatch-button-label')));
    }
}

/**
 * Initializes recommendation carousels when the product tiles are added using recommenders
 */
function reinitializeCarousel() {
    var recommendationWrappers = Array.from(document.querySelectorAll(
        '[id^=cq_recomm_slot]'
    )).filter(ele => !ele.closest('.recommendations-cdp')); // Excluding CDP recommenders
    var config = {
        attributes: false,
        childList: true,
        subtree: false
    };
    var observer = [];
    for (var index = 0; index < recommendationWrappers.length; index++) {
        var elem = recommendationWrappers[index];
        observer.push(
            new MutationObserver(function () {
                initProductCarousel();
                // Re-intializing color swatches after recommended products are added
                productTile.initSwatch();
            })
        );
        observer[index].observe(elem, config);
    }
}

/**
 * Adding aria current based on slide selection
 */
function productAttributes() {
    $('li a.product-carousel-dots').attr('aria-current', 'false');
    if ($('li.slick-active')) {
        $('li.slick-active a.product-carousel-dots').attr('aria-current', 'true');
    } else {
        $('li a.product-carousel-dots').attr('aria-current', 'false');
    }
}

/**
 * Tab Keyboard stuff
 * @param {Object} keyVent - key reference
*/
function keyboardHandlerTab(keyVent) {
    var target = keyVent.target;
    var which = keyVent.which;

    if (which === 32 || which === 13) { // ENTER OR SPACE
        $(target).trigger('click');
    }
}

/**
 * Function for Recommendation left right arrow
 * @param {Object} $parentContainer - parent div reference
 */
function carouselRecommendationArrows($parentContainer) {
    var itemSize = 120;
    var updateArrows;
    var tabList = $parentContainer.find('.multitablink');
    var leftArrow = $parentContainer.find('.left-arrow');
    var rightArrow = $parentContainer.find('.right-arrow');
    var scrollDistance = itemSize; // Adjust the scroll distance as needed
    leftArrow.on('click', function () {
        tabList.stop(true).animate({
            scrollLeft: '-=' + scrollDistance
        }, 'slow');
    });
    rightArrow.on('click', function () {
        tabList.stop(true).animate({
            scrollLeft: '+=' + scrollDistance
        }, 'slow');
    });

    /**
     * Disable - enable arrows based on scroll position
    */
    updateArrows = function () {
        var scrollLeft = tabList.scrollLeft();
        var tabWidth = tabList.outerWidth();
        var widthRoundOff = Math.ceil(tabWidth);
        var scrollLeftRoundOff = Math.ceil(scrollLeft);
        var scrollWidth = tabList[0].scrollWidth - widthRoundOff;
        leftArrow.toggleClass('disabled', scrollLeft === 0);
        rightArrow.toggleClass('disabled', scrollLeftRoundOff >= scrollWidth);
    };

    tabList.on('scroll', function () {
        updateArrows();
    });

    updateArrows();

    $(tabList).on('keydown', 'h2.btn', keyboardHandlerTab);
}

/**
 * recommendation carousel lazyload script
 */
$(window).on('load', function () {
    var displayAllSlides = false;
    $('.recommendations .product-carousel').on('afterChange', function () {
        var lazyloadAllSlides = $('.recommendations .product-carousel-slide').not('.slick-cloned').find('.image-container').find('img')
        .hasClass('lazyload');
        if (!displayAllSlides) {
            $('.recommendations .product-carousel-slide.slick-current').find('.image-container').find('img').addClass('lazyloaded');
            $('.recommendations .product-carousel-slide').find('.image-container').find('img.lazyloaded').attr('data-lazyload', 'true');
            if (!lazyloadAllSlides) {
                $('.recommendations .product-carousel-slide').find('.image-container').find('img').attr('data-lazyload', 'true');
                displayAllSlides = true;
            }
        }
    });
    productAttributes();
});

$(document).ready(function () {
    setTimeout(function () {
        $('ul.slick-dots li.slick-active a.product-carousel-dots').attr('aria-current', 'true');
        if ($('li a.product-carousel-dots').length >= 1) {
            productAttributes();
        }
    }, 3000);

    if ($('.multi-tab-content').length > 0) {
        $('#tabLink0').removeClass('d-none');
        $("h2[data-toggle='tab']").on('click', function () {
            var tab = '#' + $(this).attr('data-tab');
            var tabLink = '#' + $(this).attr('data-tab-link');
            $(this).parents('.recommendations').find('.multi-tab-content').find('.tab-pane')
            .removeClass('active');
            $(this).parents('.recommendations').find('.multi-tab-content').find(tab)
            .addClass('active');
            setTimeout(function () {
                initProductCarousel();
            }, 500);
            $('[id^="tabLink"]').addClass('d-none');
            $(tabLink).removeClass('d-none');
        });
    }

    if ($('.recommendations.multi-tab').find('.multitablink').length > 0) {
        $('.recommendations.multi-tab').find("h2[data-toggle='tab']").eq(0).trigger('click');
        carouselRecommendationArrows($('.recommendations.multi-tab'));
    }

    if ($('.recommendations.product-enhanced-grid-multi').find('.multitablink').length > 0) {
        $('.recommendations.product-enhanced-grid-multi').find("h2[data-toggle='tab']").eq(0).trigger('click');
        carouselRecommendationArrows($('.recommendations.product-enhanced-grid-multi'));
    }
});

$(window).on('resize', function () {
    if ($('.recommendations.multi-tab').find('.multitablink').length > 0) {
        carouselRecommendationArrows($('.recommendations.multi-tab'));
    }

    if ($('.recommendations.product-enhanced-grid-multi').find('.multitablink').length > 0) {
        carouselRecommendationArrows($('.recommendations.product-enhanced-grid-multi'));
    }
});

$(document).on('click', 'ul.slick-dots li', function () {
    productAttributes();
});

module.exports = {
    initProductCarousel: initProductCarousel,
    reinitializeCarousel: reinitializeCarousel
};
