'use strict';

var carousel = require('./carousel');
var mediaQuery = window.matchMedia('(max-width: 1023.98px)');

/**
 * Initialize carousel for mobile view
 */
function initProductGridCarousel() {
    var $productGrid = $('.product-grid-carousel');
    if (mediaQuery.matches) {
        var opts = {
            centerMode: true,
            dots: true,
            enterMode: true,
            centerPadding: '8%',
            slidesToShow: 1,
            customPaging: function (slider, i) {
                return (
                    '<div class="product-carousel-dots" id=' + i + '></div>'
                );
            }
        };
        $productGrid.each(function () {
            let node = $(this);
            setTimeout(function () {
                carousel.initCarousel(opts, node);
                carousel.heightResize(node, 40, 'tile-body');
            }, 10);
        });
    } else {
        carousel.removeCarousel($productGrid);
    }
}

/**
 * Initialize listeners for resize
 */
function init() {
    mediaQuery.addListener(initProductGridCarousel);
    initProductGridCarousel();
}

module.exports = { init: init };
