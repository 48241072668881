'use strict';

var scene7 = require('./scene7');
/**
 * Attach video to element
 */
function initVideo() {
    $('.js-video').each(function () {
        var id = $(this).attr('id');
        var url = $(this).data('video-url');
        var native = $(this).data('native-player');
        if (id && url) {
            if (!native) {
                scene7.getVideo(id, $(this).data('autoplay'), url);
            }
        }
    });
}

// Native video player controls and event listeners

/**
 * Handler for long form videos with all controls
 * @param {Object} player - video player element
 */
function longVideoHandler(player) {
    var duration;
    setTimeout(function () {
        if (!isNaN(player.duration)) {
            if (player.duration < 3600) {
                duration = new Date(player.duration * 1000).toISOString().substring(14, 19);
            } else {
                duration = new Date(player.duration * 1000).toISOString().substring(11, 16);
            }
        }

        $(player).closest('.long-form-video').find('.overlay-timer').html(duration);
        $(player).closest('.long-form-video').find('.overlay-timer').attr('aria-label', duration);
    }, 300);

    if (player.autoplay) {
        $(player).siblings('.overlay-section').hide();
        $(player)[0].controls = true;
    }
}

/**
 * Handler for short form videos with all controls
 * @param {Object} player - video player element
 */
function shortVideoHandler(player) {
    if ((typeof player.webkitAudioDecodedByteCount !== 'undefined' && player.webkitAudioDecodedByteCount > 0) || (typeof player.mozHasAudio !== 'undefined' && player.mozHasAudio) || (typeof player.audioTracks !== 'undefined' && player.audioTracks.length > 0)) {
        $(player).siblings('.custom-video-controls').find('.overlay-mute').removeClass('hidden');
    } else {
        $(player).siblings('.custom-video-controls').find('.no-audio-msg').show();
    }

    if (player.autoplay) {
        $(player).siblings('.custom-video-controls').find('.play-icon').hide();
        $(player).siblings('.custom-video-controls').find('.pause-icon').show();
        $(player).siblings('.custom-video-controls').find('.mute-icon').addClass('muted');
        $(player).siblings('.custom-video-controls').find('.volume-slider').hide();
    }
}

// Handling video players on load
$(window).on('load', function () {
    $('.native-video').each(function () {
        this.addEventListener('ended', function () {
            if (!this.loop) {
                if ($(this).closest('.long-form-video').length) {
                    $(this).closest('.long-form-video').find('.overlay-section').show();
                    $(this).closest('.long-form-video').find('.play-icon').show();
                    $(this).attr('controls', false);
                } else {
                    $(this).siblings('.custom-video-controls').find('.play-icon').show();
                    $(this).siblings('.custom-video-controls').find('.pause-icon').hide();
                }
            }
        });

        if ($(this).closest('.long-form-video').length) {
            longVideoHandler(this);
        } else {
            shortVideoHandler(this);
        }
    });
});

// Overlay click listener for long video player
$('.overlay-section').on('click', function () {
    $(this).hide();
    $(this).siblings('video')[0].play();
    $(this).siblings('video').attr('controls', true);
});

// Overlay click listener for long video player
$(document).on('click', '.short-form-video video', function () {
    if (this.paused) {
        this.play();
        $(this).closest('.short-form-video').find('.play-icon').hide();
        $(this).closest('.short-form-video').find('.pause-icon').show();
        $(this).closest('.short-form-video').find('.overlay-play').attr('aria-label', 'pause');
    } else {
        this.pause();
        $(this).closest('.short-form-video').find('.play-icon').show();
        $(this).closest('.short-form-video').find('.pause-icon').hide();
        $(this).closest('.short-form-video').find('.overlay-play').attr('aria-label', 'play');
    }
});

// Click listener for custom play button on both short and long video players
$('.overlay-play').on('click', function () {
    var $player;
    if ($(this).closest('.short-form-video').length) {
        $player = $(this).closest('.short-form-video').find('video')[0];
    } else {
        $player = $(this).closest('.long-form-video').find('video')[0];
    }
    if ($player.paused) {
        $player.play();
        $(this).children('.play-icon').hide();
        $(this).children('.pause-icon').show();
        $(this).attr('aria-label', 'pause');
    } else {
        $player.pause();
        $(this).children('.play-icon').show();
        $(this).children('.pause-icon').hide();
        $(this).attr('aria-label', 'play');
    }
});

$('.short-form-video .overlay-play').on('click', function (e) {
    e.stopPropagation();
});

// Click listener for custom mute icon on short video player
$('.overlay-mute').on('click', function (e) {
    e.stopPropagation();
    var $player = $(this).closest('.short-form-video').find('video')[0];
    if ($player.muted) {
        $player.muted = false;
        $(this).children('.mute-icon').removeClass('muted');
        $(this).siblings('.volume-slider').show();
        $(this).attr('aria-label', 'mute');
    } else {
        $player.muted = true;
        $(this).children('.mute-icon').addClass('muted');
        $(this).siblings('.volume-slider').hide();
        $(this).attr('aria-label', 'unmute');
    }
});

// Change listener for custom volume range slider on short video player
$('.volume-slider').on('change', function (e) {
    var $player = $(this).closest('.short-form-video').find('video')[0];
    $player.volume = e.target.value;
});

$('.volume-slider').on('click', function (e) {
    e.stopImmediatePropagation();
});

// Displaying no audio message on keyboard focus
$(document).on('keyup', '.no-audio-msg', function (e) {
    if (e.keyCode === 9) {
        $(this).css('opacity', '1');
    }
});

// Hiding no audio message when keyboard focus is lost
$(document).on('keydown', '.no-audio-msg', function (e) {
    if (e.keyCode === 9) {
        $(this).css('opacity', '0');
    }
});

export { initVideo };
