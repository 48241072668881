/* global _satellite */
'use strict';

require('../../icons/plus.svg');
require('../../icons/minus.svg');
require('../../icons/facebook.svg');
require('../../icons/twitter.svg');
require('../../icons/instagram.svg');
require('../../icons/youtube.svg');
require('../../icons/pinterest.svg');
require('../../icons/error.svg');
require('../../icons/plus24.svg');
require('../../icons/minus24.svg');
require('../../icons/minus16.svg');
require('../../icons/lululogoicon.svg');
require('../../icons/lululogoiconred.svg');
require('../../icons/errorNotification.svg');
require('../../icons/infoNotification.svg');
require('../../icons/successNotification.svg');
require('../../icons/warningNotification.svg');
require('../../icons/kakaotalk.svg');
require('../../icons/textprimaryicon.svg');

/**
 * Toggle accordion expand/collapse
 */
function toggleAccordion() {
    $('.footer-item .title-js').click(function () {
        if (window.matchMedia('(max-width: 1023.98px)').matches) {
            $(this)
                .find('.icon')
                .toggleClass('d-none');

            if (typeof $(this).attr('aria-expanded') === 'undefined') {
                $(this).attr('aria-expanded', 'true');
            } else {
                $(this).attr(
                    'aria-expanded',
                    $(this).attr('aria-expanded') === 'false' ? 'true' : 'false'
                );
            }
        } else if (this.href && this.href !== '#') {
            window.location.href = this.href;
        }
    });
}

$(document).ready(function () {
    $('#select-all-agreements-signup').click(function () {
        if ($('#select-all-agreements-signup')[0].checked === true) {
            $('.required-consent-signup').prop('checked', true);
            $('.required-consent-signup').removeClass('is-invalid');
        } else {
            $('.required-consent-signup').prop('checked', false);
        }
    });

    $('.required-consent-signup').on('click', function () {
        if ($('.required-consent-signup:checked').length === $('.required-consent-signup').length) {
            $('#select-all-agreements-signup').prop('checked', true);
        } else {
            $('#select-all-agreements-signup').prop('checked', false);
        }
    });

    var satelliteName;
    if ($('.size-guide-footer').length) {
        satelliteName = 'footer-size-guide';
    } else if ($('.cdp-size-guide').length) {
        satelliteName = 'cdp-size-guide-modal';
    } else {
        satelliteName = 'size-guide-modal';
    }

    var newSizeGuideEnable = $('#ffNewSizeGuide').val();
    if (newSizeGuideEnable === 'true') {
        $(document).on('click', '.size-chart .size-chart-link', function () {
            var sizeGuideTrack = $('.size-chart-link').attr('data-lulu-attributes');
            var prodJSON = JSON.parse(sizeGuideTrack.replace(/'/g, '"'));
            _satellite.track(satelliteName, {
                modalDetails: prodJSON
            });
        });
    }

    if (window.location.href.indexOf('?cid=men') > -1) {
        $('#Men-tab').click();
    }

    $(document).on('click', '#footer-size-guide a', function () {
        _satellite.track('footer-size-guide');
    });

    $(document).on('change', '#myselection', function () {
        var countryName = $('option:selected', this).attr('data-countryname');
        var categoryName = $('.gender-specific-div.show').find('.gender-category-tab.active').attr('id');
        if ($('.size-guide-footer').length) {
            _satellite.track(satelliteName, {
                modalDetails: 'countryselector:' + countryName,
                category: categoryName
            });
        } else {
            _satellite.track(satelliteName, {
                modalDetails: 'countryselector:' + countryName
            });
        }
    });

    $(document).on('shown.bs.tab', '.category-tabs a[data-toggle="tab"]', function () {
        var categoryName = $('.gender-specific-div.show').find('.gender-category-tab.active').attr('id');
        var tabName = ($(this).hasClass('size-tab-fit')) ? $(this).attr('data-sizetab-id') : $(this).attr('id');
        var categoryTabExist = ($('.categoty-fit-tab.active:not(:hidden)').length) ? $('.categoty-fit-tab.active:not(:hidden)').attr('id') + '-' + tabName : tabName;
        if ($(this).closest('ul').hasClass('gender-tab')) {
            _satellite.track(satelliteName, {
                modalDetails: 'category:' + tabName,
                category: tabName
            });
        }
        if ($('.size-guide-footer').length) {
            if ($(this).hasClass('categoty-fit-tab')) {
                _satellite.track(satelliteName, {
                    modalDetails: 'fit:' + tabName,
                    category: categoryName
                });
            }
        } else if ($(this).hasClass('categoty-fit-tab')) {
            _satellite.track(satelliteName, {
                modalDetails: 'fit:' + tabName
            });
        }
        if ($('.size-guide-footer').length) {
            if ($(this).hasClass('size-tab-fit')) {
                _satellite.track(satelliteName, {
                    modalDetails: 'size-tab:' + categoryTabExist,
                    category: categoryName
                });
            }
        } else if ($(this).hasClass('size-tab-fit')) {
            _satellite.track(satelliteName, {
                modalDetails: 'size-tab:' + categoryTabExist
            });
        }
    });

    $(document).on('change', '.measurement-switch input', function (e) {
        var categoryName = $('.gender-specific-div.show').find('.gender-category-tab.active').attr('id');
        var isOn = e.currentTarget.checked;
        if ($('.size-guide-footer').length) {
            if (isOn) {
                _satellite.track(satelliteName, {
                    modalDetails: 'measure:IN',
                    category: categoryName
                });
            } else {
                _satellite.track(satelliteName, {
                    modalDetails: 'measure:CM',
                    category: categoryName
                });
            }
        } else if (isOn) {
            _satellite.track(satelliteName, {
                modalDetails: 'measure:IN'
            });
        } else {
            _satellite.track(satelliteName, {
                modalDetails: 'measure:CM'
            });
        }
    });

    $(document).on('click', '.live-chat .live-chat-link', function () {
        var categoryName = $('.gender-specific-div.show').find('.gender-category-tab.active').attr('id');
        if ($('.size-guide-footer').length) {
            _satellite.track(satelliteName, {
                modalDetails: 'livechat',
                innerText: "Let's Chat",
                category: categoryName
            });
        } else {
            _satellite.track(satelliteName, {
                modalDetails: 'livechat',
                innerText: "Let's Chat"
            });
        }
    });

    $(document).on('click', '.truefit-space .tfc-fitrec-product', function () {
        var categoryName = $('.gender-specific-div.show').find('.gender-category-tab.active').attr('id');
        var trueFitText = $('.tfc-fitrec-product').find('.tfc-if-gsa-unavailable').html();
        var trueFitStatus = $('.tfc-modal-result').css('display');
        if ($('.size-guide-footer').length) {
            if (trueFitStatus === 'block') {
                _satellite.track(satelliteName, {
                    modalDetails: 'truefit',
                    innerText: trueFitText,
                    category: categoryName
                });
            }
        } else if (trueFitStatus === 'block') {
            _satellite.track(satelliteName, {
                modalDetails: 'truefit',
                innerText: trueFitText
            });
        }
    });
});

/**
 * Init to call functions
 */
function init() {
    toggleAccordion();
}

// global video id
var videoID;

/**
 * Init the video timeupdate video
 * @param {Object} videoElement - video element
 * @param {string} videoId - video Id
 */
function initVideoTimeUpdate(videoElement, videoId) {
    var halfDurationFlag = true;
    var fullDurationFlag = true;
    $(videoElement).off().on('timeupdate', function () {
        var currentTime = parseInt(this.currentTime, 10);
        var totalDuration = parseInt(this.duration, 10);
        var halfDurattion = parseInt(totalDuration / 2, 10);
        var durationAt95 = parseInt((totalDuration / 100) * 95, 10);

        if (halfDurationFlag && currentTime === halfDurattion) {
            _satellite.track('video-play-marker-50', {
                content: {
                    contentType: 'video',
                    attributes: {
                        videoID: [videoId],
                        videoLength: [totalDuration]
                    }
                },
                version: 1.1
            });
            halfDurationFlag = false;
        }
        if (fullDurationFlag && currentTime === durationAt95) {
            _satellite.track('video-play-complete', {
                content: {
                    contentType: 'video',
                    attributes: {
                        videoID: [videoId],
                        videoLength: [totalDuration]
                    }
                },
                version: 1.1
            });
            fullDurationFlag = false;
        }
    });
}

/**
 * Init the video click event for analytics
 */
$(document).ready(function () {
    $(window).on('load', function () {
        $('.js-video').off().on('click', function () {
            var thisVideoId = $(this).data('video-url');
            var $videoElement = $(this).find('video');
            $videoElement = $videoElement.length ? $videoElement : null;
            // Adding condition to unmute videos that are automatically muted by browser
            if ($videoElement.muted) {
                $videoElement.muted = false;
            }
            thisVideoId = thisVideoId.split('/').pop();
            if (videoID !== thisVideoId && $videoElement) {
                var videoLength = parseInt($videoElement[0].duration, 10);
                _satellite.track('video-play-start', {
                    content: {
                        contentType: 'video',
                        attributes: {
                            videoID: [thisVideoId],
                            videoLength: [videoLength]
                        }
                    },
                    version: 1.1
                });
                videoID = thisVideoId;
                initVideoTimeUpdate($videoElement, videoID);
            }
        });

        // Adding Analytics for native video player
        $('.long-form-video, .short-form-video').off().on('click', function () {
            var thisVideoId = $(this).parent().data('video-url');
            var $videoElement = $(this).find('video');
            thisVideoId = thisVideoId.split('/').pop();
            var videoLength = parseInt($videoElement[0].duration, 10);
            if (!$videoElement[0].paused && $videoElement[0].currentTime === 0) {
                _satellite.track('video-play-start', {
                    content: {
                        contentType: 'video',
                        attributes: {
                            videoID: [thisVideoId],
                            videoLength: [videoLength]
                        }
                    },
                    version: 1.1
                });
                videoID = thisVideoId;
                initVideoTimeUpdate($videoElement, videoID);
            }
        });

        /* if cloudflare cache enabled for SF pages set cookie to indicate CF if a cached/dynamic page to render
           if isDynamicData = true, that means the content is non-cacehable
           (either the user is logged in OR there are items in the cart/basket).
           if the preference is disaled
        */
        if (window.cfCacheEnabled) {
            $.removeCookie('isDynamicData');
            $.ajax({
                url: window.validateGuestURL,
                type: 'get',
                dataType: 'json',
                success: function (response) {
                    var isDynamicData = true;
                    if (response && response.canBeCached) {
                        isDynamicData = false;
                    }
                    $.cookie('isDynamicData', isDynamicData, { expires: 30, path: '/' });
                    if (response.sessionID) {
                        window.digitalData.user[0].sessionID = response.sessionID;
                    }
                }
            });
        } else {
            $.cookie('isDynamicData', true, { expires: 30, path: '/' });
        }

        var selectedCountryCode = $('span.selected-country-js:first').data('selected-country-code');

        if (selectedCountryCode) {
            $.cookie('UsrCountry', selectedCountryCode, { expires: 30, path: '/' });
        }

        /*
          Updating the cookies related to Awin using url parameters if exists.
        */
        var urlParams = new URLSearchParams(window.location.search);
        var utmSource = urlParams.get('utm_source');
        if (utmSource && utmSource === 'awin') {
            $.cookie('ch', 'aw', {
                expires: 30,
                path: '/'
            });
        }
        var cks = urlParams.get('awc');
        if (cks) {
            $.cookie('cks', cks, {
                expires: 30,
                path: '/'
            });
        }
        // Get local storage
        if (localStorage.getItem('alaPurchaseHistory')) {
            var purchaseHistory = JSON.parse(localStorage.getItem('alaPurchaseHistory'));
            if (purchaseHistory && purchaseHistory.length) {
                $.cookie('alaPurchaseHistory', JSON.stringify(purchaseHistory), {
                    expires: 30,
                    path: '/'
                });
            }
        }

        setTimeout(function () {
            var $myElement = $('#nebula_div_btn');
            $($myElement).css({
                'line-height': '1px'
            });
        }, 500);
    });

    /**
     * Product tile raings click event for analytics
     */
    $('.cdp-ratings').on('click', function () {
        _satellite.track('cdp-review-click');
    });
});

module.exports = {
    init: init
};
