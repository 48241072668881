/* eslint-disable no-undef */
'use strict';

$(function () {
    $('.acc__title').click(function (j) {
        var dropDown = $(this).closest('.acc__card').find('.acc__panel');

        $(this).closest('.acc').find('.acc__panel').not(dropDown)
        .slideUp();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).find('.title').attr('aria-expanded', 'false');
        } else {
            $(this).closest('.acc').find('.acc__title.active').removeClass('active');
            $(this).addClass('active');
            $(this).find('.title').attr('aria-expanded', 'true');
        }
        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
    });
});

$(document).ready(function () {
    $(window).on('hashchange load', function () {
        if (window.location.hash && $(window.location.hash).length && $(window.location.hash).closest('.acc__panel')) {
            if ($(window.location.hash).closest('.acc__panel').css('display') === 'none') {
                $(window.location.hash).closest('.acc__panel').slideToggle().siblings('.acc__title')
                .addClass('active');
                $('html, body').animate({ scrollTop: $(window.location.hash).offset().top }, 'fast');
            }
        }
    });
});
