/* global _satellite */

require('../icons/hamburger.svg');
require('../icons/userAuth.svg');
require('../icons/search.svg');
require('../icons/search16.svg');
require('../icons/logo.svg');
require('../icons/brand_logo.svg');
require('../icons/lululemonLogoNew.svg');
require('../icons/brand_lululemonLogoNew.svg');
require('../icons/lululemonCtaArrow.svg');
require('../icons/userGuest.svg');
require('../icons/Account.svg');
require('../icons/warning.svg');
require('../icons/close_modal.svg');
require('../icons/errorIcon.svg');
require('../icons/BagNew.svg');
require('../icons/store.svg');
require('../icons/skeletonProductTile.svg');
require('../icons/brand_givex.svg');

/**
    * This is required for regenerator runtime error fix
    */
require('regenerator-runtime/runtime');

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('base/util');
require('jquery.cookie');
require('jquery-validation');
require('jquery-validation/dist/additional-methods.js');
require('./clientSideFormValidation');
require('./utilities/isMobile');
require('./lib/jquery-migrate-3.0.0');

$(document).ready(function () {
    if (/MSIE \d | Trident.*rv:/.test(navigator.userAgent)) {
        processInclude(require('./utilities/polyfills'));
    }
    processInclude(require('./productSearch'));
    processInclude(require('base/components/menu'));
    processInclude(require('./components/shopLook'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('wishlists/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./miniCart'));
    processInclude(require('./components/search'));
    processInclude(require('./components/visualLinks')); // Do not remove visuallinks.js from main.js
    processInclude(require('./accordionComponent'));
    processInclude(require('./components/productEnhancedGrid'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./pageHeader'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/hero'));
    processInclude(require('./components/video'));
    processInclude(require('./checkGiftCardBalance'));
    processInclude(require('./components/productGrid'));
    processInclude(require('./navigationComponent'));
    processInclude(require('./updateAccountHeader'));

    if (window.enableSatelliteDebugger && typeof _satellite === 'object') {
        const adobeTrack = _satellite.track;
        _satellite.track = (...arg) => {
            adobeTrack(arg[0], arg[1]);
            console.log(arg); // eslint-disable-line no-console
        };
    }
    processInclude(require('./product/pdpCarousel'));
    processInclude(require('./components/recommendationCarousel'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('bootstrap/js/src/dropdown.js');
require('bootstrap/js/src/tooltip.js');
require('bootstrap-select');
processInclude(require('./components/thirdParty/slick'));
processInclude(require('./components/community'));
processInclude(require('./components/outfit'));
processInclude(require('./search/wishlistHeart'));
processInclude(require('./components/emailSignup'));
processInclude(require('./components/emailSignupPopup'));
processInclude(require('./components/pdEmailSignup'));
// Moved product tile requirement as the last requirement so as to
// keep the swatch carousels intact in various components
processInclude(require('./components/productTile'));
processInclude(require('./product/sizeGuide'));
// Quick View
processInclude(require('./product/quickView'));
