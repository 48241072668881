'use strict';

var scene7 = require('../components/scene7');
const mediaQuery = window.matchMedia('(min-width: 1024px)');

/**
 * Returns the asset ID for the image to be zoomed
 * @param {jQuery} $imgContainer container of the current image
 * @returns {string} asset ID stored in scene7
 */
function getAsset($imgContainer) {
    var url = $imgContainer.attr('src');
    var splitUrl = url.split(/\//);
    return splitUrl[splitUrl.length - 1].split(/\?/)[0];
}

/**
 * Function to update product image swatches in zoom modal
 * @param {[image]} productSwatchImages - An array of small image types to be displayed in swatches
 * @param {boolean} productShowLocalImages - boolean value for show local images
 * @param {jQuery} $productContainer - Container surrounding the product images
 */
function updateZoomImageSwatches(productSwatchImages, productShowLocalImages, $productContainer) {
    // Updates the image thumbnails DOM elements in zoom modal post Ajax call
    $productContainer
        .find('.product-zoom-thumbnails a')
        .find('img')
        .each(function (index) {
            var $this = $(this);
            if (productSwatchImages[index]) {
                $this.removeClass('d-none');
                if (productShowLocalImages && (productSwatchImages[index].alt).includes('Local')) {
                    $this.siblings('source').attr('srcset', 'https://images.lululemon.com/is/image/lululemon/' + productSwatchImages[index].alt);
                    $this.attr('src', productSwatchImages[index].alt);
                } else {
                    $this.siblings('source').attr('srcset', productSwatchImages[index].url);
                    $this.attr('src', productSwatchImages[index].url);
                }
            } else {
                $this.addClass('d-none');
            }
        });

    var productId = $productContainer.attr('data-pid');
    var asset = getAsset(
        $productContainer
            .find('.product-zoom-thumbnails a')
            .find('img')
            .eq(0)
    );
    scene7.getZoomImage(asset, 'imageZoomModalContainer-' + productId);
}

module.exports = {
    updateZoomImageSwatches: function () {
        $('body').on('updateZoomModalThumbnails', function (
            event,
            productSwatchImages,
            productShowLocalImages,
            productId
        ) {
            if (mediaQuery.matches) {
                event.stopPropagation();
                updateZoomImageSwatches(
                    productSwatchImages,
                    productShowLocalImages,
                    $('#imageZoomModal-' + productId)
                );
            }
        });
    },
    getZoomImage: function () {
        $('.carousel-item .zoom-viewer-link, .zoom-thumbnail-link').on(
            'click',
            function (e) {
                if (mediaQuery.matches) {
                    e.preventDefault();
                    var $this = $(this);
                    var target = $this.data('target');
                    $(target).modal('show');
                    var productId = $this
                        .closest('.product-detail')
                        .attr('data-pid');
                    var asset = getAsset($this.find('img'));
                    scene7.getZoomImage(
                        asset,
                        'imageZoomModalContainer-' + productId
                    );
                } else {
                    e.preventDefault();
                }
            }
        );
    },
    screenResize: mediaQuery.addListener(function () {
        $('.imageZoomModal').modal('hide');
    })
};
