var $userIcon = $('.cta-icons .user');
var $minicartQuantity = $('.minicart-quantity');

$('.minicart').on('mouseenter focusin touchstart', function () {
    if ($('.cart-page').length > 0) {
        if ($('.minicart .popover').hasClass('show')) {
            $('.minicart .popover').removeClass('show');
        }
    } else {
        $('.minicart-link').addClass('show');
    }
});

$('body').on('touchstart click', function () {
    $('.minicart-link').removeClass('show');
    $userIcon.find('.user-link').removeClass('show');
});

$('.minicart').on('mouseleave focusout', function () {
    $('.minicart-link').removeClass('show');
});

$userIcon.on('mouseenter focusin touchstart', function () {
    $userIcon.find('.user-link').addClass('show');
});

$userIcon.on('mouseleave focusout', function () {
    $userIcon.find('.user-link').removeClass('show');
});

var miniCartQuantityObserver = new MutationObserver(function () {
    if (!$('.minicart-link').hasClass('product-added')) {
        if (parseInt($minicartQuantity.text(), 10) > 0) {
            setTimeout(function () {
                $('.minicart-link').addClass('product-added');
            }, 10000);
        }
    } else if (parseInt($minicartQuantity.text(), 10) === 0) {
        $('.minicart-link').removeClass('product-added');
    }
});

miniCartQuantityObserver.observe(document.querySelector('.minicart-quantity'), { childList: true, subtree: true });

$(document).on('show.bs.modal', '#removeProductModal', function () {
    if ($(document.body).hasClass('stickyHeader')) {
        $(document.body).addClass('miniCartModal');
    }
    $(document).on('keydown', '#removeProductModal', function (e) {
        if (e.key === 'Tab' || e.keyCode === 9) {
            if (e.shiftKey) /* shift + tab */ {
                if ($('#removeProductModal .closeButton-1yRs1').is(':focus')) {
                    $('#removeProductModal .secondaryCta-25wwC').focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if ($('#removeProductModal .secondaryCta-25wwC').is(':focus')) {
                    $('#removeProductModal .closeButton-1yRs1').focus();
                    e.preventDefault();
                }
                return;
            }
        }
    });
});
$(document).on('hide.bs.modal', '#removeProductModal', function () {
    if ($(document.body).hasClass('miniCartModal')) {
        $(document.body).removeClass('miniCartModal');
    }
});

$(document).ready(function () {
    if (!$('.minicart-link').hasClass('product-added')) {
        if ($('.minicart-link .minicart-quantity')[0] && parseInt($('.minicart-link .minicart-quantity')[0].innerHTML, 10) > 0) {
            setTimeout(function () {
                $('.minicart-link').addClass('product-added');
            }, 5000);
        }
    }
});
