/* eslint-disable no-undef */
'use strict';

let i;
var x;
var a;
var d;
$(document).ready(function () {
        /**
         * @param {string} elmnt - Component Script Context
         */
    function closeAllSelect(elmnt) {
        var y;
        var xl;
        var yl;
        var arrNo = [];
        x = document.getElementsByClassName('select-items');
        y = document.getElementsByClassName('select-selected');
        xl = x.length;
        yl = y.length;
        for (i = 0; i < yl; i++) {
            if (elmnt === y[i]) {
                arrNo.push(i);
            } else {
                y[i].classList.remove('select-arrow-active');
                $('.arrow').toggleClass('down up');
            }
        }
        for (i = 0; i < xl; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add('select-hide');
                $('.arrow').toggleClass('down up');
            }
        }
    }

    /**
     * A function that works on click
     */
    function buttonClicked() {
        var y;
        var k;
        var s;
        var h;
        var sl;
        var yl;
        s = this.parentNode.parentNode.getElementsByClassName('select-mobile-navigation')[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML === this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName('same-as-selected');
                a.appendChild(d);
                window.location = s.options[s.selectedIndex].value;
                $('.arrow').toggleClass('down up');
                yl = y.length;
                for (k = 0; k < yl; k++) {
                    y[k].removeAttribute('class');
                }
                this.setAttribute('class', 'same-as-selected');
                a.appendChild(d);
                $('.arrow').toggleClass('down up');
                break;
            }
        }
        h.click();
    }

    if (window.screen.width <= 1023) {
        $("<div class= 'mobile-navigation-container' /><select class='select-mobile-navigation' />").appendTo('.navigation-content');
        $('<option />', {
            selected: 'selected',
            value: '',
            text: 'Selected Item'
        }).appendTo('.navigation-content select');
        $('.navigation-content a').each(function () {
            var el = $(this);
            $('<option />', {
                value: el.attr('href'),
                text: el.text()
            }).appendTo('.navigation-content select');
        });

        let j;
        var l;
        var ll;
        var selElmnt;
        var b;
        var c;
        x = document.getElementsByClassName('mobile-navigation-container');
        l = x.length;

        const helperDropdown = document.querySelectorAll('button.dropdown-toggle');
        helperDropdown.forEach(element => {
            element.setAttribute('role', 'combobox');
            element.setAttribute('aria-label', 'LULULEMON HELP');
            element.setAttribute('tabindex', '0');
            element.setAttribute('aria-expanded', 'false');
        });
        const items = document.querySelectorAll('.dropdown-menu.inner.show li');
        // Loop through the items to find the one with the text "Selected Item"
        items.forEach(item => {
            const linkText = item.querySelector('a .text').textContent;
            if (linkText === 'Selected Item') {
                item.remove();
            }
        });

        for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByClassName('select-mobile-navigation')[0];
            $('.select-mobile-navigation').selectpicker();
            ll = selElmnt.length;
            a = document.createElement('DIV');
            a.setAttribute('class', 'select-selected');
            a.setAttribute('role', 'combobox');
            a.setAttribute('aria-label', 'LULULEMON HELP');
            a.setAttribute('tabindex', '0');
            a.setAttribute('aria-expanded', 'false');
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            d = document.createElement('i');
            d.setAttribute('class', 'arrow down');
            b = document.createElement('DIV');
            b.setAttribute('class', 'select-items select-hide');
            for (j = 1; j < ll; j++) {
                c = document.createElement('DIV');
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener('click', buttonClicked);
                b.appendChild(c);
                a.appendChild(d);
            }
            x[i].appendChild(b);
            a.addEventListener('click', function (e) {
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle('select-hide');
                this.classList.toggle('select-arrow-active');
                if (this.classList.contains('select-arrow-active')) {
                    this.setAttribute('aria-expanded', 'true');
                } else {
                    this.setAttribute('aria-expanded', 'false');
                }
                $('.arrow').toggleClass('down up');
            });
        }
        document.addEventListener('click', closeAllSelect);
        $('.arrow').toggleClass('down up');
        $('.navigation-content .select-mobile-navigation').change(function () {
            window.location = $(this).find('option:selected').val();
        });
        $('.mobile-navigation-content').hide();
        $('.arrow').toggleClass('down up');
    } else {
        $('.mobile-navigation-content').show();
        $('.select-mobile-navigation').hide();
    }
    $('#Ordering').attr('tabindex', '-1');
    $('#Ordering h2').attr('tabindex', '0');
    $('#Payment').attr('tabindex', '-1');
    $('#Payment h2').attr('tabindex', '0');
});
