'use strict';

require('../../icons/wishlistOutline.svg');
require('../../icons/plus.svg');
var carousel = require('./carousel');

const mobileMediaQuery = window.matchMedia('(max-width: 1023.98px)');
const tabletMediaQuery = window.matchMedia(
    '(min-width: 1024px) and (max-width: 1409.98px)'
);
const smallScreenDesktop = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');
const medScreenDesktop = window.matchMedia('(min-width: 1280px) and (max-width: 1365px)');
const lgScreenDesktop = window.matchMedia('(min-width: 1366px) and (max-width: 1465px)');

/**
 * Initialize Swatches
 * @param {jQuery} $swatches - DOM element for color swatches on CDP
 */
function initSwatch($swatches) {
    var swatches;
    if ($swatches) {
        swatches = $swatches;
    } else {
        swatches = $('.swatches');
    }
    var counter = 0;
    swatches.each(function () {
        var $swatch = $(this);
        var imageContainer = $(this).closest('.product-tile').find('.image-container');
        let promoBadge = $(this).find('a:first-child span > picture > img').data('hover-promotag');
        var isLowInStock = $(this).find('a:first-child span > picture > img').data('islis');
        var lowInStockBadge = $(this).find('a:first-child span > picture > img').data('lis-msg');
        if (promoBadge) {
            if (!imageContainer.find('.promoCallout').length) {
                var promoDiv = document.createElement('div');
                $(promoDiv).addClass('promoCallout');
                promoDiv.innerText = promoBadge;
                imageContainer.append(promoDiv);
                $('.promoCallout').css('display', 'block');
            } else {
                imageContainer.find('.promoCallout').text(promoBadge);
            }
        }

        if (isLowInStock) {
            imageContainer.find('.promoCallout').addClass('d-none');
            if (!imageContainer.find('.lis-badge').length) {
                imageContainer.append('<div class="lis-badge">' + lowInStockBadge + '</div>');
            } else {
                imageContainer.find('.lis-badge').removeClass('d-none');
            }
        } else {
            imageContainer.find('.lis-badge').addClass('d-none');
        }

        $('.recommendations .product-carousel').on('init', function () {
            var prodCarousel = $(this);
            var activeSlide = prodCarousel.find('.product-carousel-slide.slick-active');
            var colorswatchIcon = activeSlide.find('.color-swatches .slick-slide[tabindex="-1"]');
            var colorswatch = activeSlide.find('.color-swatches .slick-slide[tabindex!="-1"]');

            colorswatch
                    .attr('aria-hidden', 'false')
                    .find('.swatchAnchor')
                    .attr('tabindex', '0');

            colorswatchIcon
                .find('img.swatch-circle')
                .attr('tabindex', '-1');
        });

        $('.recommendations .product-carousel').on('afterChange', function () {
            var prodCarousel = $(this);
            setTimeout(function () {
                var activeSlide = prodCarousel.find('.product-carousel-slide.slick-active');
                var colorswatch = activeSlide.find('.color-swatches .slick-slide[tabindex!="-1"]');

                colorswatch
                    .attr('aria-hidden', 'false')
                    .find('.swatchAnchor')
                    .attr('tabindex', '0')
                    .find('img.swatch-circle')
                    .attr('tabindex', '0');
            }, 100);
        });

        $('.recommendations .product-carousel').on('beforeChange', function () {
            var prodCarousel = $(this);

            var activeSlide = prodCarousel.find('.product-carousel-slide.slick-active');
            var colorswatch = activeSlide.find('.color-swatches .slick-slide[tabindex!="-1"]');

            colorswatch
                .find('img.swatch-circle')
                .attr('tabindex', '-1');
        });

        var activeSwatches = $(this).find('a').length;
        $swatch.attr('id', counter);
        counter++;
        var opts;
        if (mobileMediaQuery.matches && activeSwatches > 4) {
            opts = {
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: false,
                infinite: false,
                centerMode: false,
                centerPadding: '0px',
                padding: '3px'
            };
            $swatch.find($('a.plusSymbol')).remove();
            carousel.initCarousel(opts, $swatch);
        } else if (
            (smallScreenDesktop.matches && activeSwatches > 5)
            || (medScreenDesktop.matches && activeSwatches > 6)
            || (activeSwatches > 4 && $swatch.closest('.recommendations-cdp').length)
            || (activeSwatches > 6 && $swatch.closest('.cart-recommendations').length)
            || ($swatch.closest('.comparisonModal').length && lgScreenDesktop.matches && activeSwatches > 6)
            || ($swatch.closest('.outfit-component').length && activeSwatches > 6)
            || (activeSwatches > 7)
        ) {
            $('.color-swatches .swatches .slick-list .slick-slide a.selected').css('margin-left', '0px');
            opts = {
                slidesToShow: 7,
                slidesToScroll: 2,
                autoplay: false,
                infinite: false,
                centerMode: false,
                centerPadding: '0px',
                padding: '3px'
            };

            if ($swatch.closest('.comparisonModal').length && smallScreenDesktop.matches && activeSwatches > 5) {
                opts.slidesToShow = 5;
            }

            if ($swatch.closest('.outfit-component').length && $swatch[0].slick !== undefined) {
                $swatch[0].slick.refresh();
            }

            $swatch.find($('a.plusSymbol')).remove();
            carousel.initCarousel(opts, $swatch);

            if ($('.recommendations-cdp .product-carousel-slide .color-swatches .slick-initialized').length) {
                var $swatchSlider = $('.recommendations-cdp .product-carousel-slide .color-swatches .slick-initialized');
                for (var i = 0; i < $swatchSlider.length; i++) {
                    if ($swatchSlider[i].slick !== undefined) {
                        $swatchSlider[i].slick.options.slidesToShow = 4;
                        $swatchSlider[i].slick.options.slidesToScroll = 1;
                        $swatchSlider[i].slick.refresh();
                    }
                }
            }
        }
        let $slickSlider = $('.color-swatches .swatches.slick-slider');
        $slickSlider.attr('role', 'none');
        $slickSlider.find('.slick-list .slick-track .slick-slide').attr('role', 'none');
        $slickSlider.find('.slick-list .slick-track .slick-slide>div').attr('role', 'none');
        $slickSlider.find('.slick-list .slick-track').attr('role', 'listbox');
    });

    $(swatches)
        .find('a')
        .on('click', function () {
            var selectedColor = $(this).attr('data-color-val');
            var firstColorVal = $(this).attr('data-first-color');
            if (selectedColor === 'plus-icon') {
                $('body').trigger('variationAttributePayload', {
                    color: firstColorVal,
                    sku: $(this)
                        .parents('.product')
                        .attr('data-pid'),
                    productID: $(this)
                        .parents('.product')
                        .attr('data-pid')
                });
            } else {
                $('body').trigger('variationAttributePayload', {
                    color: selectedColor,
                    sku: $(this)
                        .parents('.product')
                        .attr('data-pid'),
                    productID: $(this)
                        .parents('.product')
                        .attr('data-pid')
                });
            }
        });
}

$(window).on('load', function () {
    if ($('#cdp-page-indicator').length >= 1) {
        $('.new-cdp > .category-hero-slot > .hero-no-overlay-component').css('margin-bottom', '40px');
        $('.new-cdp > .category-hero-slot > .hero-no-overlay-component').removeClass('container');
        if (mobileMediaQuery.matches) {
            $('.new-cdp > .category-hero-slot > .hero-no-overlay-component .hero-subtext').css('padding-left', '0px');
        }
    }
    initSwatch();
});

/**
 * Reinitialize swatches when user clicks on show more, sort or filter.
 */
function onGridRefresh() {
    $(document.body).on(
        'search:showMore search:sort search:filter',
        function () {
            var initSwatchObserver = new MutationObserver(function () {
                setTimeout(initSwatch, 10);
            });
            initSwatchObserver.observe(document.querySelector('.product-grid'), { childList: true });
        }
    );
}

/**
 * Initialize all required menthods.
 */
function init() {
    initSwatch();
    onGridRefresh();
    mobileMediaQuery.addListener(initSwatch);
    tabletMediaQuery.addListener(initSwatch);
}

module.exports = {
    init: init,
    initSwatch: initSwatch
};
