'use strict';

$('body').on('updateMobileAccountHeader', function () {
    let mobileAccoutHeader;
    let $domSelector;
    let customMobileAccountHeader;

    if ($('#guestUser').length > 0) {
        $domSelector = $('#guestUser');
        mobileAccoutHeader = {
            loginURL: $domSelector.attr('data-loginurl'),
            loginText: $domSelector.attr('data-logintext'),
            isGuestUser: $domSelector.attr('data-isguestuser'),
            registerText: $domSelector.attr('data-registertext')
        };
        customMobileAccountHeader = $domSelector.attr('data-custommobileaccountheader');
    } else if ($('#authUser').length > 0) {
        $domSelector = $('#authUser');
        mobileAccoutHeader = {
            logoutURL: $domSelector.attr('data-logouturl'),
            logoutText: $domSelector.attr('data-logouttext'),
            accountShowURL: $domSelector.attr('data-accountshowurl'),
            accountShowText: $domSelector.attr('data-accountshowtext'),
            isGuestUser: $domSelector.attr('data-isguestuser')
        };
        customMobileAccountHeader = $domSelector.attr('data-custommobileaccountheader');
    }

    if ((customMobileAccountHeader === 'true') && mobileAccoutHeader) {
        if (mobileAccoutHeader.isGuestUser === 'true') {
            var $liLogin = $('<li>', { class: 'nav-item d-lg-none', role: 'menuitem' });
            var $loginLink = $('<a>', { href: mobileAccoutHeader.loginURL, class: 'nav-link secondary-nav-links', role: 'button' });
            var $loginText = $('<span>', { text: mobileAccoutHeader.loginText + ' / ' + mobileAccoutHeader.registerText, class: 'user-message' });
            $loginLink.append($loginText);
            $liLogin.append($loginLink);
            $('#mobile-account-header').empty();
            $('#mobile-account-header').append($liLogin);
        } else {
            var $liAccountShow = $('<li>', { class: 'nav-item d-lg-none', role: 'menuitem' });
            var $AccountShowLink = $('<a>', { href: mobileAccoutHeader.accountShowURL, class: 'nav-link secondary-nav-links', text: mobileAccoutHeader.accountShowText, role: 'button' });
            $liAccountShow.append($AccountShowLink);
            var $liLogout = $('<li>', { class: 'nav-item d-lg-none', role: 'menuitem' });
            var $logoutLink = $('<a>', { href: mobileAccoutHeader.logoutURL, class: 'nav-link secondary-nav-links', text: mobileAccoutHeader.logoutText, role: 'button' });
            $liLogout.append($logoutLink);
            $('#mobile-account-header').empty();
            $('#mobile-account-header').append($liAccountShow);
            $('#mobile-account-header').append($liLogout);
        }
    }
});

$(function () {
    $('body').trigger('updateMobileAccountHeader');
});
