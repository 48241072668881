'use strict';

var carousel = require('./carousel');

const mediaQuery = window.matchMedia('(min-width: 1024px)');

/**
 * Init community cards carousel
 */
function initCardsCarousel() {
    var homeSlot8Ele = document.querySelector('.home-slot8-ABtest');
    var homeSlot9Ele = document.querySelector('.home-slot9-ABtest');

    $('.community-component .card-group').each(function () {
        var $this = $(this);

        var opts = {
            dots: true,
            customPaging: function (slider, i) {
                var ariaLabel = 'slide ' + (i + 1) + ' of ' + (slider.getDotCount() + 1);
                return '<div class="carousel-dots" role="tab" id=' + i + ' aria-label="' + ariaLabel + '" aria-pressed="true" tabindex="0"></div>';
            },
            slidesToShow: 2,
            slidesToScroll: 2,
            centerMode: false,
            centerPadding: '48px',
            arrows: false,
            responsive: [
                {
                    breakpoint: 1023.98,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: true,
                        swipeToSlide: false
                    }
                }
            ]
        };
        carousel.initCarousel(opts, $this);
        carousel.heightResize($this, 0, 'body-wrapper');
    });

    if ($(homeSlot8Ele).length || $(homeSlot9Ele).length) {
        var resizewatcher = new window.ResizeObserver(function (entries) {
            for (const entry of entries) {
                if (entry.contentRect.width !== 0) {
                    $(entry.target).find('.community-component .card-group').slick('refresh');
                    $(entry.target).find('.community-component .body-wrapper').css('height', '100%');
                    carousel.heightResize($(entry.target).find('.community-component .card-group'), 0, 'body-wrapper');
                }
            }
        });
        if ($(homeSlot8Ele).length) {
            resizewatcher.observe(homeSlot8Ele);
        }
        if ($(homeSlot9Ele).length) {
            resizewatcher.observe(homeSlot9Ele);
        }
    }
}

/**
 * Initialize listeners for resize
 */
function init() {
    mediaQuery.addListener(initCardsCarousel);
    initCardsCarousel();
}

module.exports = {
    init: init
};
